import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid URL, transported as a string */
  File: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** Autogenerated return type of AcceptTermsOfService */
export type AcceptTermsOfServicePayload = {
  /** Url to redirect the user to after accepting the terms of use */
  redirectUrl: Scalars['String'];
};

export enum ActivityLogEventTypeEnum {
  CandidateUnavailable = 'candidate_unavailable',
  CandidateAvailable = 'candidate_available',
  AdminUnavailable = 'admin_unavailable',
  AdminAvailable = 'admin_available',
  AdminUnqualified = 'admin_unqualified',
  AdminQualified = 'admin_qualified',
  WorkerUnavailable = 'worker_unavailable',
  WorkerAvailable = 'worker_available',
  ErrorOrBatch = 'error_or_batch',
  AdminBackInRodada = 'admin_back_in_rodada',
  BackToPreviousState = 'back_to_previous_state',
  BackToAprovado = 'back_to_aprovado',
  CandidateWrongfulState = 'candidate_wrongful_state',
  SetGanharDestaque = 'set_ganhar_destaque',
  SaveStatusChange = 'save_status_change',
  SaveAvailableChange = 'save_available_change',
  SaveExamFinish = 'save_exam_finish',
  SaveExamTimeExceed = 'save_exam_time_exceed',
  UpdateProfile = 'update_profile',
  ClickJobList = 'click_job_list',
  CleanProfile = 'clean_profile',
  JoiningInvisible = 'joining_invisible',
  InteractionWithPlatform = 'interaction_with_platform',
  DashboardAccess = 'dashboard_access',
  LearnMoreInternationalGeek = 'learn_more_international_geek',
  SeeJobsInternationalGeek = 'see_jobs_international_geek',
  LearnMoreSafetyWing = 'learn_more_safety_wing'
}

export type Actor = CompanyUser;

export enum ActorModelsEnum {
  Candidate = 'candidate',
  TeamMember = 'team_member',
  CompanyUser = 'company_user'
}

export type Address = {
  cityId: Scalars['ID'];
  cep: Scalars['String'];
  address: Scalars['String'];
  number: Scalars['String'];
  neighborhood: Scalars['String'];
};

/** Autogenerated return type of AdminHiringUpdateStatus */
export type AdminHiringUpdateStatusPayload = {
  hiring: Maybe<Hiring>;
};

export type AdminUser = {
  id: Scalars['ID'];
};

export type Affected = Candidate;

export enum AmplitudeHitOptionsEnum {
  LmFlowV1PvLp = 'lm_flow_v1_pv_lp',
  LmFlowV1LpNoEmail = 'lm_flow_v1_lp_no_email',
  LmFlowV1LpEmail = 'lm_flow_v1_lp_email',
  LmFlowV1PvStep_1 = 'lm_flow_v1_pv_step_1',
  LmFlowV1Step_1Completed = 'lm_flow_v1_step_1_completed',
  LmFlowV1PvStep_2 = 'lm_flow_v1_pv_step_2',
  LmFlowV1Step_2Completed = 'lm_flow_v1_step_2_completed',
  LmFlowV1PvSignupG5 = 'lm_flow_v1_pv_signup_g5',
  LmFlowV1ClickG5SwitchedToRemoteWork = 'lm_flow_v1_click_g5_switched_to_remote_work',
  LmFlowV1ClickG5DidntSwitchedToRemoteWork = 'lm_flow_v1_click_g5_didnt_switched_to_remote_work',
  LmFlowV1PvSignupG5Locked = 'lm_flow_v1_pv_signup_g5_locked',
  LmFlowV1PvHiringIntentResult = 'lm_flow_v1_pv_hiring_intent_result',
  LmFlowV1ClickScheduleDemonstration = 'lm_flow_v1_click_schedule_demonstration',
  LmFlowV1ClickPreferredContact = 'lm_flow_v1_click_preferred_contact',
  LmFlowV1PvPreferredContact = 'lm_flow_v1_pv_preferred_contact',
  LmFlowV1ClickPreferredContactCancel = 'lm_flow_v1_click_preferred_contact_cancel',
  LmFlowV1ClickPreferredContactConfirm = 'lm_flow_v1_click_preferred_contact_confirm',
  LmFlowV1PvSchedule = 'lm_flow_v1_pv_schedule',
  LmFlowV1ClickScheduleGoBack = 'lm_flow_v1_click_schedule_go_back',
  LmFlowV1ClickScheduleConfirm = 'lm_flow_v1_click_schedule_confirm',
  LmFlowV1PvDashboardPending = 'lm_flow_v1_pv_dashboard_pending',
  LmFlowV1ClickAddCompanyUser = 'lm_flow_v1_click_add_company_user',
  LmFlowV1ClickUpdateCompanyProfile = 'lm_flow_v1_click_update_company_profile',
  LmFlowV1PvDashboardConfirmMail = 'lm_flow_v1_pv_dashboard_confirm_mail',
  LmFlowV1ClickResendConfirmationMail = 'lm_flow_v1_click_resend_confirmation_mail',
  LmFlowV1PvDashboardBlocked = 'lm_flow_v1_pv_dashboard_blocked',
  LmFlowV1PvDashboardStagnated = 'lm_flow_v1_pv_dashboard_stagnated',
  LmFlowV1ExistentStagnantCompanyContactRequested = 'lm_flow_v1_existent_stagnant_company_contact_requested',
  CandidateSignInModalDeny = 'candidate_sign_in_modal_deny',
  CandidateDashboardIntendedToBecomeInvisible = 'candidate_dashboard_intended_to_become_invisible',
  CandidateDashboardIntendedToBecomeVisible = 'candidate_dashboard_intended_to_become_visible',
  CandidateDashboardv3BecameInvisibleForOwnChoice = 'candidate_dashboardv3_became_invisible_for_own_choice',
  CandidateDashboardv3BecameVisibleForOwnChoice = 'candidate_dashboardv3_became_visible_for_own_choice',
  CandidateDashboardv3IntendedToEditProfileProfessionalMoment = 'candidate_dashboardv3_intended_to_edit_profile_professional_moment',
  CandidateDashboardv3IntendedToEditProfile = 'candidate_dashboardv3_intended_to_edit_profile',
  CandidateDashboardv3Chat = 'candidate_dashboardv3_chat',
  CandidateDashboardv3BidDetails = 'candidate_dashboardv3_bid_details',
  CandidateDashboardv3InterviewDetails = 'candidate_dashboardv3_interview_details',
  CandidateDashboardv3IgnoreWhatsappNotification = 'candidate_dashboardv3_ignore_whatsapp_notification',
  CandidateDashboardv3AcceptedWhatsappNotification = 'candidate_dashboardv3_accepted_whatsapp_notification',
  CandidateDashboardv3SafetyWingClick = 'candidate_dashboardv3_safety_wing_click',
  CandidateCompletingProfileV2Status = 'candidate_completing_profile_v2_status',
  CandidateCompletingProfileV2Career = 'candidate_completing_profile_v2_career',
  CandidateCompletingProfileV2Skills = 'candidate_completing_profile_v2_skills',
  CandidateCompletingProfileV2Experiences = 'candidate_completing_profile_v2_experiences',
  CandidateCompletingProfileV2About = 'candidate_completing_profile_v2_about',
  CandidateCompletingProfileV2PersonalData = 'candidate_completing_profile_v2_personal_data',
  CandidateDashboardAccess = 'candidate_dashboard_access',
  CandidateClickPageBlog = 'candidate_click_page_blog',
  CandidateClickPageSimilarJobs = 'candidate_click_page_similar_jobs',
  CandidateClickPageCreateProfile = 'candidate_click_page_create_profile',
  CandidateShareJobOnLinkedin = 'candidate_share_job_on_linkedin',
  CandidateShareJobOnWhatsapp = 'candidate_share_job_on_whatsapp',
  CandidateShareJobOnTwitter = 'candidate_share_job_on_twitter',
  CandidateShowedInterest = 'candidate_showed_interest',
  CompanyScheduledDemonstration = 'company_scheduled_demonstration',
  CompanyCandidatesFilterClickCandidate = 'company_candidates_filter_click_candidate',
  CompanyAccessBids = 'company_access_bids',
  CompanyDetailedBidAccess = 'company_detailed_bid_access',
  CompanyDetailedBidSent = 'company_detailed_bid_sent',
  CandidateInternationalCompletingProfileV2Status = 'candidate_international_completing_profile_v2_status',
  CandidateInternationalCompletingProfileV2Career = 'candidate_international_completing_profile_v2_career',
  CandidateInternationalCompletingProfileV2Skills = 'candidate_international_completing_profile_v2_skills',
  CandidateInternationalCompletingProfileV2Experiences = 'candidate_international_completing_profile_v2_experiences',
  CandidateInternationalCompletingProfileV2About = 'candidate_international_completing_profile_v2_about',
  CandidateInternationalCompletingProfileV2PersonalData = 'candidate_international_completing_profile_v2_personal_data',
  CandidateSignup = 'candidate_signup',
  CandidateBecameAproved = 'candidate_became_aproved',
  CandidateInternationalSignup = 'candidate_international_signup',
  CandidateInternationalBecameAproved = 'candidate_international_became_aproved',
  CandidateInternationalWaitingEnglishValidation = 'candidate_international_waiting_english_validation',
  CandidateIntentToPassEnglishAssessment = 'candidate_intent_to_pass_english_assessment',
  XavierApprovedCandidate = 'xavier_approved_candidate',
  CandidateUpdateProfile = 'candidate_update_profile',
  CompanyRequestHubAccessSalaryPage = 'company_request_hub_access_salary_page',
  CompanyAccessHubPositionsPage = 'company_access_hub_positions_page',
  CompanyAccessHubSalaryPage = 'company_access_hub_salary_page'
}

export enum AmplitudeUserTypeEnum {
  Company = 'company',
  Candidate = 'candidate'
}

/** Answer Input Type */
export type AnswerInput = {
  questionId?: Maybe<Scalars['Int']>;
  answer?: Maybe<Scalars['String']>;
};

export type ApplicantForm = {
  /** Candidate name */
  name: Scalars['String'];
  /** Candidate email */
  email: Scalars['String'];
  /** Candidate phone */
  phone: Scalars['String'];
  /** Candidate linkedin */
  linkedinUrl: Scalars['String'];
  /** Candidate resume */
  resume?: Maybe<Scalars['File']>;
  /** Candidate clt salary */
  cltSalary?: Maybe<Scalars['Float']>;
  /** Candidate pj salary */
  pjSalary?: Maybe<Scalars['Float']>;
  /** Accept terms of service and privacy policy */
  termsOfService: Scalars['Boolean'];
  /** Whether candidate allows notification through whatsapp */
  allowWhatsappNotification: Scalars['Boolean'];
};

export type ArchivedStepCandidateFeedbacks = {
  archivedStepCandidate: Maybe<ArchivedStepCandidates>;
  feedbackType: Maybe<Feedback>;
  id: Scalars['ID'];
};

export type ArchivedStepCandidates = {
  bid: Maybe<Bid>;
  id: Scalars['ID'];
  stepCandidate: StepCandidates;
};

export enum AssessmentLevelEnum {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
  Fluent = 'fluent'
}

export enum AvailableFeedbackEnum {
  /** Não quero mudar de emprego neste momento. */
  DontWantChangeJob = 'dont_want_change_job',
  /** Encontrei uma oportunidade dentro da GeekHunter. */
  JobFoundOnGeek = 'job_found_on_geek',
  /** Encontrei uma oportunidade fora da GeekHunter. */
  JobFoundOffGeek = 'job_found_off_geek',
  /** A GeekHunter não atendeu as minhas expectativas. */
  GeekhunterNotMetExpectations = 'geekhunter_not_met_expectations',
  /** Estou participando de outros processos através da GeekHunter e gostaria de focar neles. */
  FocusedOnOtherProcesses = 'focused_on_other_processes',
  /** Outro */
  Others = 'others'
}

export type Benefit = {
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  placeholder: Maybe<Scalars['String']>;
};

export type Bid = Node & {
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  annualBonus: Maybe<Scalars['String']>;
  candidate: Maybe<Candidate>;
  city: Maybe<City>;
  cltMaxSalary: Maybe<Scalars['Float']>;
  cltMinSalary: Maybe<Scalars['Float']>;
  company: Maybe<Company>;
  companyPackage: Maybe<CompanyPackage>;
  companyUser: Maybe<CompanyUser>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  dentalBenefit: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  equity: Maybe<Scalars['String']>;
  healthBenefit: Maybe<Scalars['Boolean']>;
  hiringType: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  interestJustification: Maybe<Scalars['String']>;
  isNew: Maybe<Scalars['Boolean']>;
  job: Job;
  jobTitle: Maybe<Scalars['String']>;
  maxEquity: Maybe<Scalars['Float']>;
  maxSalary: Maybe<Scalars['Int']>;
  mealBenefit: Maybe<Scalars['Float']>;
  minEquity: Maybe<Scalars['Float']>;
  minSalary: Maybe<Scalars['Int']>;
  origin: Maybe<BidOriginEnum>;
  otherBenefits: Maybe<Scalars['String']>;
  parkingBenefit: Maybe<Scalars['Boolean']>;
  pjMaxSalary: Maybe<Scalars['Float']>;
  pjMinSalary: Maybe<Scalars['Float']>;
  recommendationId: Maybe<Scalars['Int']>;
  refusedByAdminId: Maybe<Scalars['Int']>;
  registrationId: Maybe<Scalars['Int']>;
  remote: Maybe<Scalars['Boolean']>;
  replyOrigin: Maybe<Scalars['String']>;
  shouldNotify: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  snapshot: Maybe<Scalars['String']>;
  state: Maybe<Scalars['Int']>;
  stateDate: Maybe<Scalars['ISO8601DateTime']>;
  status: BidStatusEnum;
  statusAcknowledgedByCompany: Maybe<Scalars['Boolean']>;
  teamMemberId: Maybe<Scalars['Int']>;
  transportBenefit: Maybe<Scalars['Boolean']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  usdAnnualMaxSalary: Maybe<Scalars['Float']>;
  usdAnnualMinSalary: Maybe<Scalars['Float']>;
};

/** Bid Input Type */
export type BidInput = {
  candidateId: Scalars['Int'];
  jobId: Scalars['Int'];
  description: Scalars['String'];
  cltMinSalary?: Maybe<Scalars['Float']>;
  cltMaxSalary?: Maybe<Scalars['Float']>;
  pjMinSalary?: Maybe<Scalars['Float']>;
  pjMaxSalary?: Maybe<Scalars['Float']>;
  usdAnnualMinSalary?: Maybe<Scalars['Float']>;
  usdAnnualMaxSalary?: Maybe<Scalars['Float']>;
  origin: BidOriginEnum;
};

export enum BidOriginEnum {
  DirectCandidateProfile = 'direct_candidate_profile',
  MatchSimilarity = 'match_similarity',
  SearchCandidate = 'search_candidate',
  FavoriteCandidate = 'favorite_candidate',
  BlockedCandidate = 'blocked_candidate',
  GeekflowRecommendationJorel = 'geekflow_recommendation_jorel',
  GeekflowRecommendationCs = 'geekflow_recommendation_cs',
  GeekflowRecommendationInterest = 'geekflow_recommendation_interest',
  GeekflowCandidateActive = 'geekflow_candidate_active',
  GeekflowArchivedCompany = 'geekflow_archived_company',
  GeekflowArchivedCandidate = 'geekflow_archived_candidate',
  CanditeQuitBidInviteAgain = 'candite_quit_bid_invite_again',
  CanditeQuitBidCandidateProfile = 'candite_quit_bid_candidate_profile',
  DirectBidCreatePage = 'direct_bid_create_page',
  MesseagesCandidateProfile = 'messeages_candidate_profile',
  CsRecommendationLink = 'cs_recommendation_link',
  GeekflowRecommendationSearch = 'geekflow_recommendation_search',
  GeekflowCandidateInterest = 'geekflow_candidate_interest'
}

export enum BidStatusEnum {
  /** Pendente */
  Waiting = 'waiting',
  /** Aceito */
  Accepted = 'accepted',
  /** Recusado */
  Rejected = 'rejected'
}

export type Candidate = Node & {
  allowImportFromLinkedin: Maybe<Scalars['Boolean']>;
  allowWhatsappNotification: Scalars['Boolean'];
  approvalType: Maybe<Scalars['Int']>;
  approvedInternational: Maybe<Scalars['Boolean']>;
  available: Scalars['Boolean'];
  availableAt: Maybe<Scalars['ISO8601DateTime']>;
  bids: Maybe<Array<Bid>>;
  bonus: Scalars['Int'];
  candidateCities: Maybe<Array<CandidateCities>>;
  candidateExcludedCompanies: Maybe<Array<CandidateExcludedCompanies>>;
  candidateFocus: Maybe<Array<CandidateFocus>>;
  candidateLanguages: Maybe<Array<CandidateLanguages>>;
  certifications: Maybe<Array<Certifications>>;
  city: Maybe<City>;
  cltSalary: Maybe<Scalars['Float']>;
  confirmationSentAt: Maybe<Scalars['ISO8601DateTime']>;
  confirmedAt: Maybe<Scalars['ISO8601DateTime']>;
  contractType: Maybe<Scalars['Int']>;
  cpf: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  currentSalary: Maybe<Scalars['Int']>;
  educations: Maybe<Array<Educations>>;
  eligibleToInternationalJobs: Scalars['Boolean'];
  email: Scalars['String'];
  emailNotification: Maybe<Scalars['Boolean']>;
  experiences: Maybe<Array<Experiences>>;
  failedAttempts: Scalars['Int'];
  firstApprovedTime: Maybe<Scalars['ISO8601DateTime']>;
  firstDashboardAccessAt: Maybe<Scalars['ISO8601DateTime']>;
  firstReferer: Maybe<Scalars['String']>;
  focus: Maybe<Scalars['Int']>;
  freelancerSalaryHour: Maybe<Scalars['Float']>;
  frelancerWeeklyHoursAvailable: Maybe<Scalars['Int']>;
  fromReferral: Maybe<Scalars['String']>;
  geekhunterEvaluatedAt: Maybe<Scalars['ISO8601DateTime']>;
  geekhunterEvaluation: Maybe<Scalars['String']>;
  geekhunterEvaluationTranslation: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  githubUrl: Maybe<Scalars['String']>;
  highlights: Maybe<Scalars['String']>;
  hiring: Maybe<Hiring>;
  hiringOption: HiringOptionEnum;
  humanizedSpecialNecessity: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Maybe<Scalars['String']>;
  importDate: Maybe<Scalars['ISO8601DateTime']>;
  isDashboardFirstAccess: Maybe<Scalars['Boolean']>;
  lastApprovedTime: Maybe<Scalars['ISO8601DateTime']>;
  lastRenderedSlug: Maybe<Scalars['String']>;
  linkedInUrl: Maybe<Scalars['String']>;
  localWorkPreference: Maybe<Scalars['String']>;
  lockedAt: Maybe<Scalars['ISO8601DateTime']>;
  md5Email: Maybe<Scalars['String']>;
  minimumSalary: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  noExperiences: Maybe<Scalars['Boolean']>;
  notes: Maybe<Scalars['String']>;
  partner: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  pipedrivePersonId: Maybe<Scalars['String']>;
  pjSalary: Maybe<Scalars['Float']>;
  portfolioExtraUrl: Maybe<Scalars['String']>;
  portfolioUrl: Maybe<Scalars['String']>;
  positionTitle: Maybe<Scalars['String']>;
  profileCompletedAt: Maybe<Scalars['ISO8601DateTime']>;
  profileLanguage: Maybe<Scalars['String']>;
  projects: Maybe<Array<Projects>>;
  rememberCreatedAt: Maybe<Scalars['ISO8601DateTime']>;
  resume: Maybe<Scalars['String']>;
  searchPools: Maybe<Array<SearchPoolFilterEnum>>;
  searchStatus: Maybe<SearchStatusEnum>;
  situation: Maybe<SituationEnum>;
  situationUpdatedAt: Maybe<Scalars['ISO8601DateTime']>;
  skills: Array<Skills>;
  skypeUsername: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  specialNecessity: Maybe<Scalars['String']>;
  stackoverflowUrl: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  summaryTranslation: Maybe<Scalars['String']>;
  techCareer: Scalars['Boolean'];
  techEvaluations: Array<TechEvaluation>;
  termsOfService: Maybe<Scalars['Boolean']>;
  totalExperienceLevel: Maybe<Scalars['Int']>;
  unconfirmedEmail: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  usdSalary: Maybe<Scalars['Float']>;
  videoAskCompleted: Scalars['Boolean'];
  videoAskResponseUrl: Maybe<Scalars['String']>;
  wasSendToTalent: Maybe<Scalars['Boolean']>;
  welcomed: Maybe<Scalars['Boolean']>;
  whatsappIdentity: Maybe<Scalars['String']>;
  workRemote: Maybe<WorkRemoteEnum>;
  workSegment: Maybe<Scalars['Int']>;
  workedUsingOnlyEnglish: Maybe<Scalars['Boolean']>;
};

export type CandidateActivityLog = {
  adminUser: Maybe<AdminUser>;
  available: Maybe<Scalars['Boolean']>;
  candidate: Maybe<Candidate>;
  count: Maybe<Scalars['Int']>;
  eventTime: Maybe<Scalars['ISO8601DateTime']>;
  eventType: Maybe<ActivityLogEventTypeEnum>;
  id: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
  situation: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
};

export type CandidateAvailableFeedback = {
  available: Scalars['Boolean'];
  candidate: Maybe<Candidate>;
  createdAt: Scalars['ISO8601DateTime'];
  feedbackType: Maybe<AvailableFeedbackEnum>;
  id: Scalars['ID'];
  message: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CandidateCities = {
  city: City;
  id: Scalars['ID'];
  order: Maybe<Scalars['Int']>;
};

export type CandidateCitiesAttributes = {
  /** City id */
  cityId: Scalars['Int'];
  /** Candidate city order */
  order: Scalars['Int'];
};

export type CandidateExcludedCompanies = {
  companyAlias: Maybe<CompanyAlias>;
  id: Scalars['ID'];
};

export type CandidateExcludedCompaniesAttributes = {
  /** Company alias id */
  name: Scalars['String'];
};

export type CandidateFiltered = {
  candidate: Candidate;
  isFavorite: Scalars['Boolean'];
  lastBidSent: Maybe<Bid>;
  lastTimeViewed: Maybe<Scalars['ISO8601DateTime']>;
  notes: Maybe<Array<Note>>;
};

export type CandidateFocus = {
  experienceLevel: Maybe<ExperienceLevel>;
  focus: Maybe<Focus>;
  id: Scalars['ID'];
  order: Maybe<Scalars['Int']>;
};

export type CandidateFocusAttributes = {
  /** Focus id */
  focusId: Scalars['Int'];
  /** Focus order */
  order: Scalars['Int'];
  /** Experience level */
  experienceLevel: Scalars['Int'];
};

export type CandidateImportedFromProxyCurl = {
  accomplishmentCourses: Maybe<Array<ProxyCurlCourse>>;
  accomplishmentHonorsAwards: Maybe<Array<ProxyCurlHonorsAwards>>;
  accomplishmentOrganisations: Maybe<Array<ProxyCurlOrg>>;
  accomplishmentPatents: Maybe<Array<ProxyCurlPatent>>;
  accomplishmentProjects: Maybe<Array<ProxyCurlProject>>;
  accomplishmentPublications: Maybe<Array<ProxyCurlPublications>>;
  accomplishmentTestScores: Maybe<Array<ProxyCurlTestScores>>;
  activities: Maybe<Array<ProxyCurlActivity>>;
  articles: Maybe<Array<ProxyCurlArticles>>;
  backgroundCoverImageUrl: Maybe<Scalars['String']>;
  certifications: Maybe<Array<ProxyCurlCertification>>;
  city: Maybe<Scalars['String']>;
  connections: Maybe<Scalars['Int']>;
  country: Maybe<Scalars['String']>;
  countryFullName: Maybe<Scalars['String']>;
  education: Maybe<Array<ProxyCurlEducation>>;
  experiences: Maybe<Array<ProxyCurlExperience>>;
  firstName: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  groups: Maybe<Array<ProxyCurlGroups>>;
  headline: Maybe<Scalars['String']>;
  languages: Maybe<Array<Scalars['String']>>;
  lastName: Maybe<Scalars['String']>;
  occupation: Maybe<Scalars['String']>;
  peopleAlsoViewed: Maybe<Array<ProxyCurlPeopleAlsoViewed>>;
  profilePicUrl: Maybe<Scalars['String']>;
  publicIdentifier: Maybe<Scalars['String']>;
  recommendations: Maybe<Array<Scalars['String']>>;
  state: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  volunteerWork: Maybe<Array<ProxyCurlVolunteeringExperience>>;
};

/** Candidate Update type */
export type CandidateInput = {
  /** Candidate clt salary */
  cltSalary?: Maybe<Scalars['Float']>;
  /** Candidate pj salary */
  pjSalary?: Maybe<Scalars['Float']>;
  /** Candidate usd salary */
  usdSalary?: Maybe<Scalars['Float']>;
  /** Hiring Option National */
  hiringOptionNational?: Maybe<Scalars['Boolean']>;
  /** Hiring Option International */
  hiringOptionInternational?: Maybe<Scalars['Boolean']>;
  /** Candidate skills */
  skillsAttributes?: Maybe<Array<SkillsAttributes>>;
  /** English level */
  englishLevel?: Maybe<Scalars['Int']>;
  /** Search status */
  searchStatus?: Maybe<SearchStatusEnum>;
};

export enum CandidateInterestJobInterestTypeEnum {
  Application = 'application',
  Interest = 'interest'
}

export type CandidateInterestJobs = {
  candidateId: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  interestType: CandidateInterestJobInterestTypeEnum;
  job: Job;
  status: Scalars['String'];
};

export type CandidateLanguages = {
  assessmentLevel: Maybe<AssessmentLevelEnum>;
  id: Scalars['ID'];
  language: Maybe<Language>;
  readingLevel: Maybe<ExperienceLevel>;
  talkingLevel: Maybe<ExperienceLevel>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  writingLevel: Maybe<ExperienceLevel>;
};

/** Candidate main info type */
export type CandidateMainInfoInput = {
  /** Candidate name */
  name: Scalars['String'];
  /** Candidate profile image */
  image?: Maybe<Scalars['File']>;
  /** Candidate position title */
  positionTitle: Scalars['String'];
  /** Candidate clt salary */
  cltSalary?: Maybe<Scalars['Float']>;
  /** Candidate pj salary */
  pjSalary?: Maybe<Scalars['Float']>;
  /** Candidate usd salary */
  usdSalary?: Maybe<Scalars['Float']>;
  /** Candidate city */
  cityId: Scalars['ID'];
  /** Candidate search status */
  searchStatus: SearchStatusEnum;
  /** Candidate linkedin url */
  linkedInUrl: Scalars['String'];
  /** Candidate github url */
  githubUrl?: Maybe<Scalars['String']>;
  /** Candidate portfolio url */
  portfolioUrl?: Maybe<Scalars['String']>;
  /** Candidate portfolio extra url */
  portfolioExtraUrl?: Maybe<Scalars['String']>;
};

export type CandidateRegisterStepAbout = {
  /** Candidate summary */
  summary: Scalars['String'];
  /** Candidate position title */
  positionTitle: Scalars['String'];
  /** Candidate excluded companies */
  excludedCompaniesAttributes?: Maybe<Array<CandidateExcludedCompaniesAttributes>>;
};

export type CandidateRegisterStepCareer = {
  /** Work remote */
  workRemote: Scalars['String'];
  /** Candidate cities */
  candidateCitiesAttributes?: Maybe<Array<CandidateCitiesAttributes>>;
  cltSalary?: Maybe<Scalars['Float']>;
  pjSalary?: Maybe<Scalars['Float']>;
  usdSalary?: Maybe<Scalars['Float']>;
};

export type CandidateRegisterStepExperiences = {
  /** Candidate have no experiences */
  noExperiences?: Maybe<Scalars['Boolean']>;
  /** Candidate experiences */
  experiencesAttributes?: Maybe<Array<ExperiencesAttributes>>;
  /** Candidate educations */
  educationsAttributes: Array<EducationsAttributes>;
  /** Candidate certifications */
  certificationsAttributes?: Maybe<Array<CertificationsAttributes>>;
  /** Candidate certifications */
  projectsAttributes?: Maybe<Array<ProjectsAttributes>>;
};

export type CandidateRegisterStepImage = {
  /** Candidate image */
  image?: Maybe<Scalars['File']>;
  /** Remove candidate image */
  removeImage?: Maybe<Scalars['Boolean']>;
};

export type CandidateRegisterStepLinkedin = {
  /** Search status */
  searchStatus: SearchStatusEnum;
  /** Candidate Linkedin URL */
  linkedInUrl: Scalars['String'];
  /** Hiring Option National */
  hiringOptionNational?: Maybe<Scalars['Boolean']>;
  /** Hiring Option International */
  hiringOptionInternational?: Maybe<Scalars['Boolean']>;
};

export type CandidateRegisterStepProfile = {
  /** Candidate name */
  name: Scalars['String'];
  /** Candidate email */
  email: Scalars['String'];
  /** Candidate phone */
  phone: Scalars['String'];
  /** Candidate city */
  cityId: Scalars['Int'];
  /** Candidate gender */
  gender: Scalars['String'];
  /** Candidate special necessity */
  specialNecessity: Scalars['String'];
  /** Candidate GitHub Url */
  githubUrl?: Maybe<Scalars['String']>;
  /** Candidate Stack Overflow Url */
  stackoverflowUrl?: Maybe<Scalars['String']>;
  /** Candidate Allow Whatsapp Notification */
  allowWhatsappNotification?: Maybe<Scalars['Boolean']>;
  /** Candidate Porfolio Url */
  portfolioUrl?: Maybe<Scalars['String']>;
  /** Candidate Extra Porfolio Url */
  portfolioExtraUrl?: Maybe<Scalars['String']>;
};

export type CandidateRegisterStepSkills = {
  /** English level */
  englishLevel?: Maybe<Scalars['Int']>;
  /** Worked Using Only English */
  workedUsingOnlyEnglish?: Maybe<Scalars['Boolean']>;
  /** Candidate focus */
  candidateFocusAttributes: Array<CandidateFocusAttributes>;
  /** Candidate skills */
  skillsAttributes?: Maybe<Array<SkillsAttributes>>;
};

export type CandidateRegistrationFirstStep = {
  /** Accept terms of service */
  termsOfService: Scalars['Boolean'];
  /** Candidate email */
  email: Scalars['String'];
  /** Accept extra contants to be sent by email - in addition to transaction emails */
  emailNotification: Scalars['Boolean'];
  /** Candidate name */
  name: Scalars['String'];
  /** Candidate password */
  password: Scalars['String'];
  /** From referral */
  fromReferral?: Maybe<Scalars['String']>;
  /** First referrer */
  firstReferer?: Maybe<Scalars['String']>;
  /** Slug job */
  lastRenderedSlug?: Maybe<Scalars['String']>;
};

/** A Career */
export type Career = {
  /** Career ID */
  id: Scalars['ID'];
  /** Career name */
  name: Maybe<Scalars['String']>;
  /** Career slug */
  slug: Maybe<CareerSlugEnum>;
  /** Career works with skills */
  worksWithSkills: Maybe<Scalars['Boolean']>;
};

export enum CareerSlugEnum {
  Data = 'data',
  Design = 'design',
  Product = 'product',
  QaTestingSupport = 'qa_testing_support',
  ItInfraSoftware = 'it_infra_software',
  Sales = 'sales',
  Cs = 'cs',
  Events = 'events',
  Marketing = 'marketing',
  Finance = 'finance',
  Hr = 'hr',
  StrategyInnovation = 'strategy_innovation',
  Operations = 'operations',
  LegalCompliance = 'legal_compliance',
  Projects = 'projects',
  Uncategorized = 'uncategorized'
}

export type Certifications = {
  current: Maybe<Scalars['Boolean']>;
  endDate: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  institution: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['ISO8601DateTime']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type CertificationsAttributes = {
  /** Institution name */
  institution: Scalars['String'];
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Start date */
  startDate: Scalars['ISO8601DateTime'];
  /** End date */
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Current */
  current: Scalars['Boolean'];
  /** URL */
  url?: Maybe<Scalars['String']>;
};

export type CheckCandidateIsRegistered = {
  isRegistered: Scalars['Boolean'];
};

export type City = {
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  state: State;
};

export type Company = {
  active: Maybe<Scalars['Boolean']>;
  address: Maybe<Scalars['String']>;
  adminUserId: Maybe<Scalars['Int']>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  baseCompanyId: Maybe<Scalars['Int']>;
  benefits: Maybe<Scalars['String']>;
  cities: Maybe<Array<City>>;
  city: Maybe<City>;
  classification: Scalars['Int'];
  companyPackages: Maybe<Array<CompanyPackage>>;
  companyUsers: Maybe<Array<CompanyUser>>;
  country: Maybe<CompanyCountryEnum>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Company current tier */
  currentTier: Maybe<TierEnum>;
  demo: CompanyDemoEnum;
  /** Says which features are enabled or not */
  features: Maybe<Features>;
  firstReferer: Maybe<Scalars['String']>;
  hasAccessToGupyIntegration: Maybe<Scalars['Boolean']>;
  /** If company has any active company package */
  hasActivePlan: Maybe<Scalars['Boolean']>;
  hasHybridJobs: Maybe<Scalars['Boolean']>;
  hasOnsiteJobs: Maybe<Scalars['Boolean']>;
  /** If company has any paused company package */
  hasPausedPackage: Maybe<Scalars['Boolean']>;
  hasRemoteJobs: Maybe<Scalars['Boolean']>;
  headoffice: Maybe<City>;
  hiringIntentAmount: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry: Maybe<Industry>;
  isInternalCompany: Scalars['Boolean'];
  isInternational: Maybe<Scalars['Boolean']>;
  jobs: Maybe<Array<Job>>;
  knowFrom: Maybe<Scalars['Int']>;
  /** Company LinkedIn */
  linkedinUrl: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  numberOfEmployees: Maybe<Scalars['String']>;
  numberOfProgrammers: Maybe<Scalars['String']>;
  onboardingCompletedAt: Maybe<Scalars['ISO8601DateTime']>;
  pausedPackage: Maybe<CompanyPackage>;
  pipedriveOrgId: Maybe<Scalars['String']>;
  preferredContactForm: Maybe<Scalars['String']>;
  registeredByTeamMemberId: Maybe<Scalars['Int']>;
  registrationFlow: Maybe<RegistrationFlowEnum>;
  representativeJob: Maybe<Scalars['String']>;
  segmentation: Maybe<CompanySegmentationEnum>;
  /** company slug used for dynamic path building */
  slug: Maybe<Scalars['String']>;
  status: Maybe<CompanyStatusEnum>;
  summary: Maybe<Scalars['String']>;
  teamMember: Maybe<TeamMember>;
  termsOfService: Maybe<Scalars['Boolean']>;
  unrestrictedView: Maybe<Scalars['Boolean']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  utmCampaign: Maybe<Scalars['String']>;
  utmMedium: Maybe<Scalars['String']>;
  utmSource: Maybe<Scalars['String']>;
  utmTerm: Maybe<Scalars['String']>;
  videoUrl: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
};

export type CompanyAlias = {
  baseCompanyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  referencesCount: Scalars['Int'];
};

/** Company Registration (International) */
export type CompanyBackOfficeInternational = {
  /** Company name */
  name: Scalars['String'];
  /** Company name */
  linkedinUrl?: Maybe<Scalars['String']>;
  /** Company state */
  state: Scalars['String'];
  /** Company country */
  country: Scalars['String'];
  /** Company industry */
  industryId: Scalars['Int'];
  /** Number of programmers in the company */
  numberOfProgrammers: Scalars['String'];
};

export enum CompanyCountryEnum {
  Brazil = 'Brazil',
  Usa = 'USA',
  Canada = 'Canada',
  China = 'China',
  Netherlands = 'Netherlands',
  England = 'England',
  Estonia = 'Estonia',
  Switzerland = 'Switzerland',
  Portugal = 'Portugal'
}

export enum CompanyDemoEnum {
  NotScheduled = 'not_scheduled',
  Scheduled = 'scheduled',
  ScheduleLater = 'schedule_later',
  ContactRequested = 'contact_requested'
}

/** Company Location enum */
export enum CompanyLocationEnum {
  /** us */
  Us = 'us',
  /** brazil */
  Brazil = 'brazil'
}

/** Company Registration (outbound) */
export type CompanyOutboundRegistration = {
  /** Company Name */
  name: Scalars['String'];
  /** Company Name */
  linkedinUrl?: Maybe<Scalars['String']>;
  /** Company Cities Ids */
  cities?: Maybe<Array<Scalars['Int']>>;
  /** Number of programmers in the company */
  numberOfProgrammers: Scalars['String'];
  /** How many devs the company intends to hire in the next 6 months */
  hiringIntentAmount?: Maybe<Scalars['String']>;
  /** Does the company have remote jobs? */
  hasRemoteJobs: Scalars['Boolean'];
  /** Does the company have hybrid jobs? */
  hasHybridJobs?: Maybe<Scalars['Boolean']>;
  /** Does the company have onsite jobs? */
  hasOnsiteJobs?: Maybe<Scalars['Boolean']>;
  /** Country Name */
  country: Scalars['String'];
};

export type CompanyPackage = {
  afterTriggerRevenue: Maybe<Scalars['Float']>;
  automaticRenewal: Scalars['Boolean'];
  beforeTriggerRevenue: Maybe<Scalars['Float']>;
  canceledAt: Maybe<Scalars['ISO8601Date']>;
  companyId: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  doneAt: Maybe<Scalars['ISO8601Date']>;
  endAt: Maybe<Scalars['ISO8601Date']>;
  farmerRevenue: Maybe<Scalars['Float']>;
  hiringCount: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  packagePlan: PackagePlan;
  packagePlanId: Maybe<Scalars['Int']>;
  packageSize: Maybe<Scalars['Int']>;
  packageType: Maybe<CompanyPackageEnum>;
  pauseEndAt: Maybe<Scalars['ISO8601Date']>;
  pauseStartAt: Maybe<Scalars['ISO8601Date']>;
  realRevenue: Maybe<Scalars['Float']>;
  revenue: Maybe<Scalars['Float']>;
  sellerTeamMemberId: Maybe<Scalars['Int']>;
  startAt: Maybe<Scalars['ISO8601Date']>;
  status: Maybe<CompanyPackageStatusEnum>;
  trigger: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Company Package Registration (International) */
export type CompanyPackageBackOfficeInternational = {
  /** When the package starts */
  startAt: Scalars['ISO8601Date'];
  /** When the package ends */
  endAt: Scalars['ISO8601Date'];
  /** How many hires the package allows */
  hiringCount: Scalars['Int'];
  /** The size of the package in positions */
  packageSize: Scalars['Int'];
  /** Revenue */
  revenue: Scalars['Float'];
  /** Its automatic renewed? */
  automaticRenewal: Scalars['Boolean'];
  /** Has any trigger */
  trigger: Scalars['Boolean'];
  /** If trigger, the revenue before trigger */
  beforeTriggerRevenue?: Maybe<Scalars['Float']>;
  /** after trigger revenue */
  afterTriggerRevenue?: Maybe<Scalars['Float']>;
};

export enum CompanyPackageEnum {
  Normal = 'normal',
  Trigger = 'trigger',
  Basic = 'basic',
  Flex = 'flex',
  NewFlex = 'new_flex'
}

/** Company Package Registration */
export type CompanyPackageOutboundRegistration = {
  /** When the package starts */
  startAt: Scalars['ISO8601Date'];
  /** When the package ends */
  endAt: Scalars['ISO8601Date'];
  /** How many hires the package allows */
  hiringCount: Scalars['Int'];
  /** Package Plan ID */
  packagePlanId: Scalars['Int'];
  /** The size of the package in positions */
  packageSize: Scalars['Int'];
  /** Revenue */
  revenue: Scalars['Float'];
  /** Its automatic renewed? */
  automaticRenewal: Scalars['Boolean'];
  /** Has any trigger */
  trigger: Scalars['Boolean'];
  /** If trigger, the revenue before trigger */
  beforeTriggerRevenue?: Maybe<Scalars['Float']>;
  /** after trigger revenue */
  afterTriggerRevenue?: Maybe<Scalars['Float']>;
};

export type CompanyPackageRemainingDuration = {
  diff: Maybe<Scalars['Int']>;
  diffType: Maybe<Scalars['String']>;
};

export enum CompanyPackageStatusEnum {
  Canceled = 'canceled',
  Open = 'open',
  Done = 'done',
  Upsell = 'upsell',
  Paused = 'paused'
}

export enum CompanyPlanUpgradeTriggerEnum {
  ShowSalary = 'show_salary',
  ShowSocialNetworks = 'show_social_networks',
  ShowSearchStatus = 'show_search_status',
  ShowSelectedByIa = 'show_selected_by_ia',
  ShowEvaluatedByGeek = 'show_evaluated_by_geek',
  FilterBySalary = 'filter_by_salary',
  FilterByDiversity = 'filter_by_diversity',
  FilterBySearchStatus = 'filter_by_search_status',
  FilterByRecommended = 'filter_by_recommended',
  AddNewJobs = 'add_new_jobs',
  AddUsers = 'add_users',
  ShowMoreCandidates = 'show_more_candidates'
}

export type CompanySearchCandidate = {
  candidates: Array<CandidateFiltered>;
  company: Company;
  companyCandidateSearchId: Maybe<Scalars['ID']>;
  isDefaultSearch: Scalars['Boolean'];
  searchPool: SearchPoolFilterEnum;
  totalFilteredCount: Maybe<Scalars['Int']>;
};

export enum CompanySegmentationEnum {
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  G4 = 'G4',
  G5 = 'G5',
  I1 = 'I1'
}

export enum CompanyStatusEnum {
  PendingApproval = 'pending_approval',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Lost = 'lost',
  Limbo = 'limbo',
  Blocked = 'blocked'
}

export type CompanyToggleFavoriteCandidates = {
  isFavorite: Scalars['Boolean'];
};

export type CompanyToggleHiddenCandidate = {
  isHidden: Scalars['Boolean'];
};

export type CompanyUser = {
  accessHub: Maybe<CompanyUserAccessHubEnum>;
  active: Scalars['Boolean'];
  company: Maybe<Company>;
  confirmationSentAt: Maybe<Scalars['ISO8601DateTime']>;
  confirmedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  currentSignInAt: Maybe<Scalars['ISO8601DateTime']>;
  currentSignInIp: Maybe<Scalars['String']>;
  email: Scalars['String'];
  failedAttempts: Scalars['Int'];
  firstReferer: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle: Maybe<Scalars['String']>;
  lastSignInAt: Maybe<Scalars['ISO8601DateTime']>;
  lastSignInIp: Maybe<Scalars['String']>;
  lockedAt: Maybe<Scalars['ISO8601DateTime']>;
  md5Email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  otpRequiredForLogin: Maybe<Scalars['Boolean']>;
  phoneNumber: Maybe<Scalars['String']>;
  receiveNotifications: Maybe<Scalars['Boolean']>;
  rememberCreatedAt: Maybe<Scalars['ISO8601DateTime']>;
  role: Maybe<CompanyUserRoleEnum>;
  signInCount: Scalars['Int'];
  termsOfService: Maybe<Scalars['Boolean']>;
  twoFactorAuthenticationIsConfigured: Scalars['Boolean'];
  twoFactorAuthenticationQrCodeUri: Maybe<Scalars['String']>;
  unconfirmedEmail: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  utmCampaign: Maybe<Scalars['String']>;
  utmMedium: Maybe<Scalars['String']>;
  utmSource: Maybe<Scalars['String']>;
  utmTerm: Maybe<Scalars['String']>;
};

export enum CompanyUserAccessHubEnum {
  Limited = 'limited',
  Full = 'full'
}

export type CompanyUserActivityLog = {
  bid: Maybe<Bid>;
  candidate: Maybe<Candidate>;
  company: Maybe<Company>;
  companyUser: Maybe<CompanyUser>;
  eventTime: Maybe<Scalars['ISO8601DateTime']>;
  eventType: Maybe<CompanyUserActivityLogEventTypeEnum>;
  id: Scalars['ID'];
  log: Maybe<Scalars['String']>;
};

export enum CompanyUserActivityLogEventTypeEnum {
  RegistrationsIndex = 'registrations_index',
  RegistrationsShow = 'registrations_show',
  RegistrationsIndexNotAuthorized = 'registrations_index_not_authorized',
  RegistrationsShowNotAvailable = 'registrations_show_not_available',
  GeekFlowIndex = 'geek_flow_index',
  GeekFlowRecommendation = 'geek_flow_recommendation',
  GeekFlowCandidate = 'geek_flow_candidate',
  AcceptTermsOfService = 'accept_terms_of_service',
  UnsetJobHiddenCandidate = 'unset_job_hidden_candidate',
  GeneralContactShow = 'general_contact_show',
  LinkedinContactShow = 'linkedin_contact_show',
  GithubContactShow = 'github_contact_show',
  StackOverflowContactShow = 'stack_overflow_contact_show',
  PortfolioContactShow = 'portfolio_contact_show',
  PortfolioExtraContactShow = 'portfolio_extra_contact_show',
  WhatsappContactShow = 'whatsapp_contact_show',
  GeekFlowRemoveRecommendation = 'geek_flow_remove_recommendation',
  GeekFlowCreateRecommendation = 'geek_flow_create_recommendation',
  CandidateNoteShow = 'candidate_note_show',
  GeekFlowCandidateApplication = 'geek_flow_candidate_application',
  TrialUpgradeCta = 'trial_upgrade_cta',
  TrialUpgradeRequest = 'trial_upgrade_request',
  SearchPoolSelectedByIaUpgradeRequest = 'search_pool_selected_by_ia_upgrade_request',
  SearchPoolGeekhunterApprovedUpgradeRequest = 'search_pool_geekhunter_approved_upgrade_request',
  PlanUpgradeRequest = 'plan_upgrade_request',
  RegistrationsChatPage = 'registrations_chat_page',
  CompanyAccessHubPositionsPage = 'company_access_hub_positions_page',
  CompanyAccessHubSalaryPage = 'company_access_hub_salary_page'
}

/** Company User Registration (International) */
export type CompanyUserBackOfficeInternational = {
  /** Company user name */
  name: Scalars['String'];
  /** Company user email */
  email: Scalars['String'];
  /** Company user phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum CompanyUserLanguageEnum {
  PtBr = 'pt_br',
  En = 'en'
}

/** Company User Registration (outbound) */
export type CompanyUserOutboundRegistration = {
  /** Company User Name */
  name: Scalars['String'];
  /** Company User E-Mail */
  email: Scalars['String'];
  /** Company User Phone Number */
  phoneNumber: Scalars['String'];
  /** Company User Password */
  password: Scalars['String'];
};

export enum CompanyUserRoleEnum {
  Traas = 'traas',
  Administrator = 'administrator',
  Manager = 'manager',
  Viewer = 'viewer'
}

export type CompanyUserSwitchLanguage = {
  language: CompanyUserLanguageEnum;
};

/** Company User activation/inactivation arguments */
export type CompanyUserToggleActiveInput = {
  /** Should activate or inactivate? */
  active: Scalars['Boolean'];
  /** The id of the Company User to toggle */
  companyUserIdToToggle: Scalars['Int'];
  /** If inactivating, the if of the Company User who will receive the data from the inactivated Company User */
  companyUserIdToMigrate?: Maybe<Scalars['Int']>;
};

/** Confirmation of a Hiring Bypass arguments */
export type ConfirmHiringBypassInput = {
  /** Bypass ID to be confirmed */
  bypassId: Scalars['Int'];
  /** Candidate ID bypassed */
  candidateId: Scalars['Int'];
  /** Company ID who bypass the hiring */
  companyId: Scalars['Int'];
  /** Company User who interacted with the Candidate */
  companyUserId: Scalars['Int'];
  /** The Job linked with the bypass, if exist */
  jobId?: Maybe<Scalars['Int']>;
};

export type Country = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateCompanyBackOfficeOutbound */
export type CreateCompanyBackOfficeOutboundPayload = {
  /** the company created */
  company: Maybe<Company>;
};

export type CreateCurrentCandidateInterestJob = {
  /** Job id */
  jobId: Scalars['Int'];
  /** Type */
  interestType: Scalars['String'];
};

/** Autogenerated return type of CreateCurrentCandidateInterestJob */
export type CreateCurrentCandidateInterestJobPayload = {
  candidateInterestJob: Maybe<CandidateInterestJobs>;
  success: Maybe<Scalars['Boolean']>;
};

/** Company User updating arguments */
export type CreateOrUpdateCompanyUserInput = {
  /** Company User name */
  name: Scalars['String'];
  /** Company User email */
  email: Scalars['String'];
  /** Company User role (e.g. manager) */
  role: Scalars['String'];
  /** Company User phone number */
  phoneNumber: Scalars['String'];
  /** Allow to receive notifications by email */
  receiveNotifications?: Maybe<Scalars['Boolean']>;
  /** Company User job title at current company */
  jobTitle?: Maybe<Scalars['String']>;
  /** Company User access to Hub */
  accessHub?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateScreeningApplicant */
export type CreateScreeningApplicantPayload = {
  confirmationToken: Maybe<Scalars['String']>;
  firstApplication: Maybe<Scalars['Boolean']>;
  ok: Scalars['Boolean'];
};

/** Autogenerated return type of CreateSubscriptionPlan */
export type CreateSubscriptionPlanPayload = {
  errors: Array<Scalars['String']>;
  subscriptionPlan: Maybe<SubscriptionPlan>;
};

export enum EducationLevelEnum {
  All = 'all',
  PostgraduateOrHigher = 'postgraduate_or_higher',
  CompleteDegree = 'complete_degree',
  IncompleteDegree = 'incomplete_degree',
  Technician = 'technician'
}

export type Educations = {
  course: Maybe<Scalars['String']>;
  durationInYears: Maybe<Scalars['Int']>;
  educationStatus: Maybe<Scalars['String']>;
  endYear: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  institution: Maybe<Scalars['String']>;
  startYear: Maybe<Scalars['Int']>;
  title: Maybe<EnumerizeValue>;
};

export type EducationsAttributes = {
  /** Institution name */
  institution: Scalars['String'];
  /** Course name */
  course: Scalars['String'];
  /** Title */
  title?: Maybe<Scalars['Int']>;
  /** Start year */
  startYear: Scalars['Int'];
  /** End year */
  endYear: Scalars['Int'];
};

export type EnumerizeValue = {
  value: Maybe<Scalars['Int']>;
};

export enum EventTypeEnum {
  /** Presencial */
  Presential = 'presential',
  /** Remoto */
  Remote = 'remote'
}

export type ExperienceLevel = {
  text: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Int']>;
};

/** Experience level enum */
export enum ExperienceLevelEnum {
  Assistance = 'ASSISTANCE',
  Internship = 'INTERNSHIP',
  EntryLevel = 'ENTRY_LEVEL',
  MidLevel = 'MID_LEVEL',
  SeniorLevel = 'SENIOR_LEVEL',
  Director = 'DIRECTOR',
  Executive = 'EXECUTIVE',
  NotApplicable = 'NOT_APPLICABLE'
}

export type ExperienceTimeFilter = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type Experiences = {
  company: Maybe<Scalars['String']>;
  currentJob: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  descriptionTranslation: Maybe<Scalars['String']>;
  durationInMonths: Maybe<Scalars['Int']>;
  endDate: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  jobTitle: Maybe<Scalars['String']>;
  skills: Maybe<Array<Skills>>;
  startDate: Maybe<Scalars['ISO8601DateTime']>;
};

export type ExperiencesAttributes = {
  /** Company name */
  company: Scalars['String'];
  /** Job title */
  jobTitle: Scalars['String'];
  /** Start date */
  startDate: Scalars['ISO8601DateTime'];
  /** End date */
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Is current job */
  currentJob: Scalars['Boolean'];
  /** Job description */
  description: Scalars['String'];
  /** Job skills */
  experienceSkillsAttributes: Array<ExperiencesSkillsAttributes>;
};

export type ExperiencesSkillsAttributes = {
  /** Institution name */
  skillId: Scalars['Int'];
};

export enum FeatureFlagsEnum {
  SearchPoolSelectedByIa = 'search_pool_selected_by_ia',
  SearchPoolGeekhunterApproved = 'search_pool_geekhunter_approved'
}

export type Features = {
  accessCompanyUserRoles: Scalars['Boolean'];
  accessToAccountManager: Scalars['Boolean'];
  accessToAts: Scalars['Boolean'];
  accessToHub: Scalars['Boolean'];
  addNewUsers: Scalars['Boolean'];
  diversityFilter: Scalars['Boolean'];
  searchPoolGeekhunterApproved: Scalars['Boolean'];
  searchPoolSelectedByIa: Scalars['Boolean'];
  showEnglishVetting: Scalars['Boolean'];
  showProfileTranslation: Scalars['Boolean'];
  showRecommendations: Scalars['Boolean'];
  showSalary: Scalars['Boolean'];
  showSocialNetworks: Scalars['Boolean'];
  showTechVetting: Scalars['Boolean'];
  showWorkAvailability: Scalars['Boolean'];
  standard3Jobs: Scalars['Boolean'];
};

export type Feedback = {
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};


export type FilterWithMinMaxRange = {
  id: Scalars['ID'];
  min: Scalars['Int'];
  max: Scalars['Int'];
};

/** A Focus */
export type Focus = {
  /** Career */
  career: Career;
  /** Focus description */
  description: Maybe<Scalars['String']>;
  /** Focus ID */
  id: Scalars['ID'];
};

export enum GenderEnum {
  Feminine = 'feminine',
  Masculine = 'masculine',
  Other = 'other',
  NonBinary = 'non_binary',
  PreferNotAnswer = 'prefer_not_answer'
}

export type GetCandidateProfileData = {
  candidate: Candidate;
};

export type GetFeatureFlag = {
  featureFlag: FeatureFlagsEnum;
  actorId?: Maybe<Scalars['Int']>;
  actorModel?: Maybe<ActorModelsEnum>;
};

export type GetFocusSuggestions = {
  focus: Array<Focus>;
};

export type GetRegisterSuggestions = {
  cities: Array<City>;
  techs: Array<Technology>;
};

export type Hiring = {
  adminApproval: Maybe<Scalars['Int']>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  approvedByAccounting: Maybe<Scalars['Int']>;
  bidId: Maybe<Scalars['Int']>;
  candidate: Maybe<Candidate>;
  candidateId: Maybe<Scalars['Int']>;
  candidateSalary: Maybe<Scalars['Float']>;
  candidateStartDate: Maybe<Scalars['ISO8601Date']>;
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']>;
  companyPackages: Maybe<Array<CompanyPackage>>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  hasSigninBonusPaid: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  job: Maybe<Job>;
  jobId: Maybe<Scalars['Int']>;
  origin: Maybe<HiringEnumTypes>;
  packageId: Maybe<Scalars['Int']>;
  replacement: Maybe<Replacement>;
  revenue: Maybe<Scalars['Float']>;
  signinBonusRequested: Maybe<Scalars['ISO8601DateTime']>;
  source: Scalars['Int'];
  status: Maybe<HiringEnumTypes>;
  teamMemberId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};

export enum HiringEnumTypes {
  Pending = 'pending',
  Hired = 'hired',
  InReplacement = 'in_replacement',
  Replaced = 'replaced',
  Refused = 'refused',
  Mistake = 'mistake'
}

export enum HiringOptionEnum {
  National = 'national',
  International = 'international',
  Both = 'both'
}

export type HubPosition = {
  focus: Maybe<Focus>;
  focusId: Maybe<Scalars['Int']>;
  hubSalaryStatistic: Maybe<HubSalaryStatistic>;
  id: Scalars['ID'];
  internalRole: Maybe<Scalars['String']>;
  jobSalaries: JobSalaries;
  level: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  origin: Scalars['String'];
  post: Maybe<Scalars['String']>;
  salary: Maybe<Scalars['Float']>;
  segment: Maybe<Scalars['String']>;
  seniority: Maybe<Scalars['String']>;
};

export type HubSalaryStatistic = {
  basis: Scalars['String'];
  max: Scalars['Float'];
  mean: Scalars['Float'];
  median: Scalars['Float'];
  min: Scalars['Float'];
  numberOfSamples: Scalars['Int'];
};



export type Industry = {
  active: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type InterviewEvent = {
  bid: Maybe<Bid>;
  candidate: Maybe<Candidate>;
  company: Maybe<Company>;
  companyUser: Maybe<CompanyUser>;
  createdAt: Scalars['ISO8601DateTime'];
  end: Maybe<Scalars['ISO8601DateTime']>;
  eventStatus: Maybe<Scalars['Int']>;
  eventType: Maybe<EventTypeEnum>;
  id: Scalars['ID'];
  note: Maybe<Scalars['String']>;
  start: Maybe<Scalars['ISO8601DateTime']>;
  statusAcknowledgedByCompany: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Job = {
  active: Maybe<Scalars['Boolean']>;
  activities: Maybe<Scalars['String']>;
  adminApproval: Maybe<Scalars['Boolean']>;
  annualBonus: Maybe<Scalars['String']>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  archiveTime: Maybe<Scalars['ISO8601DateTime']>;
  bidsCount: Maybe<Scalars['Int']>;
  careerExperienceLevel: Maybe<Scalars['Int']>;
  city: Maybe<City>;
  cityId: Maybe<Scalars['Int']>;
  cltMaxSalary: Maybe<Scalars['Float']>;
  cltMinSalary: Maybe<Scalars['Float']>;
  company: Maybe<Company>;
  companyId: Scalars['Int'];
  companyUserId: Scalars['Int'];
  confidential: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  englishLevel: Maybe<Scalars['String']>;
  experienceLevel: Maybe<ExperienceLevelEnum>;
  externalApplyUrl: Maybe<Scalars['String']>;
  focus: Maybe<Focus>;
  focusExperienceLevel: Maybe<Scalars['Int']>;
  focusId: Maybe<Scalars['Int']>;
  hideCompany: Maybe<Scalars['Boolean']>;
  hideSalary: Maybe<Scalars['Boolean']>;
  hirings: Maybe<Array<Hiring>>;
  hybrid: Scalars['Boolean'];
  id: Scalars['ID'];
  isInternational: Maybe<Scalars['Boolean']>;
  jobBenefits: Array<JobBenefit>;
  jobTechnologies: Maybe<Array<JobTechnology>>;
  jobType: Maybe<JobTypeEnum>;
  keywords: Maybe<Scalars['String']>;
  lastBidMessage: Maybe<Scalars['String']>;
  maxEquity: Maybe<Scalars['Float']>;
  maxSalary: Maybe<Scalars['Float']>;
  minEquity: Maybe<Scalars['Float']>;
  minSalary: Maybe<Scalars['Float']>;
  operationStatus: Maybe<Scalars['Int']>;
  pjMaxSalary: Maybe<Scalars['Float']>;
  pjMinSalary: Maybe<Scalars['Float']>;
  publishedScreening: Maybe<Screening>;
  recommendationsCount: Maybe<Scalars['Int']>;
  remoteWork: Maybe<Scalars['Boolean']>;
  requiredEnglish: Maybe<Scalars['Boolean']>;
  requirements: Maybe<Scalars['String']>;
  segment: Maybe<Segment>;
  slug: Maybe<Scalars['String']>;
  strength: Maybe<Scalars['Int']>;
  teamMember: Maybe<TeamMember>;
  teamMemberId: Maybe<Scalars['Int']>;
  techCareer: Scalars['Boolean'];
  technologies: Maybe<Array<Technology>>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  usdAnnualSalaryMax: Maybe<Scalars['Float']>;
  usdAnnualSalaryMin: Maybe<Scalars['Float']>;
  warnedAboutInactivity: Maybe<Scalars['Boolean']>;
  workTypeId: Maybe<Scalars['Int']>;
};

export type JobBenefit = {
  benefit: Benefit;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** min and max compensations from active and approved jobs for pj and clt */
export type JobMinMaxSalary = {
  /** Maximum salary */
  maxSalary: Scalars['Float'];
  /** Minimum salary */
  minSalary: Scalars['Float'];
};

export type JobSalaries = {
  maxSalary: Maybe<Scalars['Float']>;
  minSalary: Maybe<Scalars['Float']>;
};

export type JobSearchCandidate = {
  companyUserName: Scalars['String'];
  id: Scalars['ID'];
  job: Job;
  maxSalary: Scalars['Float'];
};

export type JobTechnology = {
  id: Scalars['ID'];
  required: Maybe<Scalars['Boolean']>;
  technology: Maybe<Technology>;
};

/** Job type enum */
export enum JobTypeEnum {
  FullSupport = 'full_support',
  FullSupportWithApplication = 'full_support_with_application',
  Ats = 'ats'
}

export type KeyValueString = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Language = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};

export type LanguageFilter = {
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
};

export type Message = {
  candidate: Candidate;
  company: Company;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  messages: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MinimumCompanySerializer = {
  address: Maybe<Scalars['String']>;
  fullView: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  logo: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type Mutation = {
  /** Accepts the CompanyUser terms of use: https://www.geekhunter.com.br/termos-de-uso */
  acceptCompanyUserTermsOfService: AcceptTermsOfServicePayload;
  activateCompany: Company;
  /** Add new question to screening */
  addNewQuestionToScreening: Question;
  adminHiringUpdateStatus: AdminHiringUpdateStatusPayload;
  /** Company cancels its subscription. Available for all plans subscribed through the checkout */
  companyCancelSubscription: Ok;
  /** Company requests an upgrade. Works for either plan upgrade or search pools upgrade */
  companyRequestUpgrade: Ok;
  /** Confirm a Hiring Bypass, creating the respective hiring and job position */
  confirmHiringBypass: Ok;
  /** Company user creates a bid */
  createBid: Bid;
  /** Register a company and a user through backoffice create international flow */
  createCompanyBackOfficeInternational: Company;
  /** Register a company and a user through signup flow or international flow */
  createCompanyBackOfficeOutbound: CreateCompanyBackOfficeOutboundPayload;
  /** Creates Company User */
  createCompanyUser: Ok;
  /** Creates a CompanyUserActivityLog based on the event type */
  createCompanyUserActivityLog: CompanyUserActivityLog;
  createCurrentCandidateActivityLog: CandidateActivityLog;
  createCurrentCandidateAvailableFeedback: CandidateAvailableFeedback;
  createCurrentCandidateInterestJob: CreateCurrentCandidateInterestJobPayload;
  /** Company user creates a note about a candidate */
  createNote: Note;
  createProspect: Prospect;
  /** Create new screening candidate for applicants and create application */
  createScreeningApplicant: CreateScreeningApplicantPayload;
  /** Create new screening candidate and demonstrate interest in job */
  createScreeningCandidate: Ok;
  createSubscriptionPlan: CreateSubscriptionPlanPayload;
  /** Delete question */
  deleteQuestion: Ok;
  /** Disable Two Factor Authentication */
  disableTwoFactorAuthentication: Ok;
  /** Discard Screening */
  discardScreening: Ok;
  /** Enable Two Factor Authentication */
  enableTwoFactorAuthentication: Ok;
  /** Generate Two Factor Authentication Secret */
  generateTwoFactorAuthenticationSecret: Ok;
  inactivateCompany: Company;
  /** Publish Screening */
  publishScreening: Ok;
  purchasePlan: Ok;
  registerAmplitudeHit: Ok;
  registerAmplitudeHitAuthenticated: Ok;
  registerCandidateFirstStep: RegisterCandidateFirstStepPayload;
  registerCandidateStepAbout: RegisterCandidateStepAboutPayload;
  registerCandidateStepCareer: RegisterCandidateStepCareerPayload;
  registerCandidateStepExperiences: RegisterCandidateStepExperiencesPayload;
  registerCandidateStepImage: RegisterCandidateStepImagePayload;
  registerCandidateStepLinkedin: RegisterCandidateStepLinkedinPayload;
  /** Register candidate step profile */
  registerCandidateStepProfile: RegisterCandidateStepProfilePayload;
  registerCandidateStepSkills: RegisterCandidateStepSkillsPayload;
  rejectCandidateInterestJob: RejectCandidateInterestJobPayload;
  scheduleDemonstration: SchedulePayload;
  sendCompanyUserEmailConfirmationInstructions: Ok;
  setCurrentCandidateDashboardFirstAccess: Candidate;
  setCurrentCandidateStatus: Candidate;
  setCurrentCandidateVideoAskCompleted: Candidate;
  setCurrentCandidateWhatsappNotification: Candidate;
  /** Submit candidate's application form */
  submitApplicationForm: SubmitApplicationFormPayload;
  /** Switches the language used by company users */
  switchCompanyUserLanguage: CompanyUserSwitchLanguage;
  /** Activates or Inactivates a Company User */
  toggleActive: Ok;
  toggleFavoriteCandidate: CompanyToggleFavoriteCandidates;
  toggleHiddenCandidate: CompanyToggleHiddenCandidate;
  /** Candidate Update */
  updateCandidate: Candidate;
  /** Candidate Update Main Info */
  updateCandidateMainInfo: UpdateCandidateMainInfoPayload;
  updateCompanyCandidateSearchResultClicked: Scalars['ID'];
  /** Updates Company User */
  updateCompanyUser: Ok;
  /** Company user updates a note about a candidate */
  updateNote: Note;
  updatePartnerIntegration: PartnerIntegration;
  /** Update a question to screening */
  updateQuestion: Question;
};


export type MutationActivateCompanyArgs = {
  companyId: Scalars['ID'];
};


export type MutationAddNewQuestionToScreeningArgs = {
  inputQuestion: QuestionInput;
};


export type MutationAdminHiringUpdateStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['String'];
};


export type MutationCompanyRequestUpgradeArgs = {
  searchPool?: Maybe<SearchPoolFilterEnum>;
  planUpgradeTrigger?: Maybe<CompanyPlanUpgradeTriggerEnum>;
  targetTier?: Maybe<TierEnum>;
};


export type MutationConfirmHiringBypassArgs = {
  params: ConfirmHiringBypassInput;
};


export type MutationCreateBidArgs = {
  bidInputParams: BidInput;
};


export type MutationCreateCompanyBackOfficeInternationalArgs = {
  companyParams: CompanyBackOfficeInternational;
  companyPackageParams: CompanyPackageBackOfficeInternational;
  companyUserParams: CompanyUserBackOfficeInternational;
};


export type MutationCreateCompanyBackOfficeOutboundArgs = {
  companyParams?: Maybe<CompanyOutboundRegistration>;
  companyUserParams: CompanyUserOutboundRegistration;
  companyPackageParams: CompanyPackageOutboundRegistration;
};


export type MutationCreateCompanyUserArgs = {
  params: CreateOrUpdateCompanyUserInput;
};


export type MutationCreateCompanyUserActivityLogArgs = {
  eventType: CompanyUserActivityLogEventTypeEnum;
};


export type MutationCreateCurrentCandidateActivityLogArgs = {
  eventType: ActivityLogEventTypeEnum;
};


export type MutationCreateCurrentCandidateAvailableFeedbackArgs = {
  available: Scalars['Boolean'];
  feedbackType: AvailableFeedbackEnum;
  message?: Maybe<Scalars['String']>;
};


export type MutationCreateCurrentCandidateInterestJobArgs = {
  params: CreateCurrentCandidateInterestJob;
};


export type MutationCreateNoteArgs = {
  noteInputParams: NoteInput;
};


export type MutationCreateProspectArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  countryId: Scalars['ID'];
  phone: Scalars['String'];
};


export type MutationCreateScreeningApplicantArgs = {
  inputScreeningApplicant: ScreeningApplicantInput;
};


export type MutationCreateScreeningCandidateArgs = {
  inputScreeningCandidate: ScreeningCandidateInput;
};


export type MutationCreateSubscriptionPlanArgs = {
  name: Scalars['String'];
  price: Scalars['Float'];
  isActive: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  period: PeriodEnum;
  slug: Scalars['String'];
  companyPackageId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['String'];
};


export type MutationDiscardScreeningArgs = {
  screeningId: Scalars['Int'];
};


export type MutationInactivateCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationPublishScreeningArgs = {
  screeningId: Scalars['Int'];
};


export type MutationPurchasePlanArgs = {
  params: Purchase;
};


export type MutationRegisterAmplitudeHitArgs = {
  amplitudeHitType: AmplitudeHitOptionsEnum;
  userEmail?: Maybe<Scalars['String']>;
  userType?: Maybe<AmplitudeUserTypeEnum>;
  anonymous?: Maybe<Scalars['Boolean']>;
};


export type MutationRegisterAmplitudeHitAuthenticatedArgs = {
  amplitudeHitType: AmplitudeHitOptionsEnum;
};


export type MutationRegisterCandidateFirstStepArgs = {
  candidateParams: CandidateRegistrationFirstStep;
  trackConversionParams?: Maybe<TrackConversion>;
};


export type MutationRegisterCandidateStepAboutArgs = {
  params: CandidateRegisterStepAbout;
};


export type MutationRegisterCandidateStepCareerArgs = {
  params: CandidateRegisterStepCareer;
};


export type MutationRegisterCandidateStepExperiencesArgs = {
  params: CandidateRegisterStepExperiences;
};


export type MutationRegisterCandidateStepImageArgs = {
  params: CandidateRegisterStepImage;
};


export type MutationRegisterCandidateStepLinkedinArgs = {
  params: CandidateRegisterStepLinkedin;
};


export type MutationRegisterCandidateStepProfileArgs = {
  params: CandidateRegisterStepProfile;
};


export type MutationRegisterCandidateStepSkillsArgs = {
  params: CandidateRegisterStepSkills;
};


export type MutationRejectCandidateInterestJobArgs = {
  id: Scalars['ID'];
};


export type MutationScheduleDemonstrationArgs = {
  id: Scalars['Int'];
  notifySales: Scalars['Boolean'];
  preferredContactForm?: Maybe<Scalars['String']>;
  type?: Maybe<ScheduleTypeEnum>;
};


export type MutationSetCurrentCandidateDashboardFirstAccessArgs = {
  isDashboardFirstAccess: Scalars['Boolean'];
};


export type MutationSetCurrentCandidateStatusArgs = {
  searchStatus: SearchStatusEnum;
  available: Scalars['Boolean'];
};


export type MutationSetCurrentCandidateVideoAskCompletedArgs = {
  videoAskCompleted: Scalars['Boolean'];
  videoAskResponseUrl?: Maybe<Scalars['String']>;
};


export type MutationSetCurrentCandidateWhatsappNotificationArgs = {
  allowWhatsappNotification: Scalars['Boolean'];
};


export type MutationSubmitApplicationFormArgs = {
  params: SubmitApplicationForm;
};


export type MutationSwitchCompanyUserLanguageArgs = {
  language: Scalars['String'];
};


export type MutationToggleActiveArgs = {
  params: CompanyUserToggleActiveInput;
};


export type MutationToggleFavoriteCandidateArgs = {
  candidateId: Scalars['Int'];
  companyUserId: Scalars['Int'];
};


export type MutationToggleHiddenCandidateArgs = {
  candidateId: Scalars['Int'];
  companyUserId: Scalars['Int'];
};


export type MutationUpdateCandidateArgs = {
  payload: CandidateInput;
};


export type MutationUpdateCandidateMainInfoArgs = {
  payload: CandidateMainInfoInput;
};


export type MutationUpdateCompanyCandidateSearchResultClickedArgs = {
  searchId: Scalars['ID'];
  candidateId: Scalars['ID'];
};


export type MutationUpdateCompanyUserArgs = {
  id: Scalars['ID'];
  params: CreateOrUpdateCompanyUserInput;
};


export type MutationUpdateNoteArgs = {
  noteUpdateParams: NoteUpdate;
};


export type MutationUpdatePartnerIntegrationArgs = {
  id: Scalars['ID'];
  params: UpdatePartnerIntegration;
};


export type MutationUpdateQuestionArgs = {
  inputQuestion: QuestionInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

export type Note = {
  actor: Actor;
  actorName: Scalars['String'];
  affected: Affected;
  createdAt: Scalars['ISO8601DateTime'];
  excluded: Scalars['Boolean'];
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  note: Scalars['String'];
  noteType: NoteTypeEnum;
};

/** Note Input Type */
export type NoteInput = {
  affectedType: Scalars['String'];
  affectedId: Scalars['Int'];
  noteType: NoteTypeEnum;
  note: Scalars['String'];
};

export enum NoteTypeEnum {
  CompanyUserNoteAboutCandidate = 'company_user_note_about_candidate'
}

/** Note Update Type */
export type NoteUpdate = {
  noteId: Scalars['Int'];
  excluded: Scalars['Boolean'];
};

export type Ok = {
  ok: Scalars['Boolean'];
};

/** Order enum */
export enum OrderEnum {
  /** Mais Recentes */
  Newer = 'newer',
  /** Mais Antigos */
  Older = 'older',
  /** Menor Remuneração */
  MinSalary = 'min_salary',
  /** Maior Remuneração */
  MaxSalary = 'max_salary'
}

export type PackagePlan = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  restrictedView: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};

export type PackageSerializer = {
  companyId: Scalars['Int'];
  endAt: Scalars['String'];
  hiringCount: Scalars['Int'];
  id: Scalars['ID'];
  packagePlan: Maybe<Scalars['String']>;
  packageSize: Maybe<Scalars['Int']>;
  remainingDuration: Maybe<CompanyPackageRemainingDuration>;
  remainingPositions: Maybe<Scalars['Int']>;
  startAt: Scalars['String'];
  status: Maybe<Scalars['String']>;
};

/** Pagination Type */
export type PaginationFilter = {
  /** Offset */
  page: Scalars['Int'];
  /** Limit */
  perPage: Scalars['Int'];
};

export type PartnerIntegration = {
  /** Is the integration active? */
  active: Scalars['Boolean'];
  companyId: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Company subdomain at partner service */
  domain: Maybe<Scalars['String']>;
  /** If the company has agreed to the Gupy integration terms */
  hasAgreedToTerms: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Partner name */
  partner: Scalars['String'];
  /** Company ID at partner service */
  partnerCompanyId: Maybe<Scalars['String']>;
  partnerId: Scalars['Int'];
  /** Company password for username authentication at partner service */
  password: Maybe<Scalars['String']>;
  /** Company integration token at partner service */
  token: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  /** Company username for password authentication at partner service */
  user: Maybe<Scalars['String']>;
};

export type Payment = {
  method: PaymentMethod;
  cardOwnerName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardExpirationDate?: Maybe<Scalars['String']>;
  cardCvv?: Maybe<Scalars['String']>;
  cardProcessor?: Maybe<SupportedProcessor>;
};

export enum PaymentMethod {
  CreditCard = 'credit_card',
  BankSlip = 'bank_slip'
}

export enum PeriodEnum {
  Monthly = 'monthly',
  Annually = 'annually'
}

export type PossibleHiringBypass = {
  bypassConfirmed: Scalars['Boolean'];
  candidate: Candidate;
  company: Company;
  companyUser: Maybe<CompanyUser>;
  confirmedAt: Maybe<Scalars['ISO8601DateTime']>;
  eventTime: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  job: Maybe<Job>;
};

export type PredefinedQuestions = {
  answerType: Maybe<Scalars['String']>;
  canRepeat: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  interpolationLabel: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  needsInterpolation: Maybe<Scalars['Boolean']>;
  shortText: Maybe<Scalars['String']>;
};

export type Projects = {
  current: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['ISO8601DateTime']>;
  url: Maybe<Scalars['String']>;
};

export type ProjectsAttributes = {
  /** Institution name */
  name: Scalars['String'];
  /** URL */
  url?: Maybe<Scalars['String']>;
  /** Start date */
  startDate: Scalars['ISO8601DateTime'];
  /** End date */
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Current */
  current: Scalars['Boolean'];
  /** Description */
  description: Scalars['String'];
};

export type Prospect = {
  alreadyAClient: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ProxyCurlActivity = {
  activityStatus: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProxyCurlArticles = {
  author: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  publishedDate: Maybe<ProxyCurlDate>;
  title: Maybe<Scalars['String']>;
};

export type ProxyCurlCertification = {
  authority: Maybe<Scalars['String']>;
  displaySource: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  licenseNumber: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  startsAt: Maybe<ProxyCurlDate>;
  url: Maybe<Scalars['String']>;
};

export type ProxyCurlCourse = {
  name: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
};

export type ProxyCurlDate = {
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type ProxyCurlEducation = {
  degreeName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  fieldOfStudy: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  school: Maybe<Scalars['String']>;
  startsAt: Maybe<ProxyCurlDate>;
};

export type ProxyCurlExperience = {
  company: Maybe<Scalars['String']>;
  companyLinkedinProfileUrl: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  location: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  startsAt: Maybe<ProxyCurlDate>;
  title: Maybe<Scalars['String']>;
};

export type ProxyCurlGroups = {
  name: Maybe<Scalars['String']>;
  profilePicUrl: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ProxyCurlHonorsAwards = {
  description: Maybe<Scalars['String']>;
  issuedOn: Maybe<ProxyCurlDate>;
  issuer: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProxyCurlOrg = {
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  orgName: Maybe<Scalars['String']>;
  startsAt: Maybe<ProxyCurlDate>;
  title: Maybe<Scalars['String']>;
};

export type ProxyCurlPatent = {
  applicationNumber: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  issuedOn: Maybe<ProxyCurlDate>;
  issuer: Maybe<Scalars['String']>;
  patentNumber: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ProxyCurlPeopleAlsoViewed = {
  link: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
};

export type ProxyCurlProject = {
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  startsAt: Maybe<ProxyCurlDate>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ProxyCurlPublications = {
  description: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  publishedOn: Maybe<ProxyCurlDate>;
  publisher: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ProxyCurlTestScores = {
  dateOn: Maybe<ProxyCurlDate>;
  description: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  score: Maybe<Scalars['String']>;
};

export type ProxyCurlVolunteeringExperience = {
  cause: Maybe<Scalars['String']>;
  company: Maybe<Scalars['String']>;
  companyLinkedinProfileUrl: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<ProxyCurlDate>;
  logoUrl: Maybe<Scalars['String']>;
  startsAt: Maybe<ProxyCurlDate>;
  title: Maybe<Scalars['String']>;
};

export type Purchase = {
  prospectId: Scalars['ID'];
  address: Address;
  cpfCnpj: Scalars['String'];
  payment: Payment;
};

export type Query = {
  /** Returns if candidate is registered */
  checkCandidateIsRegistered: CheckCandidateIsRegistered;
  /** Boolean */
  checkCompanyExistsPipedrive: Scalars['Boolean'];
  /** Check if logged in email via google has Geekhunter domains */
  checkGoogleSignin: Ok;
  /** Boolean */
  checkPassword: Scalars['Boolean'];
  /** Boolean */
  checkTwoFactorAuthenticationCode: Scalars['Boolean'];
  /** Int */
  countHubPositionsByCompany: Scalars['Int'];
  /** Get Active and Approved Jobs */
  findActiveAndApprovedJobs: Array<Job>;
  /** Find active jobs that given Company User is responsible for */
  findActiveJobsByCompanyUserWhoIsResponsible: Array<Job>;
  /** Get Jobs By Slug */
  findFirstJobBySlug: Maybe<Job>;
  /** Get Latest Approved Jobs */
  findLatestApprovedJobs: Array<Job>;
  /** Get min and max compensations from active and approved jobs for pj, clt and usd */
  findMaxMinJobsSalary: JobMinMaxSalary;
  /** Find pending bids that given Company User is responsible for */
  findPendingBidsByCompanyUserWhoIsResponsible: Array<Bid>;
  /** Find scheduled interviews that given Company User is responsible for */
  findScheduledInterviewsByCompanyUserWhoIsResponsible: Array<InterviewEvent>;
  /** Get Filtered, Ordered and Paginated Jobs For Showcase */
  findShowcaseJobs: SearchJobReturn;
  /** Get Latest Active Jobs */
  findSimilarJobs: Array<Job>;
  /** Find a team member by his email */
  findTeamMemberByEmail: Maybe<TeamMember>;
  /** Returns active focuses */
  getActiveFocuses: Array<Focus>;
  /** Returns all the company users from the current company user company */
  getAllCompanyUsersFromCurrentCompany: Array<CompanyUser>;
  /** Returns logged candidate profile data */
  getCandidateProfileData: GetCandidateProfileData;
  /** Returns top 5 cities suggestions based on active jobs cities and company cities */
  getCitiesSuggestions: Array<City>;
  /** Returns cities by an array of ids */
  getCityByIds: Array<City>;
  /** Get a Company User by ID */
  getCompanyUserById: CompanyUser;
  /** Returns information about the current account manager (team member) */
  getCurrentAccountManager: Maybe<TeamMemberSerializer>;
  /** Returns all active jobs with additional search candidates params */
  getCurrentActiveJobsSearchCandidates: Array<JobSearchCandidate>;
  /** Returns current candidate */
  getCurrentCandidate: Candidate;
  /** Returns current candidate hiring */
  getCurrentCandidateHiring: Maybe<Hiring>;
  /** Returns current candidate interest in jobs */
  getCurrentCandidateInterestJobsByJobIds: Array<CandidateInterestJobs>;
  /** Returns current candidate pending bids */
  getCurrentCandidatePendingBids: Array<Bid>;
  /** Returns current candidate pending interviews */
  getCurrentCandidatePendingInterviews: Array<InterviewEvent>;
  /** Returns current candidate unread messages */
  getCurrentCandidateUnreadMessages: Array<Message>;
  /** Returns current company user */
  getCurrentCompanyUser: CompanyUser;
  /** Returns information about the current package of the company */
  getCurrentPackageInfo: Maybe<PackageSerializer>;
  /** Boolean */
  getFeatureFlag: Scalars['Boolean'];
  /** Returns focus suggestions for candidate register or not */
  getFocusSuggestions: Maybe<GetFocusSuggestions>;
  /** Returns partner integrations for current company user */
  getGupyIntegration: Maybe<PartnerIntegration>;
  /** Get hub positions by company */
  getHubPositionsByCompany: Array<HubPosition>;
  /** Returns languages by an array of ids */
  getLanguageByIds: Array<Language>;
  /** Returns recommended languages */
  getRecommendedLanguages: Array<Language>;
  /** Returns cities and techs suggestions for candidate register */
  getRegisterSuggestions: GetRegisterSuggestions;
  /** Get latest screening by job id */
  getScreeningByJobId: Maybe<Screening>;
  /** Returns top 5 technologies suggestions based on active job technologies */
  getTechnologiesSuggestions: Array<Technology>;
  /** Returns technologies by an array of ids */
  getTechnologyByIds: Array<Technology>;
  /** Get a list of Hirings */
  hirings: Array<Hiring>;
  /** Get a person linkedin profile */
  importLinkedinProfile: CandidateImportedFromProxyCurl;
  /** Get a list of Company Industries */
  industries: Array<Industry>;
  /** Get a list of careers */
  listCareers: Array<Career>;
  /** Get a list of all countries we support */
  listCountries: Array<Country>;
  /** Get a list of companies considered inactivatable for not having an active company package */
  listOverdueCompanies: Array<Company>;
  /** List all Package Plans */
  listPackagePlans: Array<PackagePlan>;
  /** Returns a list of all possible hiring bypasses */
  listPossibleHiringBypasses: Maybe<Array<PossibleHiringBypass>>;
  /** List predefined questions */
  listPredefinedQuestions: Array<PredefinedQuestions>;
  /** Get a list of states */
  listStates: Array<State>;
  listSubscriptionPlans: Array<SubscriptionPlan>;
  /** Get a embed URL to visualize a metabase dashboard */
  metabaseEmbedDashboardUrl: Scalars['String'];
  /** Fetches an object given its ID. */
  node: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Get a list of Number of Programmers options */
  numberOfProgrammersOptions: Array<KeyValueString>;
  /** Returns candidates profiles according to filters */
  searchCandidates: CompanySearchCandidate;
  /** Search in a list of cities */
  searchCities: Array<City>;
  /** Search in a list of company alias */
  searchCompanyAlias: Array<CompanyAlias>;
  searchDeactivatedCompanies: Array<Company>;
  /** Search in a list of languages */
  searchLanguages: Array<Language>;
  /** Search in a list of techs */
  searchTechs: Array<Technology>;
  /** Returns candidate profile */
  showCandidateProfile: Maybe<ShowCandidateProfile>;
  /** Check if company's domain is valid and if it's already registered */
  validateCompanyRegistration: Maybe<Ok>;
};


export type QueryCheckCandidateIsRegisteredArgs = {
  candidateEmail: Scalars['String'];
};


export type QueryCheckCompanyExistsPipedriveArgs = {
  orgId: Scalars['Int'];
};


export type QueryCheckGoogleSigninArgs = {
  email: Scalars['String'];
};


export type QueryCheckPasswordArgs = {
  password: Scalars['String'];
};


export type QueryCheckTwoFactorAuthenticationCodeArgs = {
  code: Scalars['String'];
};


export type QueryCountHubPositionsByCompanyArgs = {
  keyword?: Maybe<Scalars['String']>;
};


export type QueryFindActiveJobsByCompanyUserWhoIsResponsibleArgs = {
  id: Scalars['ID'];
};


export type QueryFindFirstJobBySlugArgs = {
  slug: Scalars['String'];
  companySlug?: Maybe<Scalars['String']>;
};


export type QueryFindLatestApprovedJobsArgs = {
  quantity?: Maybe<Scalars['Int']>;
};


export type QueryFindPendingBidsByCompanyUserWhoIsResponsibleArgs = {
  id: Scalars['ID'];
};


export type QueryFindScheduledInterviewsByCompanyUserWhoIsResponsibleArgs = {
  id: Scalars['ID'];
};


export type QueryFindShowcaseJobsArgs = {
  showcaseParams: SearchJobFilter;
};


export type QueryFindSimilarJobsArgs = {
  jobId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};


export type QueryFindTeamMemberByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetActiveFocusesArgs = {
  careerSlugs?: Maybe<Array<CareerSlugEnum>>;
  availableForCandidateSearch?: Maybe<Scalars['Boolean']>;
  availableForCandidates?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCityByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetCompanyUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCurrentCandidateInterestJobsByJobIdsArgs = {
  jobIds: Array<Scalars['Int']>;
};


export type QueryGetFeatureFlagArgs = {
  params: GetFeatureFlag;
};


export type QueryGetFocusSuggestionsArgs = {
  careerSlug?: Maybe<Scalars['String']>;
  onlyActiveJobs?: Maybe<Scalars['Boolean']>;
};


export type QueryGetHubPositionsByCompanyArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryGetLanguageByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetRegisterSuggestionsArgs = {
  careerSlug?: Maybe<CareerSlugEnum>;
};


export type QueryGetScreeningByJobIdArgs = {
  jobId: Scalars['Int'];
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTechnologyByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryHiringsArgs = {
  searchBy: Scalars['String'];
};


export type QueryImportLinkedinProfileArgs = {
  linkedinProfileUrl: Scalars['String'];
};


export type QueryMetabaseEmbedDashboardUrlArgs = {
  dashboard: Scalars['Int'];
  role?: Maybe<Scalars['String']>;
  dark?: Maybe<Scalars['Boolean']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySearchCandidatesArgs = {
  filters: SearchCandidateFilter;
  companyCandidateSearchId?: Maybe<Scalars['ID']>;
  onlyTotals?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchCitiesArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchCompanyAliasArgs = {
  text: Scalars['String'];
};


export type QuerySearchDeactivatedCompaniesArgs = {
  searchText: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchLanguagesArgs = {
  text: Scalars['String'];
};


export type QuerySearchTechsArgs = {
  text: Scalars['String'];
  subtypes?: Maybe<Array<TechnologySubtypeEnum>>;
};


export type QueryShowCandidateProfileArgs = {
  candidateId: Scalars['Int'];
};


export type QueryValidateCompanyRegistrationArgs = {
  companyDomain: Scalars['String'];
  companyName: Scalars['String'];
};

export type Question = {
  answerType: Maybe<Scalars['String']>;
  eliminatory: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['ID']>;
  interpolationOptions: Maybe<Array<Scalars['String']>>;
  interpolationText: Maybe<Scalars['String']>;
  mandatory: Maybe<Scalars['Boolean']>;
  maxAnswer: Maybe<Scalars['Int']>;
  minAnswer: Maybe<Scalars['Int']>;
  minAnswerOptions: Maybe<Array<Scalars['String']>>;
  name: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
  predefinedQuestionId: Maybe<Scalars['Int']>;
};

/** Question Input Type */
export type QuestionInput = {
  jobId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  predefinedQuestionId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  interpolationText?: Maybe<Scalars['String']>;
  minAnswer?: Maybe<Scalars['Int']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  eliminatory?: Maybe<Scalars['Boolean']>;
  answerType?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RegisterCandidateFirstStep */
export type RegisterCandidateFirstStepPayload = {
  authToken: Maybe<Scalars['String']>;
  candidate: Maybe<Candidate>;
  errors: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of RegisterCandidateStepAbout */
export type RegisterCandidateStepAboutPayload = {
  candidate: Maybe<Candidate>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RegisterCandidateStepCareer */
export type RegisterCandidateStepCareerPayload = {
  candidate: Maybe<Candidate>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RegisterCandidateStepExperiences */
export type RegisterCandidateStepExperiencesPayload = {
  candidate: Maybe<Candidate>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RegisterCandidateStepImage */
export type RegisterCandidateStepImagePayload = {
  candidate: Maybe<Candidate>;
};

/** Autogenerated return type of RegisterCandidateStepLinkedin */
export type RegisterCandidateStepLinkedinPayload = {
  candidate: Maybe<Candidate>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RegisterCandidateStepProfile */
export type RegisterCandidateStepProfilePayload = {
  /** Candidate */
  candidate: Maybe<Candidate>;
  /** Success */
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RegisterCandidateStepSkills */
export type RegisterCandidateStepSkillsPayload = {
  candidate: Maybe<Candidate>;
  success: Maybe<Scalars['Boolean']>;
};

export enum RegistrationFlowEnum {
  RegistrationFlowV1 = 'registration_flow_v1',
  RegistrationFlowV2 = 'registration_flow_v2',
  LmFlowV1 = 'lm_flow_v1',
  LmFlowV2 = 'lm_flow_v2',
  BackofficeOutboundV1 = 'backoffice_outbound_v1',
  CheckoutV1 = 'checkout_v1',
  CheckoutV2 = 'checkout_v2'
}

/** Autogenerated return type of RejectCandidateInterestJob */
export type RejectCandidateInterestJobPayload = {
  success: Maybe<Scalars['Boolean']>;
};

export type Replacement = {
  createdAt: Scalars['ISO8601DateTime'];
  hiringIdReplaced: Maybe<Scalars['Int']>;
  hiringIdWhoReplaced: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  substitutedAt: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Salary Range Type */
export type SalaryRangeFilter = {
  /** Max Salary */
  max: Scalars['Float'];
  /** Min Salary */
  min: Scalars['Float'];
};

/** Autogenerated return type of Schedule */
export type SchedulePayload = {
  company: Maybe<Company>;
};

export enum ScheduleTypeEnum {
  NotScheduled = 'not_scheduled',
  Scheduled = 'scheduled',
  ScheduleLater = 'schedule_later',
  ContactRequested = 'contact_requested'
}

export type Screening = {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobId: Scalars['Int'];
  lastPublishedAt: Maybe<Scalars['ISO8601DateTime']>;
  published: Maybe<Scalars['Boolean']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  questions: Maybe<Array<Question>>;
  status: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

/** Screening Candidate Input Type */
export type ScreeningApplicantInput = {
  jobId: Scalars['Int'];
  screeningId: Scalars['Int'];
  trackApplicantParams: TrackConversion;
  applicantParams: ApplicantForm;
  answers: Array<AnswerInput>;
};

/** Screening Candidate Input Type */
export type ScreeningCandidateInput = {
  jobId?: Maybe<Scalars['Int']>;
  screeningId?: Maybe<Scalars['Int']>;
  interestType?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerInput>>;
};

export type SearchCandidateFilter = {
  workCities?: Maybe<Array<Scalars['ID']>>;
  livingCities?: Maybe<Array<Scalars['ID']>>;
  languages?: Maybe<Array<LanguageFilter>>;
  focus?: Maybe<Array<FilterWithMinMaxRange>>;
  careerSlugs?: Maybe<Array<CareerSlugEnum>>;
  salaryRange?: Maybe<SalaryRangeFilter>;
  workTypes?: Maybe<Scalars['Int']>;
  workRemote?: Maybe<Array<WorkRemoteEnum>>;
  skills?: Maybe<Array<FilterWithMinMaxRange>>;
  experiencesTime?: Maybe<ExperienceTimeFilter>;
  available?: Maybe<Scalars['Boolean']>;
  situation?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  orderFilter: Scalars['String'];
  isInternationalCompany?: Maybe<Scalars['Boolean']>;
  showEnglishVettedCandidates?: Maybe<Scalars['Boolean']>;
  genders?: Maybe<Array<GenderEnum>>;
  specialNecessities?: Maybe<Array<SpecialNecessityEnum>>;
  /** Job to look for interest candidates */
  jobForInterested?: Maybe<Scalars['ID']>;
  /** Job to look for recommmended candidates */
  jobForRecommended?: Maybe<Scalars['ID']>;
  educationLevel?: Maybe<EducationLevelEnum>;
  searchStatus?: Maybe<SearchStatusFilterEnum>;
  searchPool?: Maybe<SearchPoolFilterEnum>;
};

export type SearchJobFilter = {
  /** Company Location */
  companyLocation: Array<CompanyLocationEnum>;
  /** Focuses to be filtered */
  focuses?: Maybe<Array<Scalars['ID']>>;
  /** Technology ids to filter */
  technologies?: Maybe<Array<Scalars['ID']>>;
  /** Has remote work? */
  remoteWork?: Maybe<Scalars['Boolean']>;
  /** Cities ids to filter */
  cities?: Maybe<Array<Scalars['ID']>>;
  /** List of seniorities to filter */
  seniority?: Maybe<Array<SeniorityEnum>>;
  /** List of work modes to filter */
  workMode?: Maybe<Array<WorkModeEnum>>;
  /** The salary range to filter */
  salaryRange?: Maybe<SalaryRangeFilter>;
  /** The order to filter */
  order: OrderEnum;
  /** The pagination to filter */
  pagination: PaginationFilter;
};

/** Jobs showcase return type */
export type SearchJobReturn = {
  /** Jobs showcase return data */
  data: Array<Job>;
  /** Jobs showcase return total */
  total: Scalars['Int'];
};

export enum SearchPoolFilterEnum {
  All = 'all',
  SelectedByIa = 'selected_by_ia',
  GeekhunterApproved = 'geekhunter_approved'
}

export enum SearchStatusEnum {
  /** Não estou aberto a oportunidades no momento. */
  NotOpenForOpportunities = 'not_open_for_opportunities',
  /** Busco oportunidades para iniciar o trabalho imediatamente. */
  SearchingForAJobRightNow = 'searching_for_a_job_right_now',
  /** Estou empregado, mas busco novos desafios. */
  HiredButSearchingNewThings = 'hired_but_searching_new_things',
  /** Não procuro emprego ativamente, mas estou aberto a avaliar novas oportunidades. */
  NotSearchingButCanHearAboutNewOpportunities = 'not_searching_but_can_hear_about_new_opportunities'
}

export enum SearchStatusFilterEnum {
  All = 'all',
  /** Busco oportunidades para iniciar o trabalho imediatamente. */
  SearchingForAJobRightNow = 'searching_for_a_job_right_now',
  /** Estou empregado, mas busco novos desafios. */
  HiredButSearchingNewThings = 'hired_but_searching_new_things',
  /** Não procuro emprego ativamente, mas estou aberto a avaliar novas oportunidades. */
  NotSearchingButCanHearAboutNewOpportunities = 'not_searching_but_can_hear_about_new_opportunities'
}

export type Segment = {
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

/** Seniority enum */
export enum SeniorityEnum {
  /** Não aplicável */
  NotApplicable = 'NOT_APPLICABLE',
  /** Assistente */
  Assistance = 'ASSISTANCE',
  /** Estágio */
  Internship = 'INTERNSHIP',
  /** Júnior */
  EntryLevel = 'ENTRY_LEVEL',
  /** Pleno */
  MidLevel = 'MID_LEVEL',
  /** Sênior */
  SeniorLevel = 'SENIOR_LEVEL',
  /** Diretor */
  Director = 'DIRECTOR',
  /** Executivo */
  Executive = 'EXECUTIVE'
}

export type ShowCandidateProfile = Node & {
  activeStepCandidates: Array<StepCandidates>;
  anyAcceptedBid: Scalars['Boolean'];
  archivedStepCandidateFeedbacks: Array<ArchivedStepCandidateFeedbacks>;
  archivedStepCandidates: Array<StepCandidates>;
  bidsSent: Maybe<Array<Bid>>;
  candidate: Candidate;
  candidateInterestJobs: Maybe<Array<CandidateInterestJobs>>;
  company: MinimumCompanySerializer;
  companyUser: CompanyUser;
  hasMessage: Maybe<Scalars['Boolean']>;
  hasPendingBid: Maybe<Scalars['Boolean']>;
  hasPendingInterview: Maybe<Scalars['Boolean']>;
  /** ID of the object. */
  id: Scalars['ID'];
  isBidden: Maybe<Scalars['Boolean']>;
  isCandidateHidden: Maybe<Scalars['Boolean']>;
  isFavoriteCandidate: Maybe<Scalars['Boolean']>;
  notes: Maybe<Array<Note>>;
  unreadMessagesCount: Maybe<Scalars['Int']>;
};

export enum SituationEnum {
  Registered = 'registered',
  RegisterComplete = 'register_complete',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Suspended = 'suspended',
  Disabled = 'disabled',
  Hired = 'hired',
  Locked = 'locked',
  IncompatibleProfile = 'incompatible_profile',
  ReevaluationRequired = 'reevaluation_required',
  WaitingApprovalReevaluation = 'waiting_approval_reevaluation',
  EarlyStageCareer = 'early_stage_career',
  ProfileNeedImprovement = 'profile_need_improvement',
  Excluded = 'excluded',
  CandidateAppliedOnly = 'candidate_applied_only'
}

export type Skills = {
  candidate: Maybe<Candidate>;
  evaluationCount: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  level: Maybe<ExperienceLevel>;
  mainTech: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Int']>;
  technology: Maybe<Technology>;
};

export type SkillsAttributes = {
  /** Skill id */
  id?: Maybe<Scalars['Int']>;
  /** Experience level */
  level: Scalars['Int'];
  /** Technology id */
  technologyId: Scalars['Int'];
  /** Main tech */
  mainTech?: Maybe<Scalars['Boolean']>;
  /** Skills order */
  order: Scalars['Int'];
};

export enum SpecialNecessityEnum {
  None = 'none',
  Physical = 'physical',
  Mental = 'mental',
  Hearing = 'hearing',
  Visual = 'visual',
  Intellectual = 'intellectual',
  Other = 'other'
}

export type State = {
  abbreviation: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Step = {
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
};

export type StepCandidates = {
  active: Scalars['Boolean'];
  bid: Maybe<Bid>;
  id: Scalars['ID'];
  step: Step;
};

export type SubmitApplicationForm = {
  /** Job slug */
  slug: Scalars['String'];
  trackApplicantParams: TrackConversion;
  applicantParams: ApplicantForm;
};

/** Autogenerated return type of SubmitApplicationForm */
export type SubmitApplicationFormPayload = {
  confirmationToken: Maybe<Scalars['String']>;
  firstApplication: Maybe<Scalars['Boolean']>;
  ok: Scalars['Boolean'];
};

export type SubscriptionPlan = {
  companyPackage: Maybe<CompanyPackage>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  period: PeriodEnum;
  price: Scalars['Float'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SupportedProcessor {
  Diners = 'diners',
  Amex = 'amex',
  Mastercard = 'mastercard',
  Visa = 'visa'
}

export type TeamMember = {
  aboutUsVisibility: Maybe<Scalars['Boolean']>;
  active: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Maybe<Scalars['String']>;
  isActiveToHandoff: Maybe<Scalars['Boolean']>;
  isNewFarmer: Maybe<Scalars['Boolean']>;
  jobTitle: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
  phone: Maybe<Scalars['String']>;
  pipedriveUserId: Maybe<Scalars['String']>;
  roleType: Maybe<TeamMemberRoleTypeEnum>;
  scheduleUrl: Maybe<Scalars['String']>;
  slackId: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum TeamMemberRoleTypeEnum {
  Farmer = 'farmer',
  Hunter = 'hunter',
  Manager = 'manager',
  FarmerUnit = 'farmer_unit',
  FarmerPackage = 'farmer_package',
  CsOnboarding = 'cs_onboarding',
  UnlimitedPackagesLeader = 'unlimited_packages_leader',
  PlansLeader = 'plans_leader',
  Other = 'other'
}

export type TeamMemberSerializer = {
  email: Scalars['String'];
  image: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  scheduleUrl: Maybe<Scalars['String']>;
};

export type TechEvaluation = {
  evaluatedAt: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  pdfUrl: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  skills: Maybe<Array<Scalars['String']>>;
};

export type Technology = {
  availableAsRequired: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  subtype: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  urlPath: Maybe<Scalars['String']>;
};

export enum TechnologySubtypeEnum {
  Language = 'language',
  Framework = 'framework',
  Knowledge = 'knowledge'
}

export enum TierEnum {
  Hub = 'hub',
  Lite = 'lite',
  Standard = 'standard',
  Recruiter = 'recruiter',
  LiteV2 = 'lite_v2',
  StandardV2 = 'standard_v2',
  Premium = 'premium',
  HubPremium = 'hub_premium',
  Old = 'old'
}

export type TrackConversion = {
  /** UTM Source */
  utmSource: Scalars['String'];
  /** UTM Medium */
  utmMedium: Scalars['String'];
  /** UTM Campaign */
  utmCampaign: Scalars['String'];
  /** UTM Term */
  utmTerm?: Maybe<Scalars['String']>;
  /** Device type */
  deviceType?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCandidateMainInfo */
export type UpdateCandidateMainInfoPayload = {
  /** Candidate city */
  cityId: Scalars['ID'];
  /** Candidate name */
  cltSalary: Maybe<Scalars['Float']>;
  /** Candidate github url */
  githubUrl: Maybe<Scalars['String']>;
  /** Candidate profile avatar */
  image: Maybe<Scalars['String']>;
  /** Candidate linkedin url */
  linkedInUrl: Scalars['String'];
  /** Candidate name */
  name: Scalars['String'];
  /** Candidate name */
  pjSalary: Maybe<Scalars['Float']>;
  /** Candidate portfolio extra url */
  portfolioExtraUrl: Maybe<Scalars['String']>;
  /** Candidate portfolio url */
  portfolioUrl: Maybe<Scalars['String']>;
  /** Candidate position title */
  positionTitle: Scalars['String'];
  /** Candidate city */
  searchStatus: SearchStatusEnum;
  /** Candidate name */
  usdSalary: Maybe<Scalars['Float']>;
};

/** Partner Integration updating arguments */
export type UpdatePartnerIntegration = {
  /** Partner token */
  token: Scalars['String'];
  /** Partner domain */
  domain: Scalars['String'];
};

/** Work mode enum */
export enum WorkModeEnum {
  /** CLT */
  Clt = 'CLT',
  /** PJ ou Cooperativa */
  Pj = 'PJ'
}

export enum WorkRemoteEnum {
  /** Não aceita trabalho remoto. */
  RemoteNo = 'remote_no',
  /** Aceita trabalho remoto. */
  RemoteYes = 'remote_yes',
  /** Aceita somente trabalho remoto. */
  RemoteOnly = 'remote_only'
}

export type RegisterAmplitudeHitMutationVariables = Exact<{
  amplitudeHitType: AmplitudeHitOptionsEnum;
  userEmail?: Maybe<Scalars['String']>;
  userType: AmplitudeUserTypeEnum;
  anonymous?: Maybe<Scalars['Boolean']>;
}>;


export type RegisterAmplitudeHitMutation = { registerAmplitudeHit: Pick<Ok, 'ok'> };

export type RegisterAmplitudeHitAuthenticatedMutationVariables = Exact<{
  amplitudeHitType: AmplitudeHitOptionsEnum;
}>;


export type RegisterAmplitudeHitAuthenticatedMutation = { registerAmplitudeHitAuthenticated: Pick<Ok, 'ok'> };

export type CandidateRegisterStepAboutMutationVariables = Exact<{
  positionTitle: Scalars['String'];
  summary: Scalars['String'];
  excludedCompaniesAttributes?: Maybe<Array<CandidateExcludedCompaniesAttributes> | CandidateExcludedCompaniesAttributes>;
}>;


export type CandidateRegisterStepAboutMutation = { registerCandidateStepAbout: (
    Pick<RegisterCandidateStepAboutPayload, 'success'>
    & { candidate: Maybe<(
      Pick<Candidate, 'id' | 'positionTitle' | 'summary'>
      & { candidateExcludedCompanies: Maybe<Array<(
        Pick<CandidateExcludedCompanies, 'id'>
        & { companyAlias: Maybe<Pick<CompanyAlias, 'id' | 'name'>> }
      )>> }
    )> }
  ) };

export type CandidateRegisterStepCareerMutationVariables = Exact<{
  workRemote: Scalars['String'];
  pjSalary?: Maybe<Scalars['Float']>;
  cltSalary?: Maybe<Scalars['Float']>;
  usdSalary?: Maybe<Scalars['Float']>;
  candidateCitiesAttributes?: Maybe<Array<CandidateCitiesAttributes> | CandidateCitiesAttributes>;
}>;


export type CandidateRegisterStepCareerMutation = { registerCandidateStepCareer: (
    Pick<RegisterCandidateStepCareerPayload, 'success'>
    & { candidate: Maybe<(
      Pick<Candidate, 'id' | 'workRemote' | 'pjSalary' | 'cltSalary' | 'usdSalary'>
      & { candidateCities: Maybe<Array<(
        Pick<CandidateCities, 'order'>
        & { city: Pick<City, 'id' | 'name'> }
      )>> }
    )> }
  ) };

export type CandidateRegisterStepExperiencesMutationVariables = Exact<{
  noExperiences: Scalars['Boolean'];
  experiencesAttributes?: Maybe<Array<ExperiencesAttributes> | ExperiencesAttributes>;
  educationsAttributes: Array<EducationsAttributes> | EducationsAttributes;
  certificationsAttributes?: Maybe<Array<CertificationsAttributes> | CertificationsAttributes>;
  projectsAttributes?: Maybe<Array<ProjectsAttributes> | ProjectsAttributes>;
}>;


export type CandidateRegisterStepExperiencesMutation = { registerCandidateStepExperiences: (
    Pick<RegisterCandidateStepExperiencesPayload, 'success'>
    & { candidate: Maybe<(
      Pick<Candidate, 'id' | 'noExperiences'>
      & { experiences: Maybe<Array<(
        Pick<Experiences, 'company' | 'currentJob' | 'description' | 'endDate' | 'id' | 'jobTitle' | 'startDate'>
        & { skills: Maybe<Array<(
          Pick<Skills, 'id' | 'mainTech' | 'order'>
          & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
        )>> }
      )>>, educations: Maybe<Array<(
        Pick<Educations, 'course' | 'educationStatus' | 'endYear' | 'id' | 'institution' | 'startYear'>
        & { title: Maybe<Pick<EnumerizeValue, 'value'>> }
      )>>, certifications: Maybe<Array<Pick<Certifications, 'current' | 'endDate' | 'id' | 'institution' | 'startDate' | 'title' | 'url'>>>, projects: Maybe<Array<Pick<Projects, 'current' | 'description' | 'endDate' | 'id' | 'name' | 'startDate' | 'url'>>>, candidateExcludedCompanies: Maybe<Array<(
        Pick<CandidateExcludedCompanies, 'id'>
        & { companyAlias: Maybe<Pick<CompanyAlias, 'id' | 'name'>> }
      )>> }
    )> }
  ) };

export type CandidateRegisterStepImageMutationVariables = Exact<{
  params: CandidateRegisterStepImage;
}>;


export type CandidateRegisterStepImageMutation = { registerCandidateStepImage: { candidate: Maybe<Pick<Candidate, 'id' | 'image'>> } };

export type CandidateRegisterStepLinkedinMutationVariables = Exact<{
  params: CandidateRegisterStepLinkedin;
}>;


export type CandidateRegisterStepLinkedinMutation = { registerCandidateStepLinkedin: { candidate: Maybe<Pick<Candidate, 'id' | 'searchStatus' | 'linkedInUrl' | 'hiringOption'>> } };

export type CandidateRegisterStepProfileMutationVariables = Exact<{
  params: CandidateRegisterStepProfile;
}>;


export type CandidateRegisterStepProfileMutation = { registerCandidateStepProfile: (
    Pick<RegisterCandidateStepProfilePayload, 'success'>
    & { candidate: Maybe<(
      Pick<Candidate, 'id' | 'name' | 'email' | 'phone' | 'gender' | 'specialNecessity' | 'stackoverflowUrl' | 'githubUrl' | 'allowWhatsappNotification' | 'portfolioUrl' | 'portfolioExtraUrl'>
      & { city: Maybe<Pick<City, 'id' | 'name'>> }
    )> }
  ) };

export type CandidateRegisterStepSkillsMutationVariables = Exact<{
  params: CandidateRegisterStepSkills;
}>;


export type CandidateRegisterStepSkillsMutation = { registerCandidateStepSkills: (
    Pick<RegisterCandidateStepSkillsPayload, 'success'>
    & { candidate: Maybe<(
      Pick<Candidate, 'id' | 'workedUsingOnlyEnglish'>
      & { candidateFocus: Maybe<Array<(
        Pick<CandidateFocus, 'order'>
        & { experienceLevel: Maybe<Pick<ExperienceLevel, 'value'>>, focus: Maybe<(
          Pick<Focus, 'id' | 'description'>
          & { career: Pick<Career, 'name' | 'id' | 'slug' | 'worksWithSkills'> }
        )> }
      )>>, skills: Array<(
        Pick<Skills, 'id' | 'mainTech' | 'order'>
        & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
      )>, experiences: Maybe<Array<(
        Pick<Experiences, 'company' | 'currentJob' | 'description' | 'endDate' | 'id' | 'jobTitle' | 'startDate'>
        & { skills: Maybe<Array<(
          Pick<Skills, 'id' | 'mainTech' | 'order'>
          & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
        )>> }
      )>>, candidateLanguages: Maybe<Array<(
        Pick<CandidateLanguages, 'assessmentLevel' | 'updatedAt'>
        & { language: Maybe<Pick<Language, 'id' | 'name'>>, readingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, writingLevel: Maybe<Pick<ExperienceLevel, 'value'>> }
      )>> }
    )> }
  ) };

export type SetCurrentCandidateVideoAskCompletedMutationVariables = Exact<{
  videoAskCompleted: Scalars['Boolean'];
  videoAskResponseUrl?: Maybe<Scalars['String']>;
}>;


export type SetCurrentCandidateVideoAskCompletedMutation = { setCurrentCandidateVideoAskCompleted: Pick<Candidate, 'id'> };

export type UpdateCandidateMutationVariables = Exact<{
  payload: CandidateInput;
}>;


export type UpdateCandidateMutation = { updateCandidate: (
    Pick<Candidate, 'cltSalary' | 'pjSalary' | 'usdSalary' | 'hiringOption' | 'searchStatus' | 'available'>
    & { skills: Array<(
      Pick<Skills, 'id' | 'mainTech' | 'order'>
      & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
    )>, candidateLanguages: Maybe<Array<(
      Pick<CandidateLanguages, 'assessmentLevel' | 'updatedAt'>
      & { language: Maybe<Pick<Language, 'id' | 'name'>>, readingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, writingLevel: Maybe<Pick<ExperienceLevel, 'value'>> }
    )>> }
  ) };

export type CreateCurrentCandidateActivityLogMutationVariables = Exact<{
  eventType: ActivityLogEventTypeEnum;
}>;


export type CreateCurrentCandidateActivityLogMutation = { createCurrentCandidateActivityLog: Pick<CandidateActivityLog, 'id'> };

export type CreateCurrentCandidateAvailableFeedbackMutationVariables = Exact<{
  available: Scalars['Boolean'];
  feedbackType: AvailableFeedbackEnum;
  message?: Maybe<Scalars['String']>;
}>;


export type CreateCurrentCandidateAvailableFeedbackMutation = { createCurrentCandidateAvailableFeedback: Pick<CandidateAvailableFeedback, 'id'> };

export type RegisterCandidateFirstStepMutationVariables = Exact<{
  termsOfService: Scalars['Boolean'];
  email: Scalars['String'];
  emailNotification: Scalars['Boolean'];
  name: Scalars['String'];
  password: Scalars['String'];
  firstReferer?: Maybe<Scalars['String']>;
  fromReferral?: Maybe<Scalars['String']>;
  utmSource: Scalars['String'];
  utmMedium: Scalars['String'];
  utmCampaign: Scalars['String'];
  utmTerm?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  lastRenderedSlug?: Maybe<Scalars['String']>;
}>;


export type RegisterCandidateFirstStepMutation = { registerCandidateFirstStep: (
    Pick<RegisterCandidateFirstStepPayload, 'authToken' | 'errors'>
    & { candidate: Maybe<Pick<Candidate, 'id' | 'name' | 'email'>> }
  ) };

export type SetCurrentCandidateDashboardFirstAccessMutationVariables = Exact<{
  isDashboardFirstAccess: Scalars['Boolean'];
}>;


export type SetCurrentCandidateDashboardFirstAccessMutation = { setCurrentCandidateDashboardFirstAccess: Pick<Candidate, 'id'> };

export type SetCurrentCandidateStatusMutationVariables = Exact<{
  searchStatus: SearchStatusEnum;
  available: Scalars['Boolean'];
}>;


export type SetCurrentCandidateStatusMutation = { setCurrentCandidateStatus: Pick<Candidate, 'id' | 'available' | 'searchStatus'> };

export type SetCurrentCandidateWhatsappNotificationMutationVariables = Exact<{
  allowWhatsappNotification: Scalars['Boolean'];
}>;


export type SetCurrentCandidateWhatsappNotificationMutation = { setCurrentCandidateWhatsappNotification: Pick<Candidate, 'id'> };

export type CreateProspectMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  countryId: Scalars['ID'];
  phone: Scalars['String'];
}>;


export type CreateProspectMutation = { createProspect: Pick<Prospect, 'alreadyAClient' | 'id'> };

export type PurchasePlanMutationVariables = Exact<{
  params: Purchase;
}>;


export type PurchasePlanMutation = { purchasePlan: Pick<Ok, 'ok'> };

export type AcceptCompanyUserTermsOfServiceMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptCompanyUserTermsOfServiceMutation = { acceptCompanyUserTermsOfService: Pick<AcceptTermsOfServicePayload, 'redirectUrl'> };

export type CompanyCancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CompanyCancelSubscriptionMutation = { companyCancelSubscription: Pick<Ok, 'ok'> };

export type CompanyRequestUpgradeMutationVariables = Exact<{
  searchPool?: Maybe<SearchPoolFilterEnum>;
  planUpgradeTrigger?: Maybe<CompanyPlanUpgradeTriggerEnum>;
  targetTier?: Maybe<TierEnum>;
}>;


export type CompanyRequestUpgradeMutation = { companyRequestUpgrade: Pick<Ok, 'ok'> };

export type CreateBidMutationVariables = Exact<{
  bidInputParams: BidInput;
}>;


export type CreateBidMutation = { createBid: Pick<Bid, 'id'> };

export type CreateNoteMutationVariables = Exact<{
  noteInputParams: NoteInput;
}>;


export type CreateNoteMutation = { createNote: Pick<Note, 'id' | 'note' | 'actorName' | 'excluded'> };

export type CreateScreeningCandidateMutationVariables = Exact<{
  inputScreeningCandidate: ScreeningCandidateInput;
}>;


export type CreateScreeningCandidateMutation = { createScreeningCandidate: Pick<Ok, 'ok'> };

export type DisableTwoFactorAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableTwoFactorAuthenticationMutation = { disableTwoFactorAuthentication: Pick<Ok, 'ok'> };

export type EnableTwoFactorAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type EnableTwoFactorAuthenticationMutation = { enableTwoFactorAuthentication: Pick<Ok, 'ok'> };

export type GenerateTwoFactorAuthenticationSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateTwoFactorAuthenticationSecretMutation = { generateTwoFactorAuthenticationSecret: Pick<Ok, 'ok'> };

export type RejectCandidateInterestJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RejectCandidateInterestJobMutation = { rejectCandidateInterestJob: Pick<RejectCandidateInterestJobPayload, 'success'> };

export type ScheduleDemonstrationMutationVariables = Exact<{
  id: Scalars['Int'];
  notifySales: Scalars['Boolean'];
  preferredContactForm?: Maybe<Scalars['String']>;
  type?: Maybe<ScheduleTypeEnum>;
}>;


export type ScheduleDemonstrationMutation = { scheduleDemonstration: { company: Maybe<Pick<Company, 'id'>> } };

export type SendUserEmailConfirmationInstructionsMutationVariables = Exact<{ [key: string]: never; }>;


export type SendUserEmailConfirmationInstructionsMutation = { sendCompanyUserEmailConfirmationInstructions: Pick<Ok, 'ok'> };

export type ToggleFavoriteCandidateMutationVariables = Exact<{
  candidateId: Scalars['Int'];
  companyUserId: Scalars['Int'];
}>;


export type ToggleFavoriteCandidateMutation = { toggleFavoriteCandidate: Pick<CompanyToggleFavoriteCandidates, 'isFavorite'> };

export type ToggleHiddenCandidateMutationVariables = Exact<{
  candidateId: Scalars['Int'];
  companyUserId: Scalars['Int'];
}>;


export type ToggleHiddenCandidateMutation = { toggleHiddenCandidate: Pick<CompanyToggleHiddenCandidate, 'isHidden'> };

export type UpdateCompanyCandidateSearchResultClickedMutationVariables = Exact<{
  searchId: Scalars['ID'];
  candidateId: Scalars['ID'];
}>;


export type UpdateCompanyCandidateSearchResultClickedMutation = Pick<Mutation, 'updateCompanyCandidateSearchResultClicked'>;

export type UpdateNoteMutationVariables = Exact<{
  noteUpdateParams: NoteUpdate;
}>;


export type UpdateNoteMutation = { updateNote: Pick<Note, 'note' | 'excluded'> };

export type UpdatePartnerIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
  params: UpdatePartnerIntegration;
}>;


export type UpdatePartnerIntegrationMutation = { updatePartnerIntegration: Pick<PartnerIntegration, 'id' | 'domain' | 'token'> };

export type CreateCompanyUserMutationVariables = Exact<{
  params: CreateOrUpdateCompanyUserInput;
}>;


export type CreateCompanyUserMutation = { createCompanyUser: Pick<Ok, 'ok'> };

export type CreateCompanyUserActivityLogMutationVariables = Exact<{
  eventType: CompanyUserActivityLogEventTypeEnum;
}>;


export type CreateCompanyUserActivityLogMutation = { createCompanyUserActivityLog: Pick<CompanyUserActivityLog, 'id'> };

export type SwitchCompanyUserLanguageMutationVariables = Exact<{
  language: Scalars['String'];
}>;


export type SwitchCompanyUserLanguageMutation = { switchCompanyUserLanguage: Pick<CompanyUserSwitchLanguage, 'language'> };

export type ToggleActiveMutationVariables = Exact<{
  params: CompanyUserToggleActiveInput;
}>;


export type ToggleActiveMutation = { toggleActive: Pick<Ok, 'ok'> };

export type UpdateCompanyUserMutationVariables = Exact<{
  id: Scalars['ID'];
  params: CreateOrUpdateCompanyUserInput;
}>;


export type UpdateCompanyUserMutation = { updateCompanyUser: Pick<Ok, 'ok'> };

export type CreateCurrentCandidateInterestJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
  interestType: Scalars['String'];
}>;


export type CreateCurrentCandidateInterestJobMutation = { createCurrentCandidateInterestJob: { candidateInterestJob: Maybe<(
      Pick<CandidateInterestJobs, 'id' | 'interestType' | 'candidateId'>
      & { job: Pick<Job, 'id'> }
    )> } };

export type CheckCandidateIsRegisteredQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckCandidateIsRegisteredQuery = { checkCandidateIsRegistered: Pick<CheckCandidateIsRegistered, 'isRegistered'> };

export type GetCandidateProfileDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCandidateProfileDataQuery = { getCandidateProfileData: { candidate: (
      Pick<Candidate, 'id' | 'image' | 'linkedInUrl' | 'searchStatus' | 'workRemote' | 'workedUsingOnlyEnglish' | 'isDashboardFirstAccess' | 'cltSalary' | 'pjSalary' | 'usdSalary' | 'noExperiences' | 'positionTitle' | 'summary' | 'name' | 'email' | 'phone' | 'gender' | 'specialNecessity' | 'stackoverflowUrl' | 'githubUrl' | 'allowWhatsappNotification' | 'hiringOption' | 'available' | 'situation' | 'portfolioUrl' | 'portfolioExtraUrl' | 'videoAskCompleted' | 'eligibleToInternationalJobs' | 'techCareer'>
      & { candidateCities: Maybe<Array<(
        Pick<CandidateCities, 'order'>
        & { city: Pick<City, 'id' | 'name'> }
      )>>, candidateFocus: Maybe<Array<(
        Pick<CandidateFocus, 'order'>
        & { experienceLevel: Maybe<Pick<ExperienceLevel, 'value'>>, focus: Maybe<(
          Pick<Focus, 'id' | 'description'>
          & { career: Pick<Career, 'name' | 'id' | 'slug' | 'worksWithSkills'> }
        )> }
      )>>, skills: Array<(
        Pick<Skills, 'id' | 'mainTech' | 'order'>
        & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
      )>, candidateLanguages: Maybe<Array<(
        Pick<CandidateLanguages, 'assessmentLevel' | 'updatedAt'>
        & { language: Maybe<Pick<Language, 'id' | 'name'>>, readingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, writingLevel: Maybe<Pick<ExperienceLevel, 'value'>> }
      )>>, experiences: Maybe<Array<(
        Pick<Experiences, 'company' | 'currentJob' | 'description' | 'endDate' | 'id' | 'jobTitle' | 'startDate'>
        & { skills: Maybe<Array<(
          Pick<Skills, 'id' | 'mainTech' | 'order'>
          & { level: Maybe<Pick<ExperienceLevel, 'value'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
        )>> }
      )>>, educations: Maybe<Array<(
        Pick<Educations, 'course' | 'educationStatus' | 'endYear' | 'id' | 'institution' | 'startYear'>
        & { title: Maybe<Pick<EnumerizeValue, 'value'>> }
      )>>, certifications: Maybe<Array<Pick<Certifications, 'current' | 'endDate' | 'id' | 'institution' | 'startDate' | 'title' | 'url'>>>, projects: Maybe<Array<Pick<Projects, 'current' | 'description' | 'endDate' | 'id' | 'name' | 'startDate' | 'url'>>>, candidateExcludedCompanies: Maybe<Array<(
        Pick<CandidateExcludedCompanies, 'id'>
        & { companyAlias: Maybe<Pick<CompanyAlias, 'id' | 'name'>> }
      )>>, city: Maybe<Pick<City, 'id' | 'name'>> }
    ) } };

export type GetCurrentCandidateAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateAvailableQuery = { getCurrentCandidate: Pick<Candidate, 'id' | 'available' | 'searchStatus'> };

export type GetCurrentCandidateBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateBasicInfoQuery = { getCurrentCandidate: Pick<Candidate, 'id' | 'name' | 'email' | 'phone' | 'hiringOption' | 'isDashboardFirstAccess'> };

export type GetCurrentCandidateHiringQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateHiringQuery = { getCurrentCandidateHiring: Maybe<(
    Pick<Hiring, 'id' | 'candidateSalary' | 'createdAt'>
    & { company: Maybe<Pick<Company, 'id' | 'name'>>, job: Maybe<Pick<Job, 'id' | 'title' | 'isInternational'>> }
  )> };

export type GetCurrentCandidateImageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateImageQuery = { getCurrentCandidate: Pick<Candidate, 'id' | 'image'> };

export type GetCurrentCandidateInterestJobsByJobIdsQueryVariables = Exact<{
  jobs: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetCurrentCandidateInterestJobsByJobIdsQuery = { getCurrentCandidateInterestJobsByJobIds: Array<(
    Pick<CandidateInterestJobs, 'id'>
    & { job: Pick<Job, 'id'> }
  )> };

export type GetCurrentCandidateMainInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateMainInfoQuery = { getCurrentCandidate: (
    Pick<Candidate, 'id' | 'pjSalary' | 'cltSalary' | 'usdSalary' | 'workRemote' | 'image' | 'hiringOption' | 'situation' | 'videoAskCompleted' | 'eligibleToInternationalJobs'>
    & { candidateCities: Maybe<Array<(
      Pick<CandidateCities, 'order'>
      & { city: Pick<City, 'id' | 'name'> }
    )>>, skills: Array<(
      Pick<Skills, 'id' | 'mainTech'>
      & { technology: Maybe<Pick<Technology, 'id' | 'name'>> }
    )>, candidateLanguages: Maybe<Array<{ readingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, writingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, language: Maybe<Pick<Language, 'id' | 'name'>> }>>, candidateFocus: Maybe<Array<{ focus: Maybe<{ career: Pick<Career, 'name' | 'id' | 'slug' | 'worksWithSkills'> }> }>> }
  ) };

export type GetCurrentCandidateMd5EmailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateMd5EmailQuery = { getCurrentCandidate: Pick<Candidate, 'id' | 'md5Email'> };

export type GetCurrentCandidatePendingBidsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidatePendingBidsQuery = { getCurrentCandidatePendingBids: Array<(
    Pick<Bid, 'id' | 'createdAt'>
    & { company: Maybe<Pick<Company, 'id' | 'name'>> }
  )> };

export type GetCurrentCandidatePendingInterviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidatePendingInterviewsQuery = { getCurrentCandidatePendingInterviews: Array<(
    Pick<InterviewEvent, 'id' | 'start' | 'end' | 'eventType'>
    & { company: Maybe<Pick<Company, 'id' | 'name'>>, companyUser: Maybe<Pick<CompanyUser, 'id' | 'name'>>, bid: Maybe<Pick<Bid, 'id'>> }
  )> };

export type GetCurrentCandidateUnreadMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateUnreadMessagesQuery = { getCurrentCandidateUnreadMessages: Array<(
    Pick<Message, 'id'>
    & { company: Pick<Company, 'id' | 'name'> }
  )> };

export type GetCurrentCandidateWhatsAppNotificationStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCandidateWhatsAppNotificationStatusQuery = { getCurrentCandidate: Pick<Candidate, 'id' | 'allowWhatsappNotification'> };

export type GetFocusSuggestionsQueryVariables = Exact<{
  careerSlug?: Maybe<Scalars['String']>;
  onlyActiveJobs?: Maybe<Scalars['Boolean']>;
}>;


export type GetFocusSuggestionsQuery = { getFocusSuggestions: Maybe<{ focus: Array<(
      Pick<Focus, 'id' | 'description'>
      & { career: Pick<Career, 'worksWithSkills'> }
    )> }> };

export type GetRegisterSuggestionsQueryVariables = Exact<{
  careerSlug?: Maybe<CareerSlugEnum>;
}>;


export type GetRegisterSuggestionsQuery = { getRegisterSuggestions: { cities: Array<Pick<City, 'name' | 'id'>>, techs: Array<Pick<Technology, 'name' | 'id'>> } };

export type ImportLinkedinProfileQueryVariables = Exact<{
  linkedinProfileUrl: Scalars['String'];
}>;


export type ImportLinkedinProfileQuery = { importLinkedinProfile: (
    Pick<CandidateImportedFromProxyCurl, 'fullName' | 'occupation' | 'headline' | 'summary'>
    & { experiences: Maybe<Array<(
      Pick<ProxyCurlExperience, 'company' | 'title' | 'description' | 'location'>
      & { startsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>>, endsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>> }
    )>>, education: Maybe<Array<(
      Pick<ProxyCurlEducation, 'fieldOfStudy' | 'degreeName' | 'school' | 'description'>
      & { startsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>>, endsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>> }
    )>>, accomplishmentProjects: Maybe<Array<(
      Pick<ProxyCurlProject, 'title' | 'description' | 'url'>
      & { startsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>>, endsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>> }
    )>>, certifications: Maybe<Array<(
      Pick<ProxyCurlCertification, 'url' | 'name' | 'displaySource' | 'authority'>
      & { startsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>>, endsAt: Maybe<Pick<ProxyCurlDate, 'month' | 'year'>> }
    )>> }
  ) };

export type ListCareersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCareersQuery = { listCareers: Array<Pick<Career, 'name' | 'id' | 'slug' | 'worksWithSkills'>> };

export type ShowCandidateProfileQueryVariables = Exact<{
  candidateId: Scalars['Int'];
}>;


export type ShowCandidateProfileQuery = { showCandidateProfile: Maybe<(
    Pick<ShowCandidateProfile, 'isFavoriteCandidate' | 'isCandidateHidden' | 'hasMessage' | 'isBidden'>
    & { candidate: (
      Pick<Candidate, 'id' | 'name' | 'image' | 'positionTitle' | 'cltSalary' | 'pjSalary' | 'usdSalary' | 'lastApprovedTime' | 'approvedInternational' | 'searchStatus' | 'summary' | 'summaryTranslation' | 'workRemote' | 'localWorkPreference' | 'specialNecessity' | 'humanizedSpecialNecessity' | 'workedUsingOnlyEnglish' | 'geekhunterEvaluation' | 'geekhunterEvaluationTranslation' | 'geekhunterEvaluatedAt' | 'searchPools' | 'stackoverflowUrl' | 'githubUrl' | 'linkedInUrl' | 'portfolioUrl' | 'portfolioExtraUrl' | 'available' | 'situation' | 'phone' | 'email' | 'allowWhatsappNotification' | 'hiringOption' | 'profileLanguage'>
      & { city: Maybe<Pick<City, 'id' | 'name'>>, techEvaluations: Array<Pick<TechEvaluation, 'evaluatedAt' | 'score' | 'skills' | 'kind' | 'pdfUrl'>>, candidateLanguages: Maybe<Array<(
        Pick<CandidateLanguages, 'assessmentLevel' | 'id' | 'updatedAt'>
        & { language: Maybe<Pick<Language, 'id' | 'name'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>> }
      )>>, experiences: Maybe<Array<(
        Pick<Experiences, 'id' | 'company' | 'startDate' | 'endDate' | 'currentJob' | 'jobTitle' | 'durationInMonths' | 'description' | 'descriptionTranslation'>
        & { skills: Maybe<Array<{ technology: Maybe<Pick<Technology, 'name'>> }>> }
      )>>, skills: Array<(
        Pick<Skills, 'id' | 'mainTech' | 'evaluationCount'>
        & { level: Maybe<Pick<ExperienceLevel, 'value' | 'text'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
      )>, educations: Maybe<Array<Pick<Educations, 'id' | 'institution' | 'educationStatus' | 'startYear' | 'endYear' | 'durationInYears' | 'course'>>>, certifications: Maybe<Array<Pick<Certifications, 'id' | 'institution' | 'startDate' | 'endDate' | 'url' | 'current' | 'title'>>>, projects: Maybe<Array<Pick<Projects, 'id' | 'name' | 'description' | 'url' | 'startDate' | 'endDate' | 'current'>>>, candidateFocus: Maybe<Array<(
        Pick<CandidateFocus, 'id' | 'order'>
        & { focus: Maybe<Pick<Focus, 'id' | 'description'>>, experienceLevel: Maybe<Pick<ExperienceLevel, 'value' | 'text'>> }
      )>>, candidateCities: Maybe<Array<(
        Pick<CandidateCities, 'id' | 'order'>
        & { city: Pick<City, 'id' | 'name'> }
      )>> }
    ), bidsSent: Maybe<Array<(
      Pick<Bid, 'cltMinSalary' | 'cltMaxSalary' | 'pjMinSalary' | 'pjMaxSalary' | 'usdAnnualMinSalary' | 'usdAnnualMaxSalary' | 'status' | 'createdAt' | 'id' | 'acceptedAt'>
      & { job: Pick<Job, 'title' | 'id'>, companyUser: Maybe<Pick<CompanyUser, 'name'>>, company: Maybe<Pick<Company, 'name'>> }
    )>>, notes: Maybe<Array<Pick<Note, 'id' | 'note' | 'actorName' | 'excluded'>>>, candidateInterestJobs: Maybe<Array<(
      Pick<CandidateInterestJobs, 'id' | 'candidateId' | 'status' | 'createdAt' | 'interestType'>
      & { job: Pick<Job, 'id' | 'title'> }
    )>>, activeStepCandidates: Array<(
      Pick<StepCandidates, 'id'>
      & { step: Pick<Step, 'title'>, bid: Maybe<{ job: Pick<Job, 'title'> }> }
    )>, archivedStepCandidates: Array<(
      Pick<StepCandidates, 'id'>
      & { step: Pick<Step, 'title'>, bid: Maybe<{ job: Pick<Job, 'title'> }> }
    )>, archivedStepCandidateFeedbacks: Array<{ feedbackType: Maybe<Pick<Feedback, 'value' | 'label'>>, archivedStepCandidate: Maybe<{ stepCandidate: Pick<StepCandidates, 'id'> }> }> }
  )> };

export type CheckPasswordQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type CheckPasswordQuery = Pick<Query, 'checkPassword'>;

export type CheckTwoFactorAuthenticationCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CheckTwoFactorAuthenticationCodeQuery = Pick<Query, 'checkTwoFactorAuthenticationCode'>;

export type GetAccountManagerInfoForHeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountManagerInfoForHeaderQuery = { getCurrentAccountManager: Maybe<Pick<TeamMemberSerializer, 'name' | 'email' | 'image' | 'phone' | 'scheduleUrl'>> };

export type GetActiveFocusesQueryVariables = Exact<{
  careerSlugs?: Maybe<Array<CareerSlugEnum> | CareerSlugEnum>;
  availableForCandidateSearch?: Maybe<Scalars['Boolean']>;
  availableForCandidates?: Maybe<Scalars['Boolean']>;
}>;


export type GetActiveFocusesQuery = { getActiveFocuses: Array<(
    Pick<Focus, 'id' | 'description'>
    & { career: Pick<Career, 'id' | 'name' | 'slug' | 'worksWithSkills'> }
  )> };

export type GetCandidateFilterOptionsByIdQueryVariables = Exact<{
  cityIds: Array<Scalars['ID']> | Scalars['ID'];
  techIds: Array<Scalars['ID']> | Scalars['ID'];
  shouldGetCities: Scalars['Boolean'];
  shouldGetTechs: Scalars['Boolean'];
}>;


export type GetCandidateFilterOptionsByIdQuery = { getCityByIds?: Maybe<Array<Pick<City, 'id' | 'name'>>>, getTechnologyByIds?: Maybe<Array<Pick<Technology, 'id' | 'name'>>> };

export type GetCandidateFilterSelectOptionsQueryVariables = Exact<{
  careerSlugs?: Maybe<Array<CareerSlugEnum> | CareerSlugEnum>;
  availableForCandidateSearch?: Maybe<Scalars['Boolean']>;
}>;


export type GetCandidateFilterSelectOptionsQuery = { getTechnologiesSuggestions: Array<Pick<Technology, 'id' | 'name'>>, getCitiesSuggestions: Array<Pick<City, 'id' | 'name'>>, getActiveFocuses: Array<(
    Pick<Focus, 'id' | 'description'>
    & { career: Pick<Career, 'id' | 'name' | 'slug' | 'worksWithSkills'> }
  )> };

export type GetCitiesSuggestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCitiesSuggestionsQuery = { getCitiesSuggestions: Array<Pick<City, 'id' | 'name'>> };

export type GetCityByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetCityByIdsQuery = { getCityByIds: Array<Pick<City, 'id' | 'name'>> };

export type GetCompanyAccountInfoForHeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyAccountInfoForHeaderQuery = { getCurrentCompanyUser: (
    Pick<CompanyUser, 'id' | 'name'>
    & { company: Maybe<Pick<Company, 'id' | 'name' | 'logo' | 'isInternational' | 'currentTier' | 'registrationFlow'>> }
  ), getCurrentPackageInfo: Maybe<(
    Pick<PackageSerializer, 'id' | 'startAt' | 'endAt' | 'hiringCount' | 'remainingPositions' | 'packageSize' | 'packagePlan'>
    & { remainingDuration: Maybe<Pick<CompanyPackageRemainingDuration, 'diff' | 'diffType'>> }
  )> };

export type GetCompanyUserScheduleInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyUserScheduleInfoQuery = { getCurrentCompanyUser: (
    Pick<CompanyUser, 'id' | 'email' | 'phoneNumber'>
    & { company: Maybe<(
      Pick<Company, 'id'>
      & { teamMember: Maybe<Pick<TeamMember, 'id' | 'scheduleUrl'>> }
    )> }
  ) };

export type GetCurrentActiveJobsSearchCandidatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentActiveJobsSearchCandidatesQuery = { getCurrentActiveJobsSearchCandidates: Array<(
    Pick<JobSearchCandidate, 'id' | 'maxSalary' | 'companyUserName'>
    & { job: (
      Pick<Job, 'id' | 'slug' | 'title' | 'requiredEnglish' | 'remoteWork' | 'hybrid' | 'cityId' | 'cltMinSalary' | 'cltMaxSalary' | 'pjMinSalary' | 'pjMaxSalary' | 'usdAnnualSalaryMin' | 'usdAnnualSalaryMax' | 'focusId' | 'lastBidMessage'>
      & { technologies: Maybe<Array<Pick<Technology, 'id' | 'name'>>> }
    ) }
  )> };

export type GetCurrentCompanyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyUserQuery = { getCurrentCompanyUser: (
    Pick<CompanyUser, 'confirmationSentAt' | 'confirmedAt' | 'createdAt' | 'currentSignInAt' | 'currentSignInIp' | 'email' | 'failedAttempts' | 'firstReferer' | 'id' | 'jobTitle' | 'lastSignInAt' | 'lastSignInIp' | 'lockedAt' | 'md5Email' | 'name' | 'phoneNumber' | 'receiveNotifications' | 'rememberCreatedAt' | 'role' | 'signInCount' | 'termsOfService' | 'unconfirmedEmail' | 'updatedAt' | 'utmCampaign' | 'utmMedium' | 'utmSource' | 'utmTerm' | 'otpRequiredForLogin' | 'twoFactorAuthenticationIsConfigured' | 'twoFactorAuthenticationQrCodeUri' | 'accessHub'>
    & { company: Maybe<(
      Pick<Company, 'id' | 'name' | 'segmentation' | 'status' | 'demo' | 'isInternalCompany' | 'isInternational' | 'hasAccessToGupyIntegration' | 'hasActivePlan' | 'hasPausedPackage' | 'unrestrictedView' | 'currentTier' | 'registrationFlow' | 'logo'>
      & { cities: Maybe<Array<(
        Pick<City, 'id' | 'name' | 'priority'>
        & { state: Pick<State, 'id' | 'name' | 'abbreviation'> }
      )>>, companyPackages: Maybe<Array<(
        Pick<CompanyPackage, 'id' | 'startAt' | 'endAt' | 'revenue' | 'status' | 'automaticRenewal'>
        & { packagePlan: Pick<PackagePlan, 'slug'> }
      )>>, pausedPackage: Maybe<Pick<CompanyPackage, 'id' | 'pauseStartAt' | 'pauseEndAt'>>, teamMember: Maybe<Pick<TeamMember, 'email'>>, features: Maybe<Pick<Features, 'showSalary' | 'showEnglishVetting' | 'showProfileTranslation' | 'showTechVetting' | 'searchPoolSelectedByIa' | 'searchPoolGeekhunterApproved' | 'showRecommendations' | 'standard3Jobs' | 'showWorkAvailability' | 'showSocialNetworks' | 'addNewUsers' | 'diversityFilter' | 'accessToAccountManager' | 'accessCompanyUserRoles' | 'accessToHub' | 'accessToAts'>> }
    )> }
  ) };

export type GetCurrentCompanyUserBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyUserBasicInfoQuery = { getCurrentCompanyUser: (
    Pick<CompanyUser, 'id' | 'email' | 'name' | 'phoneNumber'>
    & { company: Maybe<Pick<Company, 'id' | 'name' | 'isInternational'>> }
  ), getCurrentPackageInfo: Maybe<Pick<PackageSerializer, 'id' | 'packagePlan'>> };

export type GetCurrentCompanyUserCompanyCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyUserCompanyCitiesQuery = { getCurrentCompanyUser: (
    Pick<CompanyUser, 'id'>
    & { company: Maybe<(
      Pick<Company, 'id'>
      & { cities: Maybe<Array<(
        Pick<City, 'id' | 'name' | 'priority'>
        & { state: Pick<State, 'id' | 'name' | 'abbreviation'> }
      )>> }
    )> }
  ) };

export type GetCurrentCompanyUserIsConfirmedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyUserIsConfirmedQuery = { getCurrentCompanyUser: Pick<CompanyUser, 'id' | 'confirmedAt'> };

export type GetGupyIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGupyIntegrationQuery = { getGupyIntegration: Maybe<Pick<PartnerIntegration, 'id' | 'domain' | 'token' | 'active' | 'hasAgreedToTerms'>> };

export type GetTechnologiesSuggestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTechnologiesSuggestionsQuery = { getTechnologiesSuggestions: Array<Pick<Technology, 'id' | 'name'>> };

export type GetTechnologyByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetTechnologyByIdsQuery = { getTechnologyByIds: Array<Pick<Technology, 'id' | 'name'>> };

export type CountHubPositionsByCompanyQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;


export type CountHubPositionsByCompanyQuery = Pick<Query, 'countHubPositionsByCompany'>;

export type GetHubPositionsByCompanyQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type GetHubPositionsByCompanyQuery = { getHubPositionsByCompany: Array<(
    Pick<HubPosition, 'id' | 'name' | 'post' | 'seniority' | 'level' | 'salary' | 'internalRole' | 'origin'>
    & { focus: Maybe<Pick<Focus, 'id' | 'description'>>, jobSalaries: Pick<JobSalaries, 'minSalary' | 'maxSalary'>, hubSalaryStatistic: Maybe<Pick<HubSalaryStatistic, 'median' | 'mean' | 'min' | 'max' | 'numberOfSamples' | 'basis'>> }
  )> };

export type ListIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIndustriesQuery = { industries: Array<Pick<Industry, 'id' | 'name' | 'active'>> };

export type ListNumberOfDevelopersOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListNumberOfDevelopersOptionsQuery = { numberOfProgrammersOptions: Array<Pick<KeyValueString, 'key' | 'value'>> };

export type MetabaseEmbedDashboardUrlQueryVariables = Exact<{
  dashboard: Scalars['Int'];
  role: Scalars['String'];
}>;


export type MetabaseEmbedDashboardUrlQuery = Pick<Query, 'metabaseEmbedDashboardUrl'>;

export type SearchCandidatesQueryVariables = Exact<{
  filters: SearchCandidateFilter;
  searchId?: Maybe<Scalars['ID']>;
  onlyTotals?: Maybe<Scalars['Boolean']>;
}>;


export type SearchCandidatesQuery = { searchCandidates: (
    Pick<CompanySearchCandidate, 'totalFilteredCount' | 'isDefaultSearch' | 'companyCandidateSearchId'>
    & { candidates: Array<(
      Pick<CandidateFiltered, 'isFavorite' | 'lastTimeViewed'>
      & { candidate: (
        Pick<Candidate, 'id' | 'name' | 'image' | 'positionTitle' | 'cltSalary' | 'pjSalary' | 'usdSalary' | 'lastApprovedTime' | 'approvedInternational' | 'summary' | 'workRemote' | 'localWorkPreference' | 'specialNecessity' | 'humanizedSpecialNecessity' | 'searchPools'>
        & { city: Maybe<Pick<City, 'id' | 'name'>>, experiences: Maybe<Array<Pick<Experiences, 'id' | 'company' | 'startDate' | 'endDate' | 'currentJob'>>>, skills: Array<(
          Pick<Skills, 'id' | 'mainTech'>
          & { level: Maybe<Pick<ExperienceLevel, 'value' | 'text'>>, technology: Maybe<Pick<Technology, 'id' | 'name'>> }
        )>, educations: Maybe<Array<Pick<Educations, 'id' | 'institution' | 'educationStatus' | 'endYear'>>>, candidateFocus: Maybe<Array<(
          Pick<CandidateFocus, 'id' | 'order'>
          & { focus: Maybe<Pick<Focus, 'id' | 'description'>>, experienceLevel: Maybe<Pick<ExperienceLevel, 'value' | 'text'>> }
        )>>, candidateCities: Maybe<Array<(
          Pick<CandidateCities, 'id' | 'order'>
          & { city: Pick<City, 'id' | 'name'> }
        )>>, candidateLanguages: Maybe<Array<(
          Pick<CandidateLanguages, 'assessmentLevel' | 'updatedAt'>
          & { language: Maybe<Pick<Language, 'id' | 'name'>>, readingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, talkingLevel: Maybe<Pick<ExperienceLevel, 'value'>>, writingLevel: Maybe<Pick<ExperienceLevel, 'value'>> }
        )>> }
      ), lastBidSent: Maybe<(
        Pick<Bid, 'id' | 'status' | 'createdAt'>
        & { companyUser: Maybe<Pick<CompanyUser, 'id' | 'name'>> }
      )>, notes: Maybe<Array<(
        Pick<Note, 'note' | 'createdAt'>
        & { actor: Pick<CompanyUser, 'name'> }
      )>> }
    )> }
  ) };

export type SearchCandidatesTotalsQueryVariables = Exact<{
  filters: SearchCandidateFilter;
  searchId?: Maybe<Scalars['ID']>;
  onlyTotals?: Maybe<Scalars['Boolean']>;
}>;


export type SearchCandidatesTotalsQuery = { searchCandidates: Pick<CompanySearchCandidate, 'totalFilteredCount'> };

export type SearchLanguagesQueryVariables = Exact<{
  text: Scalars['String'];
}>;


export type SearchLanguagesQuery = { searchLanguages: Array<Pick<Language, 'id' | 'name'>> };

export type ValidateCompanyRegistrationQueryVariables = Exact<{
  companyDomain: Scalars['String'];
  companyName: Scalars['String'];
}>;


export type ValidateCompanyRegistrationQuery = { validateCompanyRegistration: Maybe<Pick<Ok, 'ok'>> };

export type FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindActiveJobsByCompanyUserWhoIsResponsibleQuery = { findActiveJobsByCompanyUserWhoIsResponsible: Array<Pick<Job, 'id'>> };

export type FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindPendingBidsByCompanyUserWhoIsResponsibleQuery = { findPendingBidsByCompanyUserWhoIsResponsible: Array<Pick<Bid, 'id'>> };

export type FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery = { findScheduledInterviewsByCompanyUserWhoIsResponsible: Array<Pick<InterviewEvent, 'id'>> };

export type GetAllCompanyUsersFromCurrentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCompanyUsersFromCurrentCompanyQuery = { getAllCompanyUsersFromCurrentCompany: Array<Pick<CompanyUser, 'id' | 'name' | 'role' | 'email' | 'jobTitle' | 'receiveNotifications' | 'active'>> };

export type GetCompanyUserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompanyUserByIdQuery = { getCompanyUserById: Pick<CompanyUser, 'id' | 'name' | 'role' | 'email' | 'jobTitle' | 'receiveNotifications' | 'phoneNumber' | 'active' | 'accessHub'> };

export type GetFeatureFlagQueryVariables = Exact<{
  params: GetFeatureFlag;
}>;


export type GetFeatureFlagQuery = Pick<Query, 'getFeatureFlag'>;

export type FindActiveAndApprovedJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindActiveAndApprovedJobsQuery = { findActiveAndApprovedJobs: Array<(
    Pick<Job, 'id' | 'cltMaxSalary' | 'cltMinSalary' | 'createdAt' | 'maxSalary' | 'pjMaxSalary' | 'pjMinSalary' | 'remoteWork' | 'slug' | 'title' | 'focusId' | 'experienceLevel' | 'requirements'>
    & { city: Maybe<Pick<City, 'id' | 'name'>>, technologies: Maybe<Array<Pick<Technology, 'id' | 'name' | 'urlPath'>>> }
  )> };

export type FindFirstJobBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type FindFirstJobBySlugQuery = { findFirstJobBySlug: Maybe<(
    Pick<Job, 'id' | 'title' | 'experienceLevel' | 'active' | 'adminApproval' | 'cltMaxSalary' | 'cltMinSalary' | 'createdAt' | 'maxSalary' | 'pjMaxSalary' | 'pjMinSalary' | 'usdAnnualSalaryMin' | 'usdAnnualSalaryMax' | 'remoteWork' | 'hybrid' | 'slug' | 'activities' | 'requirements' | 'englishLevel' | 'description' | 'focusId' | 'hideSalary' | 'hideCompany' | 'isInternational' | 'jobType' | 'techCareer' | 'careerExperienceLevel' | 'focusExperienceLevel'>
    & { city: Maybe<(
      Pick<City, 'id' | 'name'>
      & { state: Pick<State, 'abbreviation'> }
    )>, jobBenefits: Array<(
      Pick<JobBenefit, 'description'>
      & { benefit: Pick<Benefit, 'name' | 'key'> }
    )>, jobTechnologies: Maybe<Array<(
      Pick<JobTechnology, 'id' | 'required'>
      & { technology: Maybe<Pick<Technology, 'id' | 'name' | 'urlPath'>> }
    )>>, company: Maybe<(
      Pick<Company, 'summary' | 'name'>
      & { industry: Maybe<Pick<Industry, 'name'>> }
    )>, focus: Maybe<(
      Pick<Focus, 'description'>
      & { career: Pick<Career, 'name' | 'slug'> }
    )>, publishedScreening: Maybe<(
      Pick<Screening, 'id'>
      & { questions: Maybe<Array<Pick<Question, 'id' | 'answerType' | 'name' | 'mandatory' | 'minAnswerOptions'>>> }
    )> }
  )> };

export type FindLatestApprovedJobsQueryVariables = Exact<{
  quantity?: Maybe<Scalars['Int']>;
}>;


export type FindLatestApprovedJobsQuery = { findLatestApprovedJobs: Array<(
    Pick<Job, 'id' | 'cltMaxSalary' | 'cltMinSalary' | 'createdAt' | 'maxSalary' | 'pjMaxSalary' | 'pjMinSalary' | 'usdAnnualSalaryMin' | 'usdAnnualSalaryMax' | 'remoteWork' | 'hybrid' | 'slug' | 'hideSalary' | 'title' | 'isInternational' | 'jobType'>
    & { city: Maybe<Pick<City, 'id' | 'name'>>, technologies: Maybe<Array<Pick<Technology, 'id' | 'name' | 'urlPath'>>>, company: Maybe<Pick<Company, 'slug'>> }
  )> };

export type FindMaxMinJobsSalaryQueryVariables = Exact<{ [key: string]: never; }>;


export type FindMaxMinJobsSalaryQuery = { findMaxMinJobsSalary: Pick<JobMinMaxSalary, 'minSalary' | 'maxSalary'> };

export type FindShowcaseJobsQueryVariables = Exact<{
  showcaseParams: SearchJobFilter;
}>;


export type FindShowcaseJobsQuery = { findShowcaseJobs: (
    Pick<SearchJobReturn, 'total'>
    & { data: Array<(
      Pick<Job, 'id' | 'cltMaxSalary' | 'cltMinSalary' | 'createdAt' | 'maxSalary' | 'pjMaxSalary' | 'pjMinSalary' | 'usdAnnualSalaryMin' | 'usdAnnualSalaryMax' | 'remoteWork' | 'slug' | 'hideSalary' | 'title' | 'focusId' | 'experienceLevel' | 'requirements' | 'description' | 'isInternational' | 'hybrid' | 'techCareer' | 'careerExperienceLevel' | 'focusExperienceLevel' | 'jobType'>
      & { city: Maybe<Pick<City, 'id' | 'name'>>, technologies: Maybe<Array<Pick<Technology, 'id' | 'name' | 'urlPath'>>>, focus: Maybe<(
        Pick<Focus, 'id' | 'description'>
        & { career: Pick<Career, 'id' | 'name'> }
      )>, company: Maybe<Pick<Company, 'slug'>> }
    )> }
  ) };

export type FindSimilarJobsQueryVariables = Exact<{
  jobId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
}>;


export type FindSimilarJobsQuery = { findSimilarJobs: Array<(
    Pick<Job, 'id' | 'cltMaxSalary' | 'cltMinSalary' | 'createdAt' | 'maxSalary' | 'pjMaxSalary' | 'pjMinSalary' | 'usdAnnualSalaryMin' | 'usdAnnualSalaryMax' | 'remoteWork' | 'hybrid' | 'slug' | 'hideSalary' | 'title' | 'isInternational' | 'jobType'>
    & { city: Maybe<Pick<City, 'id' | 'name'>>, technologies: Maybe<Array<Pick<Technology, 'id' | 'name' | 'urlPath'>>>, company: Maybe<Pick<Company, 'slug'>> }
  )> };

export type GetScreeningByJobIdQueryVariables = Exact<{
  jobId: Scalars['Int'];
  publishedOnly?: Maybe<Scalars['Boolean']>;
}>;


export type GetScreeningByJobIdQuery = { getScreeningByJobId: Maybe<(
    Pick<Screening, 'id'>
    & { questions: Maybe<Array<Pick<Question, 'id' | 'answerType' | 'name' | 'mandatory' | 'minAnswerOptions'>>> }
  )> };

export type ListCountriesAndCitiesQueryVariables = Exact<{
  textSearch: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type ListCountriesAndCitiesQuery = { listCountries: Array<Pick<Country, 'id' | 'name'>>, searchCities: Array<Pick<City, 'id' | 'name'>> };

export type ListSubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSubscriptionPlansQuery = { listSubscriptionPlans: Array<Pick<SubscriptionPlan, 'id' | 'name' | 'price' | 'period' | 'slug'>> };

export type SearchCitiesQueryVariables = Exact<{
  textSearch: Scalars['String'];
}>;


export type SearchCitiesQuery = { searchCities: Array<Pick<City, 'id' | 'name'>> };

export type SearchCompanyAliasQueryVariables = Exact<{
  textSearch: Scalars['String'];
}>;


export type SearchCompanyAliasQuery = { searchCompanyAlias: Array<Pick<CompanyAlias, 'id' | 'name' | 'baseCompanyId'>> };

export type SearchTechsQueryVariables = Exact<{
  textSearch: Scalars['String'];
  subTypes?: Maybe<Array<TechnologySubtypeEnum> | TechnologySubtypeEnum>;
}>;


export type SearchTechsQuery = { searchTechs: Array<Pick<Technology, 'id' | 'name'>> };


export const RegisterAmplitudeHitDocument = gql`
    mutation RegisterAmplitudeHit($amplitudeHitType: AmplitudeHitOptionsEnum!, $userEmail: String, $userType: AmplitudeUserTypeEnum!, $anonymous: Boolean) {
  registerAmplitudeHit(
    amplitudeHitType: $amplitudeHitType
    userEmail: $userEmail
    userType: $userType
    anonymous: $anonymous
  ) {
    ok
  }
}
    `;
export type RegisterAmplitudeHitMutationFn = Apollo.MutationFunction<RegisterAmplitudeHitMutation, RegisterAmplitudeHitMutationVariables>;

/**
 * __useRegisterAmplitudeHitMutation__
 *
 * To run a mutation, you first call `useRegisterAmplitudeHitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAmplitudeHitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAmplitudeHitMutation, { data, loading, error }] = useRegisterAmplitudeHitMutation({
 *   variables: {
 *      amplitudeHitType: // value for 'amplitudeHitType'
 *      userEmail: // value for 'userEmail'
 *      userType: // value for 'userType'
 *      anonymous: // value for 'anonymous'
 *   },
 * });
 */
export function useRegisterAmplitudeHitMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAmplitudeHitMutation, RegisterAmplitudeHitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAmplitudeHitMutation, RegisterAmplitudeHitMutationVariables>(RegisterAmplitudeHitDocument, options);
      }
export type RegisterAmplitudeHitMutationHookResult = ReturnType<typeof useRegisterAmplitudeHitMutation>;
export type RegisterAmplitudeHitMutationResult = Apollo.MutationResult<RegisterAmplitudeHitMutation>;
export type RegisterAmplitudeHitMutationOptions = Apollo.BaseMutationOptions<RegisterAmplitudeHitMutation, RegisterAmplitudeHitMutationVariables>;
export const RegisterAmplitudeHitAuthenticatedDocument = gql`
    mutation RegisterAmplitudeHitAuthenticated($amplitudeHitType: AmplitudeHitOptionsEnum!) {
  registerAmplitudeHitAuthenticated(amplitudeHitType: $amplitudeHitType) {
    ok
  }
}
    `;
export type RegisterAmplitudeHitAuthenticatedMutationFn = Apollo.MutationFunction<RegisterAmplitudeHitAuthenticatedMutation, RegisterAmplitudeHitAuthenticatedMutationVariables>;

/**
 * __useRegisterAmplitudeHitAuthenticatedMutation__
 *
 * To run a mutation, you first call `useRegisterAmplitudeHitAuthenticatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAmplitudeHitAuthenticatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAmplitudeHitAuthenticatedMutation, { data, loading, error }] = useRegisterAmplitudeHitAuthenticatedMutation({
 *   variables: {
 *      amplitudeHitType: // value for 'amplitudeHitType'
 *   },
 * });
 */
export function useRegisterAmplitudeHitAuthenticatedMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAmplitudeHitAuthenticatedMutation, RegisterAmplitudeHitAuthenticatedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAmplitudeHitAuthenticatedMutation, RegisterAmplitudeHitAuthenticatedMutationVariables>(RegisterAmplitudeHitAuthenticatedDocument, options);
      }
export type RegisterAmplitudeHitAuthenticatedMutationHookResult = ReturnType<typeof useRegisterAmplitudeHitAuthenticatedMutation>;
export type RegisterAmplitudeHitAuthenticatedMutationResult = Apollo.MutationResult<RegisterAmplitudeHitAuthenticatedMutation>;
export type RegisterAmplitudeHitAuthenticatedMutationOptions = Apollo.BaseMutationOptions<RegisterAmplitudeHitAuthenticatedMutation, RegisterAmplitudeHitAuthenticatedMutationVariables>;
export const CandidateRegisterStepAboutDocument = gql`
    mutation CandidateRegisterStepAbout($positionTitle: String!, $summary: String!, $excludedCompaniesAttributes: [CandidateExcludedCompaniesAttributes!]) {
  registerCandidateStepAbout(
    params: {positionTitle: $positionTitle, summary: $summary, excludedCompaniesAttributes: $excludedCompaniesAttributes}
  ) {
    success
    candidate {
      id
      positionTitle
      summary
      candidateExcludedCompanies {
        id
        companyAlias {
          id
          name
        }
      }
    }
  }
}
    `;
export type CandidateRegisterStepAboutMutationFn = Apollo.MutationFunction<CandidateRegisterStepAboutMutation, CandidateRegisterStepAboutMutationVariables>;

/**
 * __useCandidateRegisterStepAboutMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepAboutMutation, { data, loading, error }] = useCandidateRegisterStepAboutMutation({
 *   variables: {
 *      positionTitle: // value for 'positionTitle'
 *      summary: // value for 'summary'
 *      excludedCompaniesAttributes: // value for 'excludedCompaniesAttributes'
 *   },
 * });
 */
export function useCandidateRegisterStepAboutMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepAboutMutation, CandidateRegisterStepAboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepAboutMutation, CandidateRegisterStepAboutMutationVariables>(CandidateRegisterStepAboutDocument, options);
      }
export type CandidateRegisterStepAboutMutationHookResult = ReturnType<typeof useCandidateRegisterStepAboutMutation>;
export type CandidateRegisterStepAboutMutationResult = Apollo.MutationResult<CandidateRegisterStepAboutMutation>;
export type CandidateRegisterStepAboutMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepAboutMutation, CandidateRegisterStepAboutMutationVariables>;
export const CandidateRegisterStepCareerDocument = gql`
    mutation CandidateRegisterStepCareer($workRemote: String!, $pjSalary: Float, $cltSalary: Float, $usdSalary: Float, $candidateCitiesAttributes: [CandidateCitiesAttributes!]) {
  registerCandidateStepCareer(
    params: {workRemote: $workRemote, pjSalary: $pjSalary, cltSalary: $cltSalary, usdSalary: $usdSalary, candidateCitiesAttributes: $candidateCitiesAttributes}
  ) {
    success
    candidate {
      id
      workRemote
      pjSalary
      cltSalary
      usdSalary
      candidateCities {
        city {
          id
          name
        }
        order
      }
    }
  }
}
    `;
export type CandidateRegisterStepCareerMutationFn = Apollo.MutationFunction<CandidateRegisterStepCareerMutation, CandidateRegisterStepCareerMutationVariables>;

/**
 * __useCandidateRegisterStepCareerMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepCareerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepCareerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepCareerMutation, { data, loading, error }] = useCandidateRegisterStepCareerMutation({
 *   variables: {
 *      workRemote: // value for 'workRemote'
 *      pjSalary: // value for 'pjSalary'
 *      cltSalary: // value for 'cltSalary'
 *      usdSalary: // value for 'usdSalary'
 *      candidateCitiesAttributes: // value for 'candidateCitiesAttributes'
 *   },
 * });
 */
export function useCandidateRegisterStepCareerMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepCareerMutation, CandidateRegisterStepCareerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepCareerMutation, CandidateRegisterStepCareerMutationVariables>(CandidateRegisterStepCareerDocument, options);
      }
export type CandidateRegisterStepCareerMutationHookResult = ReturnType<typeof useCandidateRegisterStepCareerMutation>;
export type CandidateRegisterStepCareerMutationResult = Apollo.MutationResult<CandidateRegisterStepCareerMutation>;
export type CandidateRegisterStepCareerMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepCareerMutation, CandidateRegisterStepCareerMutationVariables>;
export const CandidateRegisterStepExperiencesDocument = gql`
    mutation CandidateRegisterStepExperiences($noExperiences: Boolean!, $experiencesAttributes: [ExperiencesAttributes!], $educationsAttributes: [EducationsAttributes!]!, $certificationsAttributes: [CertificationsAttributes!], $projectsAttributes: [ProjectsAttributes!]) {
  registerCandidateStepExperiences(
    params: {noExperiences: $noExperiences, experiencesAttributes: $experiencesAttributes, educationsAttributes: $educationsAttributes, certificationsAttributes: $certificationsAttributes, projectsAttributes: $projectsAttributes}
  ) {
    success
    candidate {
      id
      noExperiences
      experiences {
        company
        currentJob
        description
        endDate
        id
        jobTitle
        startDate
        skills {
          id
          level {
            value
          }
          mainTech
          order
          technology {
            id
            name
          }
        }
      }
      educations {
        course
        educationStatus
        endYear
        id
        institution
        startYear
        title {
          value
        }
      }
      certifications {
        current
        endDate
        id
        institution
        startDate
        title
        url
      }
      projects {
        current
        description
        endDate
        id
        name
        startDate
        url
      }
      candidateExcludedCompanies {
        id
        companyAlias {
          id
          name
        }
      }
    }
  }
}
    `;
export type CandidateRegisterStepExperiencesMutationFn = Apollo.MutationFunction<CandidateRegisterStepExperiencesMutation, CandidateRegisterStepExperiencesMutationVariables>;

/**
 * __useCandidateRegisterStepExperiencesMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepExperiencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepExperiencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepExperiencesMutation, { data, loading, error }] = useCandidateRegisterStepExperiencesMutation({
 *   variables: {
 *      noExperiences: // value for 'noExperiences'
 *      experiencesAttributes: // value for 'experiencesAttributes'
 *      educationsAttributes: // value for 'educationsAttributes'
 *      certificationsAttributes: // value for 'certificationsAttributes'
 *      projectsAttributes: // value for 'projectsAttributes'
 *   },
 * });
 */
export function useCandidateRegisterStepExperiencesMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepExperiencesMutation, CandidateRegisterStepExperiencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepExperiencesMutation, CandidateRegisterStepExperiencesMutationVariables>(CandidateRegisterStepExperiencesDocument, options);
      }
export type CandidateRegisterStepExperiencesMutationHookResult = ReturnType<typeof useCandidateRegisterStepExperiencesMutation>;
export type CandidateRegisterStepExperiencesMutationResult = Apollo.MutationResult<CandidateRegisterStepExperiencesMutation>;
export type CandidateRegisterStepExperiencesMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepExperiencesMutation, CandidateRegisterStepExperiencesMutationVariables>;
export const CandidateRegisterStepImageDocument = gql`
    mutation CandidateRegisterStepImage($params: CandidateRegisterStepImage!) {
  registerCandidateStepImage(params: $params) {
    candidate {
      id
      image
    }
  }
}
    `;
export type CandidateRegisterStepImageMutationFn = Apollo.MutationFunction<CandidateRegisterStepImageMutation, CandidateRegisterStepImageMutationVariables>;

/**
 * __useCandidateRegisterStepImageMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepImageMutation, { data, loading, error }] = useCandidateRegisterStepImageMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCandidateRegisterStepImageMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepImageMutation, CandidateRegisterStepImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepImageMutation, CandidateRegisterStepImageMutationVariables>(CandidateRegisterStepImageDocument, options);
      }
export type CandidateRegisterStepImageMutationHookResult = ReturnType<typeof useCandidateRegisterStepImageMutation>;
export type CandidateRegisterStepImageMutationResult = Apollo.MutationResult<CandidateRegisterStepImageMutation>;
export type CandidateRegisterStepImageMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepImageMutation, CandidateRegisterStepImageMutationVariables>;
export const CandidateRegisterStepLinkedinDocument = gql`
    mutation CandidateRegisterStepLinkedin($params: CandidateRegisterStepLinkedin!) {
  registerCandidateStepLinkedin(params: $params) {
    candidate {
      id
      searchStatus
      linkedInUrl
      hiringOption
    }
  }
}
    `;
export type CandidateRegisterStepLinkedinMutationFn = Apollo.MutationFunction<CandidateRegisterStepLinkedinMutation, CandidateRegisterStepLinkedinMutationVariables>;

/**
 * __useCandidateRegisterStepLinkedinMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepLinkedinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepLinkedinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepLinkedinMutation, { data, loading, error }] = useCandidateRegisterStepLinkedinMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCandidateRegisterStepLinkedinMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepLinkedinMutation, CandidateRegisterStepLinkedinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepLinkedinMutation, CandidateRegisterStepLinkedinMutationVariables>(CandidateRegisterStepLinkedinDocument, options);
      }
export type CandidateRegisterStepLinkedinMutationHookResult = ReturnType<typeof useCandidateRegisterStepLinkedinMutation>;
export type CandidateRegisterStepLinkedinMutationResult = Apollo.MutationResult<CandidateRegisterStepLinkedinMutation>;
export type CandidateRegisterStepLinkedinMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepLinkedinMutation, CandidateRegisterStepLinkedinMutationVariables>;
export const CandidateRegisterStepProfileDocument = gql`
    mutation CandidateRegisterStepProfile($params: CandidateRegisterStepProfile!) {
  registerCandidateStepProfile(params: $params) {
    success
    candidate {
      id
      name
      email
      phone
      city {
        id
        name
      }
      gender
      specialNecessity
      stackoverflowUrl
      githubUrl
      allowWhatsappNotification
      portfolioUrl
      portfolioExtraUrl
    }
  }
}
    `;
export type CandidateRegisterStepProfileMutationFn = Apollo.MutationFunction<CandidateRegisterStepProfileMutation, CandidateRegisterStepProfileMutationVariables>;

/**
 * __useCandidateRegisterStepProfileMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepProfileMutation, { data, loading, error }] = useCandidateRegisterStepProfileMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCandidateRegisterStepProfileMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepProfileMutation, CandidateRegisterStepProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepProfileMutation, CandidateRegisterStepProfileMutationVariables>(CandidateRegisterStepProfileDocument, options);
      }
export type CandidateRegisterStepProfileMutationHookResult = ReturnType<typeof useCandidateRegisterStepProfileMutation>;
export type CandidateRegisterStepProfileMutationResult = Apollo.MutationResult<CandidateRegisterStepProfileMutation>;
export type CandidateRegisterStepProfileMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepProfileMutation, CandidateRegisterStepProfileMutationVariables>;
export const CandidateRegisterStepSkillsDocument = gql`
    mutation CandidateRegisterStepSkills($params: CandidateRegisterStepSkills!) {
  registerCandidateStepSkills(params: $params) {
    success
    candidate {
      id
      workedUsingOnlyEnglish
      candidateFocus {
        order
        experienceLevel {
          value
        }
        focus {
          id
          description
          career {
            name
            id
            slug
            worksWithSkills
          }
        }
      }
      skills {
        id
        level {
          value
        }
        mainTech
        order
        technology {
          id
          name
        }
      }
      experiences {
        company
        currentJob
        description
        endDate
        id
        jobTitle
        startDate
        skills {
          id
          level {
            value
          }
          mainTech
          order
          technology {
            id
            name
          }
        }
      }
      candidateLanguages {
        language {
          id
          name
        }
        readingLevel {
          value
        }
        talkingLevel {
          value
        }
        writingLevel {
          value
        }
        assessmentLevel
        updatedAt
      }
    }
  }
}
    `;
export type CandidateRegisterStepSkillsMutationFn = Apollo.MutationFunction<CandidateRegisterStepSkillsMutation, CandidateRegisterStepSkillsMutationVariables>;

/**
 * __useCandidateRegisterStepSkillsMutation__
 *
 * To run a mutation, you first call `useCandidateRegisterStepSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateRegisterStepSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateRegisterStepSkillsMutation, { data, loading, error }] = useCandidateRegisterStepSkillsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCandidateRegisterStepSkillsMutation(baseOptions?: Apollo.MutationHookOptions<CandidateRegisterStepSkillsMutation, CandidateRegisterStepSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateRegisterStepSkillsMutation, CandidateRegisterStepSkillsMutationVariables>(CandidateRegisterStepSkillsDocument, options);
      }
export type CandidateRegisterStepSkillsMutationHookResult = ReturnType<typeof useCandidateRegisterStepSkillsMutation>;
export type CandidateRegisterStepSkillsMutationResult = Apollo.MutationResult<CandidateRegisterStepSkillsMutation>;
export type CandidateRegisterStepSkillsMutationOptions = Apollo.BaseMutationOptions<CandidateRegisterStepSkillsMutation, CandidateRegisterStepSkillsMutationVariables>;
export const SetCurrentCandidateVideoAskCompletedDocument = gql`
    mutation SetCurrentCandidateVideoAskCompleted($videoAskCompleted: Boolean!, $videoAskResponseUrl: String) {
  setCurrentCandidateVideoAskCompleted(
    videoAskCompleted: $videoAskCompleted
    videoAskResponseUrl: $videoAskResponseUrl
  ) {
    id
  }
}
    `;
export type SetCurrentCandidateVideoAskCompletedMutationFn = Apollo.MutationFunction<SetCurrentCandidateVideoAskCompletedMutation, SetCurrentCandidateVideoAskCompletedMutationVariables>;

/**
 * __useSetCurrentCandidateVideoAskCompletedMutation__
 *
 * To run a mutation, you first call `useSetCurrentCandidateVideoAskCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentCandidateVideoAskCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentCandidateVideoAskCompletedMutation, { data, loading, error }] = useSetCurrentCandidateVideoAskCompletedMutation({
 *   variables: {
 *      videoAskCompleted: // value for 'videoAskCompleted'
 *      videoAskResponseUrl: // value for 'videoAskResponseUrl'
 *   },
 * });
 */
export function useSetCurrentCandidateVideoAskCompletedMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentCandidateVideoAskCompletedMutation, SetCurrentCandidateVideoAskCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCurrentCandidateVideoAskCompletedMutation, SetCurrentCandidateVideoAskCompletedMutationVariables>(SetCurrentCandidateVideoAskCompletedDocument, options);
      }
export type SetCurrentCandidateVideoAskCompletedMutationHookResult = ReturnType<typeof useSetCurrentCandidateVideoAskCompletedMutation>;
export type SetCurrentCandidateVideoAskCompletedMutationResult = Apollo.MutationResult<SetCurrentCandidateVideoAskCompletedMutation>;
export type SetCurrentCandidateVideoAskCompletedMutationOptions = Apollo.BaseMutationOptions<SetCurrentCandidateVideoAskCompletedMutation, SetCurrentCandidateVideoAskCompletedMutationVariables>;
export const UpdateCandidateDocument = gql`
    mutation UpdateCandidate($payload: CandidateInput!) {
  updateCandidate(payload: $payload) {
    cltSalary
    pjSalary
    usdSalary
    hiringOption
    skills {
      id
      level {
        value
      }
      mainTech
      order
      technology {
        id
        name
      }
    }
    candidateLanguages {
      language {
        id
        name
      }
      readingLevel {
        value
      }
      talkingLevel {
        value
      }
      writingLevel {
        value
      }
      assessmentLevel
      updatedAt
    }
    searchStatus
    available
  }
}
    `;
export type UpdateCandidateMutationFn = Apollo.MutationFunction<UpdateCandidateMutation, UpdateCandidateMutationVariables>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateCandidateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidateMutation, UpdateCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidateMutation, UpdateCandidateMutationVariables>(UpdateCandidateDocument, options);
      }
export type UpdateCandidateMutationHookResult = ReturnType<typeof useUpdateCandidateMutation>;
export type UpdateCandidateMutationResult = Apollo.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<UpdateCandidateMutation, UpdateCandidateMutationVariables>;
export const CreateCurrentCandidateActivityLogDocument = gql`
    mutation CreateCurrentCandidateActivityLog($eventType: ActivityLogEventTypeEnum!) {
  createCurrentCandidateActivityLog(eventType: $eventType) {
    id
  }
}
    `;
export type CreateCurrentCandidateActivityLogMutationFn = Apollo.MutationFunction<CreateCurrentCandidateActivityLogMutation, CreateCurrentCandidateActivityLogMutationVariables>;

/**
 * __useCreateCurrentCandidateActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateCurrentCandidateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentCandidateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentCandidateActivityLogMutation, { data, loading, error }] = useCreateCurrentCandidateActivityLogMutation({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useCreateCurrentCandidateActivityLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentCandidateActivityLogMutation, CreateCurrentCandidateActivityLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentCandidateActivityLogMutation, CreateCurrentCandidateActivityLogMutationVariables>(CreateCurrentCandidateActivityLogDocument, options);
      }
export type CreateCurrentCandidateActivityLogMutationHookResult = ReturnType<typeof useCreateCurrentCandidateActivityLogMutation>;
export type CreateCurrentCandidateActivityLogMutationResult = Apollo.MutationResult<CreateCurrentCandidateActivityLogMutation>;
export type CreateCurrentCandidateActivityLogMutationOptions = Apollo.BaseMutationOptions<CreateCurrentCandidateActivityLogMutation, CreateCurrentCandidateActivityLogMutationVariables>;
export const CreateCurrentCandidateAvailableFeedbackDocument = gql`
    mutation CreateCurrentCandidateAvailableFeedback($available: Boolean!, $feedbackType: AvailableFeedbackEnum!, $message: String) {
  createCurrentCandidateAvailableFeedback(
    available: $available
    feedbackType: $feedbackType
    message: $message
  ) {
    id
  }
}
    `;
export type CreateCurrentCandidateAvailableFeedbackMutationFn = Apollo.MutationFunction<CreateCurrentCandidateAvailableFeedbackMutation, CreateCurrentCandidateAvailableFeedbackMutationVariables>;

/**
 * __useCreateCurrentCandidateAvailableFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateCurrentCandidateAvailableFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentCandidateAvailableFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentCandidateAvailableFeedbackMutation, { data, loading, error }] = useCreateCurrentCandidateAvailableFeedbackMutation({
 *   variables: {
 *      available: // value for 'available'
 *      feedbackType: // value for 'feedbackType'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateCurrentCandidateAvailableFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentCandidateAvailableFeedbackMutation, CreateCurrentCandidateAvailableFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentCandidateAvailableFeedbackMutation, CreateCurrentCandidateAvailableFeedbackMutationVariables>(CreateCurrentCandidateAvailableFeedbackDocument, options);
      }
export type CreateCurrentCandidateAvailableFeedbackMutationHookResult = ReturnType<typeof useCreateCurrentCandidateAvailableFeedbackMutation>;
export type CreateCurrentCandidateAvailableFeedbackMutationResult = Apollo.MutationResult<CreateCurrentCandidateAvailableFeedbackMutation>;
export type CreateCurrentCandidateAvailableFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateCurrentCandidateAvailableFeedbackMutation, CreateCurrentCandidateAvailableFeedbackMutationVariables>;
export const RegisterCandidateFirstStepDocument = gql`
    mutation RegisterCandidateFirstStep($termsOfService: Boolean!, $email: String!, $emailNotification: Boolean!, $name: String!, $password: String!, $firstReferer: String, $fromReferral: String, $utmSource: String!, $utmMedium: String!, $utmCampaign: String!, $utmTerm: String, $deviceType: String, $lastRenderedSlug: String) {
  registerCandidateFirstStep(
    candidateParams: {termsOfService: $termsOfService, email: $email, emailNotification: $emailNotification, name: $name, password: $password, firstReferer: $firstReferer, fromReferral: $fromReferral, lastRenderedSlug: $lastRenderedSlug}
    trackConversionParams: {utmSource: $utmSource, utmMedium: $utmMedium, utmCampaign: $utmCampaign, utmTerm: $utmTerm, deviceType: $deviceType}
  ) {
    candidate {
      id
      name
      email
    }
    authToken
    errors
  }
}
    `;
export type RegisterCandidateFirstStepMutationFn = Apollo.MutationFunction<RegisterCandidateFirstStepMutation, RegisterCandidateFirstStepMutationVariables>;

/**
 * __useRegisterCandidateFirstStepMutation__
 *
 * To run a mutation, you first call `useRegisterCandidateFirstStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCandidateFirstStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCandidateFirstStepMutation, { data, loading, error }] = useRegisterCandidateFirstStepMutation({
 *   variables: {
 *      termsOfService: // value for 'termsOfService'
 *      email: // value for 'email'
 *      emailNotification: // value for 'emailNotification'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      firstReferer: // value for 'firstReferer'
 *      fromReferral: // value for 'fromReferral'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      utmTerm: // value for 'utmTerm'
 *      deviceType: // value for 'deviceType'
 *      lastRenderedSlug: // value for 'lastRenderedSlug'
 *   },
 * });
 */
export function useRegisterCandidateFirstStepMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCandidateFirstStepMutation, RegisterCandidateFirstStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCandidateFirstStepMutation, RegisterCandidateFirstStepMutationVariables>(RegisterCandidateFirstStepDocument, options);
      }
export type RegisterCandidateFirstStepMutationHookResult = ReturnType<typeof useRegisterCandidateFirstStepMutation>;
export type RegisterCandidateFirstStepMutationResult = Apollo.MutationResult<RegisterCandidateFirstStepMutation>;
export type RegisterCandidateFirstStepMutationOptions = Apollo.BaseMutationOptions<RegisterCandidateFirstStepMutation, RegisterCandidateFirstStepMutationVariables>;
export const SetCurrentCandidateDashboardFirstAccessDocument = gql`
    mutation SetCurrentCandidateDashboardFirstAccess($isDashboardFirstAccess: Boolean!) {
  setCurrentCandidateDashboardFirstAccess(
    isDashboardFirstAccess: $isDashboardFirstAccess
  ) {
    id
  }
}
    `;
export type SetCurrentCandidateDashboardFirstAccessMutationFn = Apollo.MutationFunction<SetCurrentCandidateDashboardFirstAccessMutation, SetCurrentCandidateDashboardFirstAccessMutationVariables>;

/**
 * __useSetCurrentCandidateDashboardFirstAccessMutation__
 *
 * To run a mutation, you first call `useSetCurrentCandidateDashboardFirstAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentCandidateDashboardFirstAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentCandidateDashboardFirstAccessMutation, { data, loading, error }] = useSetCurrentCandidateDashboardFirstAccessMutation({
 *   variables: {
 *      isDashboardFirstAccess: // value for 'isDashboardFirstAccess'
 *   },
 * });
 */
export function useSetCurrentCandidateDashboardFirstAccessMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentCandidateDashboardFirstAccessMutation, SetCurrentCandidateDashboardFirstAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCurrentCandidateDashboardFirstAccessMutation, SetCurrentCandidateDashboardFirstAccessMutationVariables>(SetCurrentCandidateDashboardFirstAccessDocument, options);
      }
export type SetCurrentCandidateDashboardFirstAccessMutationHookResult = ReturnType<typeof useSetCurrentCandidateDashboardFirstAccessMutation>;
export type SetCurrentCandidateDashboardFirstAccessMutationResult = Apollo.MutationResult<SetCurrentCandidateDashboardFirstAccessMutation>;
export type SetCurrentCandidateDashboardFirstAccessMutationOptions = Apollo.BaseMutationOptions<SetCurrentCandidateDashboardFirstAccessMutation, SetCurrentCandidateDashboardFirstAccessMutationVariables>;
export const SetCurrentCandidateStatusDocument = gql`
    mutation SetCurrentCandidateStatus($searchStatus: SearchStatusEnum!, $available: Boolean!) {
  setCurrentCandidateStatus(searchStatus: $searchStatus, available: $available) {
    id
    available
    searchStatus
  }
}
    `;
export type SetCurrentCandidateStatusMutationFn = Apollo.MutationFunction<SetCurrentCandidateStatusMutation, SetCurrentCandidateStatusMutationVariables>;

/**
 * __useSetCurrentCandidateStatusMutation__
 *
 * To run a mutation, you first call `useSetCurrentCandidateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentCandidateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentCandidateStatusMutation, { data, loading, error }] = useSetCurrentCandidateStatusMutation({
 *   variables: {
 *      searchStatus: // value for 'searchStatus'
 *      available: // value for 'available'
 *   },
 * });
 */
export function useSetCurrentCandidateStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentCandidateStatusMutation, SetCurrentCandidateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCurrentCandidateStatusMutation, SetCurrentCandidateStatusMutationVariables>(SetCurrentCandidateStatusDocument, options);
      }
export type SetCurrentCandidateStatusMutationHookResult = ReturnType<typeof useSetCurrentCandidateStatusMutation>;
export type SetCurrentCandidateStatusMutationResult = Apollo.MutationResult<SetCurrentCandidateStatusMutation>;
export type SetCurrentCandidateStatusMutationOptions = Apollo.BaseMutationOptions<SetCurrentCandidateStatusMutation, SetCurrentCandidateStatusMutationVariables>;
export const SetCurrentCandidateWhatsappNotificationDocument = gql`
    mutation SetCurrentCandidateWhatsappNotification($allowWhatsappNotification: Boolean!) {
  setCurrentCandidateWhatsappNotification(
    allowWhatsappNotification: $allowWhatsappNotification
  ) {
    id
  }
}
    `;
export type SetCurrentCandidateWhatsappNotificationMutationFn = Apollo.MutationFunction<SetCurrentCandidateWhatsappNotificationMutation, SetCurrentCandidateWhatsappNotificationMutationVariables>;

/**
 * __useSetCurrentCandidateWhatsappNotificationMutation__
 *
 * To run a mutation, you first call `useSetCurrentCandidateWhatsappNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentCandidateWhatsappNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentCandidateWhatsappNotificationMutation, { data, loading, error }] = useSetCurrentCandidateWhatsappNotificationMutation({
 *   variables: {
 *      allowWhatsappNotification: // value for 'allowWhatsappNotification'
 *   },
 * });
 */
export function useSetCurrentCandidateWhatsappNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentCandidateWhatsappNotificationMutation, SetCurrentCandidateWhatsappNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCurrentCandidateWhatsappNotificationMutation, SetCurrentCandidateWhatsappNotificationMutationVariables>(SetCurrentCandidateWhatsappNotificationDocument, options);
      }
export type SetCurrentCandidateWhatsappNotificationMutationHookResult = ReturnType<typeof useSetCurrentCandidateWhatsappNotificationMutation>;
export type SetCurrentCandidateWhatsappNotificationMutationResult = Apollo.MutationResult<SetCurrentCandidateWhatsappNotificationMutation>;
export type SetCurrentCandidateWhatsappNotificationMutationOptions = Apollo.BaseMutationOptions<SetCurrentCandidateWhatsappNotificationMutation, SetCurrentCandidateWhatsappNotificationMutationVariables>;
export const CreateProspectDocument = gql`
    mutation CreateProspect($name: String!, $email: String!, $countryId: ID!, $phone: String!) {
  createProspect(name: $name, email: $email, countryId: $countryId, phone: $phone) {
    alreadyAClient
    id
  }
}
    `;
export type CreateProspectMutationFn = Apollo.MutationFunction<CreateProspectMutation, CreateProspectMutationVariables>;

/**
 * __useCreateProspectMutation__
 *
 * To run a mutation, you first call `useCreateProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProspectMutation, { data, loading, error }] = useCreateProspectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      countryId: // value for 'countryId'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateProspectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProspectMutation, CreateProspectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProspectMutation, CreateProspectMutationVariables>(CreateProspectDocument, options);
      }
export type CreateProspectMutationHookResult = ReturnType<typeof useCreateProspectMutation>;
export type CreateProspectMutationResult = Apollo.MutationResult<CreateProspectMutation>;
export type CreateProspectMutationOptions = Apollo.BaseMutationOptions<CreateProspectMutation, CreateProspectMutationVariables>;
export const PurchasePlanDocument = gql`
    mutation PurchasePlan($params: Purchase!) {
  purchasePlan(params: $params) {
    ok
  }
}
    `;
export type PurchasePlanMutationFn = Apollo.MutationFunction<PurchasePlanMutation, PurchasePlanMutationVariables>;

/**
 * __usePurchasePlanMutation__
 *
 * To run a mutation, you first call `usePurchasePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchasePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchasePlanMutation, { data, loading, error }] = usePurchasePlanMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePurchasePlanMutation(baseOptions?: Apollo.MutationHookOptions<PurchasePlanMutation, PurchasePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchasePlanMutation, PurchasePlanMutationVariables>(PurchasePlanDocument, options);
      }
export type PurchasePlanMutationHookResult = ReturnType<typeof usePurchasePlanMutation>;
export type PurchasePlanMutationResult = Apollo.MutationResult<PurchasePlanMutation>;
export type PurchasePlanMutationOptions = Apollo.BaseMutationOptions<PurchasePlanMutation, PurchasePlanMutationVariables>;
export const AcceptCompanyUserTermsOfServiceDocument = gql`
    mutation AcceptCompanyUserTermsOfService {
  acceptCompanyUserTermsOfService {
    redirectUrl
  }
}
    `;
export type AcceptCompanyUserTermsOfServiceMutationFn = Apollo.MutationFunction<AcceptCompanyUserTermsOfServiceMutation, AcceptCompanyUserTermsOfServiceMutationVariables>;

/**
 * __useAcceptCompanyUserTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useAcceptCompanyUserTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCompanyUserTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCompanyUserTermsOfServiceMutation, { data, loading, error }] = useAcceptCompanyUserTermsOfServiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptCompanyUserTermsOfServiceMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCompanyUserTermsOfServiceMutation, AcceptCompanyUserTermsOfServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCompanyUserTermsOfServiceMutation, AcceptCompanyUserTermsOfServiceMutationVariables>(AcceptCompanyUserTermsOfServiceDocument, options);
      }
export type AcceptCompanyUserTermsOfServiceMutationHookResult = ReturnType<typeof useAcceptCompanyUserTermsOfServiceMutation>;
export type AcceptCompanyUserTermsOfServiceMutationResult = Apollo.MutationResult<AcceptCompanyUserTermsOfServiceMutation>;
export type AcceptCompanyUserTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<AcceptCompanyUserTermsOfServiceMutation, AcceptCompanyUserTermsOfServiceMutationVariables>;
export const CompanyCancelSubscriptionDocument = gql`
    mutation CompanyCancelSubscription {
  companyCancelSubscription {
    ok
  }
}
    `;
export type CompanyCancelSubscriptionMutationFn = Apollo.MutationFunction<CompanyCancelSubscriptionMutation, CompanyCancelSubscriptionMutationVariables>;

/**
 * __useCompanyCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCompanyCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCancelSubscriptionMutation, { data, loading, error }] = useCompanyCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompanyCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CompanyCancelSubscriptionMutation, CompanyCancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyCancelSubscriptionMutation, CompanyCancelSubscriptionMutationVariables>(CompanyCancelSubscriptionDocument, options);
      }
export type CompanyCancelSubscriptionMutationHookResult = ReturnType<typeof useCompanyCancelSubscriptionMutation>;
export type CompanyCancelSubscriptionMutationResult = Apollo.MutationResult<CompanyCancelSubscriptionMutation>;
export type CompanyCancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CompanyCancelSubscriptionMutation, CompanyCancelSubscriptionMutationVariables>;
export const CompanyRequestUpgradeDocument = gql`
    mutation CompanyRequestUpgrade($searchPool: SearchPoolFilterEnum, $planUpgradeTrigger: CompanyPlanUpgradeTriggerEnum, $targetTier: TierEnum) {
  companyRequestUpgrade(
    searchPool: $searchPool
    planUpgradeTrigger: $planUpgradeTrigger
    targetTier: $targetTier
  ) {
    ok
  }
}
    `;
export type CompanyRequestUpgradeMutationFn = Apollo.MutationFunction<CompanyRequestUpgradeMutation, CompanyRequestUpgradeMutationVariables>;

/**
 * __useCompanyRequestUpgradeMutation__
 *
 * To run a mutation, you first call `useCompanyRequestUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRequestUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRequestUpgradeMutation, { data, loading, error }] = useCompanyRequestUpgradeMutation({
 *   variables: {
 *      searchPool: // value for 'searchPool'
 *      planUpgradeTrigger: // value for 'planUpgradeTrigger'
 *      targetTier: // value for 'targetTier'
 *   },
 * });
 */
export function useCompanyRequestUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<CompanyRequestUpgradeMutation, CompanyRequestUpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyRequestUpgradeMutation, CompanyRequestUpgradeMutationVariables>(CompanyRequestUpgradeDocument, options);
      }
export type CompanyRequestUpgradeMutationHookResult = ReturnType<typeof useCompanyRequestUpgradeMutation>;
export type CompanyRequestUpgradeMutationResult = Apollo.MutationResult<CompanyRequestUpgradeMutation>;
export type CompanyRequestUpgradeMutationOptions = Apollo.BaseMutationOptions<CompanyRequestUpgradeMutation, CompanyRequestUpgradeMutationVariables>;
export const CreateBidDocument = gql`
    mutation CreateBid($bidInputParams: BidInput!) {
  createBid(bidInputParams: $bidInputParams) {
    id
  }
}
    `;
export type CreateBidMutationFn = Apollo.MutationFunction<CreateBidMutation, CreateBidMutationVariables>;

/**
 * __useCreateBidMutation__
 *
 * To run a mutation, you first call `useCreateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBidMutation, { data, loading, error }] = useCreateBidMutation({
 *   variables: {
 *      bidInputParams: // value for 'bidInputParams'
 *   },
 * });
 */
export function useCreateBidMutation(baseOptions?: Apollo.MutationHookOptions<CreateBidMutation, CreateBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBidMutation, CreateBidMutationVariables>(CreateBidDocument, options);
      }
export type CreateBidMutationHookResult = ReturnType<typeof useCreateBidMutation>;
export type CreateBidMutationResult = Apollo.MutationResult<CreateBidMutation>;
export type CreateBidMutationOptions = Apollo.BaseMutationOptions<CreateBidMutation, CreateBidMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($noteInputParams: NoteInput!) {
  createNote(noteInputParams: $noteInputParams) {
    id
    note
    actorName
    excluded
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      noteInputParams: // value for 'noteInputParams'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const CreateScreeningCandidateDocument = gql`
    mutation CreateScreeningCandidate($inputScreeningCandidate: ScreeningCandidateInput!) {
  createScreeningCandidate(inputScreeningCandidate: $inputScreeningCandidate) {
    ok
  }
}
    `;
export type CreateScreeningCandidateMutationFn = Apollo.MutationFunction<CreateScreeningCandidateMutation, CreateScreeningCandidateMutationVariables>;

/**
 * __useCreateScreeningCandidateMutation__
 *
 * To run a mutation, you first call `useCreateScreeningCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScreeningCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScreeningCandidateMutation, { data, loading, error }] = useCreateScreeningCandidateMutation({
 *   variables: {
 *      inputScreeningCandidate: // value for 'inputScreeningCandidate'
 *   },
 * });
 */
export function useCreateScreeningCandidateMutation(baseOptions?: Apollo.MutationHookOptions<CreateScreeningCandidateMutation, CreateScreeningCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScreeningCandidateMutation, CreateScreeningCandidateMutationVariables>(CreateScreeningCandidateDocument, options);
      }
export type CreateScreeningCandidateMutationHookResult = ReturnType<typeof useCreateScreeningCandidateMutation>;
export type CreateScreeningCandidateMutationResult = Apollo.MutationResult<CreateScreeningCandidateMutation>;
export type CreateScreeningCandidateMutationOptions = Apollo.BaseMutationOptions<CreateScreeningCandidateMutation, CreateScreeningCandidateMutationVariables>;
export const DisableTwoFactorAuthenticationDocument = gql`
    mutation DisableTwoFactorAuthentication {
  disableTwoFactorAuthentication {
    ok
  }
}
    `;
export type DisableTwoFactorAuthenticationMutationFn = Apollo.MutationFunction<DisableTwoFactorAuthenticationMutation, DisableTwoFactorAuthenticationMutationVariables>;

/**
 * __useDisableTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useDisableTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTwoFactorAuthenticationMutation, { data, loading, error }] = useDisableTwoFactorAuthenticationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableTwoFactorAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<DisableTwoFactorAuthenticationMutation, DisableTwoFactorAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTwoFactorAuthenticationMutation, DisableTwoFactorAuthenticationMutationVariables>(DisableTwoFactorAuthenticationDocument, options);
      }
export type DisableTwoFactorAuthenticationMutationHookResult = ReturnType<typeof useDisableTwoFactorAuthenticationMutation>;
export type DisableTwoFactorAuthenticationMutationResult = Apollo.MutationResult<DisableTwoFactorAuthenticationMutation>;
export type DisableTwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<DisableTwoFactorAuthenticationMutation, DisableTwoFactorAuthenticationMutationVariables>;
export const EnableTwoFactorAuthenticationDocument = gql`
    mutation EnableTwoFactorAuthentication {
  enableTwoFactorAuthentication {
    ok
  }
}
    `;
export type EnableTwoFactorAuthenticationMutationFn = Apollo.MutationFunction<EnableTwoFactorAuthenticationMutation, EnableTwoFactorAuthenticationMutationVariables>;

/**
 * __useEnableTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useEnableTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTwoFactorAuthenticationMutation, { data, loading, error }] = useEnableTwoFactorAuthenticationMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnableTwoFactorAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<EnableTwoFactorAuthenticationMutation, EnableTwoFactorAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableTwoFactorAuthenticationMutation, EnableTwoFactorAuthenticationMutationVariables>(EnableTwoFactorAuthenticationDocument, options);
      }
export type EnableTwoFactorAuthenticationMutationHookResult = ReturnType<typeof useEnableTwoFactorAuthenticationMutation>;
export type EnableTwoFactorAuthenticationMutationResult = Apollo.MutationResult<EnableTwoFactorAuthenticationMutation>;
export type EnableTwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<EnableTwoFactorAuthenticationMutation, EnableTwoFactorAuthenticationMutationVariables>;
export const GenerateTwoFactorAuthenticationSecretDocument = gql`
    mutation GenerateTwoFactorAuthenticationSecret {
  generateTwoFactorAuthenticationSecret {
    ok
  }
}
    `;
export type GenerateTwoFactorAuthenticationSecretMutationFn = Apollo.MutationFunction<GenerateTwoFactorAuthenticationSecretMutation, GenerateTwoFactorAuthenticationSecretMutationVariables>;

/**
 * __useGenerateTwoFactorAuthenticationSecretMutation__
 *
 * To run a mutation, you first call `useGenerateTwoFactorAuthenticationSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTwoFactorAuthenticationSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTwoFactorAuthenticationSecretMutation, { data, loading, error }] = useGenerateTwoFactorAuthenticationSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateTwoFactorAuthenticationSecretMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTwoFactorAuthenticationSecretMutation, GenerateTwoFactorAuthenticationSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTwoFactorAuthenticationSecretMutation, GenerateTwoFactorAuthenticationSecretMutationVariables>(GenerateTwoFactorAuthenticationSecretDocument, options);
      }
export type GenerateTwoFactorAuthenticationSecretMutationHookResult = ReturnType<typeof useGenerateTwoFactorAuthenticationSecretMutation>;
export type GenerateTwoFactorAuthenticationSecretMutationResult = Apollo.MutationResult<GenerateTwoFactorAuthenticationSecretMutation>;
export type GenerateTwoFactorAuthenticationSecretMutationOptions = Apollo.BaseMutationOptions<GenerateTwoFactorAuthenticationSecretMutation, GenerateTwoFactorAuthenticationSecretMutationVariables>;
export const RejectCandidateInterestJobDocument = gql`
    mutation RejectCandidateInterestJob($id: ID!) {
  rejectCandidateInterestJob(id: $id) {
    success
  }
}
    `;
export type RejectCandidateInterestJobMutationFn = Apollo.MutationFunction<RejectCandidateInterestJobMutation, RejectCandidateInterestJobMutationVariables>;

/**
 * __useRejectCandidateInterestJobMutation__
 *
 * To run a mutation, you first call `useRejectCandidateInterestJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCandidateInterestJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCandidateInterestJobMutation, { data, loading, error }] = useRejectCandidateInterestJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectCandidateInterestJobMutation(baseOptions?: Apollo.MutationHookOptions<RejectCandidateInterestJobMutation, RejectCandidateInterestJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCandidateInterestJobMutation, RejectCandidateInterestJobMutationVariables>(RejectCandidateInterestJobDocument, options);
      }
export type RejectCandidateInterestJobMutationHookResult = ReturnType<typeof useRejectCandidateInterestJobMutation>;
export type RejectCandidateInterestJobMutationResult = Apollo.MutationResult<RejectCandidateInterestJobMutation>;
export type RejectCandidateInterestJobMutationOptions = Apollo.BaseMutationOptions<RejectCandidateInterestJobMutation, RejectCandidateInterestJobMutationVariables>;
export const ScheduleDemonstrationDocument = gql`
    mutation ScheduleDemonstration($id: Int!, $notifySales: Boolean!, $preferredContactForm: String, $type: ScheduleTypeEnum) {
  scheduleDemonstration(
    id: $id
    notifySales: $notifySales
    preferredContactForm: $preferredContactForm
    type: $type
  ) {
    company {
      id
    }
  }
}
    `;
export type ScheduleDemonstrationMutationFn = Apollo.MutationFunction<ScheduleDemonstrationMutation, ScheduleDemonstrationMutationVariables>;

/**
 * __useScheduleDemonstrationMutation__
 *
 * To run a mutation, you first call `useScheduleDemonstrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleDemonstrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleDemonstrationMutation, { data, loading, error }] = useScheduleDemonstrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notifySales: // value for 'notifySales'
 *      preferredContactForm: // value for 'preferredContactForm'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useScheduleDemonstrationMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleDemonstrationMutation, ScheduleDemonstrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleDemonstrationMutation, ScheduleDemonstrationMutationVariables>(ScheduleDemonstrationDocument, options);
      }
export type ScheduleDemonstrationMutationHookResult = ReturnType<typeof useScheduleDemonstrationMutation>;
export type ScheduleDemonstrationMutationResult = Apollo.MutationResult<ScheduleDemonstrationMutation>;
export type ScheduleDemonstrationMutationOptions = Apollo.BaseMutationOptions<ScheduleDemonstrationMutation, ScheduleDemonstrationMutationVariables>;
export const SendUserEmailConfirmationInstructionsDocument = gql`
    mutation SendUserEmailConfirmationInstructions {
  sendCompanyUserEmailConfirmationInstructions {
    ok
  }
}
    `;
export type SendUserEmailConfirmationInstructionsMutationFn = Apollo.MutationFunction<SendUserEmailConfirmationInstructionsMutation, SendUserEmailConfirmationInstructionsMutationVariables>;

/**
 * __useSendUserEmailConfirmationInstructionsMutation__
 *
 * To run a mutation, you first call `useSendUserEmailConfirmationInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserEmailConfirmationInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserEmailConfirmationInstructionsMutation, { data, loading, error }] = useSendUserEmailConfirmationInstructionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendUserEmailConfirmationInstructionsMutation(baseOptions?: Apollo.MutationHookOptions<SendUserEmailConfirmationInstructionsMutation, SendUserEmailConfirmationInstructionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUserEmailConfirmationInstructionsMutation, SendUserEmailConfirmationInstructionsMutationVariables>(SendUserEmailConfirmationInstructionsDocument, options);
      }
export type SendUserEmailConfirmationInstructionsMutationHookResult = ReturnType<typeof useSendUserEmailConfirmationInstructionsMutation>;
export type SendUserEmailConfirmationInstructionsMutationResult = Apollo.MutationResult<SendUserEmailConfirmationInstructionsMutation>;
export type SendUserEmailConfirmationInstructionsMutationOptions = Apollo.BaseMutationOptions<SendUserEmailConfirmationInstructionsMutation, SendUserEmailConfirmationInstructionsMutationVariables>;
export const ToggleFavoriteCandidateDocument = gql`
    mutation ToggleFavoriteCandidate($candidateId: Int!, $companyUserId: Int!) {
  toggleFavoriteCandidate(
    candidateId: $candidateId
    companyUserId: $companyUserId
  ) {
    isFavorite
  }
}
    `;
export type ToggleFavoriteCandidateMutationFn = Apollo.MutationFunction<ToggleFavoriteCandidateMutation, ToggleFavoriteCandidateMutationVariables>;

/**
 * __useToggleFavoriteCandidateMutation__
 *
 * To run a mutation, you first call `useToggleFavoriteCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFavoriteCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFavoriteCandidateMutation, { data, loading, error }] = useToggleFavoriteCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      companyUserId: // value for 'companyUserId'
 *   },
 * });
 */
export function useToggleFavoriteCandidateMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFavoriteCandidateMutation, ToggleFavoriteCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFavoriteCandidateMutation, ToggleFavoriteCandidateMutationVariables>(ToggleFavoriteCandidateDocument, options);
      }
export type ToggleFavoriteCandidateMutationHookResult = ReturnType<typeof useToggleFavoriteCandidateMutation>;
export type ToggleFavoriteCandidateMutationResult = Apollo.MutationResult<ToggleFavoriteCandidateMutation>;
export type ToggleFavoriteCandidateMutationOptions = Apollo.BaseMutationOptions<ToggleFavoriteCandidateMutation, ToggleFavoriteCandidateMutationVariables>;
export const ToggleHiddenCandidateDocument = gql`
    mutation ToggleHiddenCandidate($candidateId: Int!, $companyUserId: Int!) {
  toggleHiddenCandidate(candidateId: $candidateId, companyUserId: $companyUserId) {
    isHidden
  }
}
    `;
export type ToggleHiddenCandidateMutationFn = Apollo.MutationFunction<ToggleHiddenCandidateMutation, ToggleHiddenCandidateMutationVariables>;

/**
 * __useToggleHiddenCandidateMutation__
 *
 * To run a mutation, you first call `useToggleHiddenCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleHiddenCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleHiddenCandidateMutation, { data, loading, error }] = useToggleHiddenCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      companyUserId: // value for 'companyUserId'
 *   },
 * });
 */
export function useToggleHiddenCandidateMutation(baseOptions?: Apollo.MutationHookOptions<ToggleHiddenCandidateMutation, ToggleHiddenCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleHiddenCandidateMutation, ToggleHiddenCandidateMutationVariables>(ToggleHiddenCandidateDocument, options);
      }
export type ToggleHiddenCandidateMutationHookResult = ReturnType<typeof useToggleHiddenCandidateMutation>;
export type ToggleHiddenCandidateMutationResult = Apollo.MutationResult<ToggleHiddenCandidateMutation>;
export type ToggleHiddenCandidateMutationOptions = Apollo.BaseMutationOptions<ToggleHiddenCandidateMutation, ToggleHiddenCandidateMutationVariables>;
export const UpdateCompanyCandidateSearchResultClickedDocument = gql`
    mutation UpdateCompanyCandidateSearchResultClicked($searchId: ID!, $candidateId: ID!) {
  updateCompanyCandidateSearchResultClicked(
    searchId: $searchId
    candidateId: $candidateId
  )
}
    `;
export type UpdateCompanyCandidateSearchResultClickedMutationFn = Apollo.MutationFunction<UpdateCompanyCandidateSearchResultClickedMutation, UpdateCompanyCandidateSearchResultClickedMutationVariables>;

/**
 * __useUpdateCompanyCandidateSearchResultClickedMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCandidateSearchResultClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCandidateSearchResultClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCandidateSearchResultClickedMutation, { data, loading, error }] = useUpdateCompanyCandidateSearchResultClickedMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useUpdateCompanyCandidateSearchResultClickedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyCandidateSearchResultClickedMutation, UpdateCompanyCandidateSearchResultClickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyCandidateSearchResultClickedMutation, UpdateCompanyCandidateSearchResultClickedMutationVariables>(UpdateCompanyCandidateSearchResultClickedDocument, options);
      }
export type UpdateCompanyCandidateSearchResultClickedMutationHookResult = ReturnType<typeof useUpdateCompanyCandidateSearchResultClickedMutation>;
export type UpdateCompanyCandidateSearchResultClickedMutationResult = Apollo.MutationResult<UpdateCompanyCandidateSearchResultClickedMutation>;
export type UpdateCompanyCandidateSearchResultClickedMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyCandidateSearchResultClickedMutation, UpdateCompanyCandidateSearchResultClickedMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation UpdateNote($noteUpdateParams: NoteUpdate!) {
  updateNote(noteUpdateParams: $noteUpdateParams) {
    note
    excluded
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      noteUpdateParams: // value for 'noteUpdateParams'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const UpdatePartnerIntegrationDocument = gql`
    mutation UpdatePartnerIntegration($id: ID!, $params: UpdatePartnerIntegration!) {
  updatePartnerIntegration(id: $id, params: $params) {
    id
    domain
    token
  }
}
    `;
export type UpdatePartnerIntegrationMutationFn = Apollo.MutationFunction<UpdatePartnerIntegrationMutation, UpdatePartnerIntegrationMutationVariables>;

/**
 * __useUpdatePartnerIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerIntegrationMutation, { data, loading, error }] = useUpdatePartnerIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdatePartnerIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerIntegrationMutation, UpdatePartnerIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerIntegrationMutation, UpdatePartnerIntegrationMutationVariables>(UpdatePartnerIntegrationDocument, options);
      }
export type UpdatePartnerIntegrationMutationHookResult = ReturnType<typeof useUpdatePartnerIntegrationMutation>;
export type UpdatePartnerIntegrationMutationResult = Apollo.MutationResult<UpdatePartnerIntegrationMutation>;
export type UpdatePartnerIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerIntegrationMutation, UpdatePartnerIntegrationMutationVariables>;
export const CreateCompanyUserDocument = gql`
    mutation CreateCompanyUser($params: CreateOrUpdateCompanyUserInput!) {
  createCompanyUser(params: $params) {
    ok
  }
}
    `;
export type CreateCompanyUserMutationFn = Apollo.MutationFunction<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>;

/**
 * __useCreateCompanyUserMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUserMutation, { data, loading, error }] = useCreateCompanyUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>(CreateCompanyUserDocument, options);
      }
export type CreateCompanyUserMutationHookResult = ReturnType<typeof useCreateCompanyUserMutation>;
export type CreateCompanyUserMutationResult = Apollo.MutationResult<CreateCompanyUserMutation>;
export type CreateCompanyUserMutationOptions = Apollo.BaseMutationOptions<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>;
export const CreateCompanyUserActivityLogDocument = gql`
    mutation CreateCompanyUserActivityLog($eventType: CompanyUserActivityLogEventTypeEnum!) {
  createCompanyUserActivityLog(eventType: $eventType) {
    id
  }
}
    `;
export type CreateCompanyUserActivityLogMutationFn = Apollo.MutationFunction<CreateCompanyUserActivityLogMutation, CreateCompanyUserActivityLogMutationVariables>;

/**
 * __useCreateCompanyUserActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUserActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUserActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUserActivityLogMutation, { data, loading, error }] = useCreateCompanyUserActivityLogMutation({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useCreateCompanyUserActivityLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyUserActivityLogMutation, CreateCompanyUserActivityLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyUserActivityLogMutation, CreateCompanyUserActivityLogMutationVariables>(CreateCompanyUserActivityLogDocument, options);
      }
export type CreateCompanyUserActivityLogMutationHookResult = ReturnType<typeof useCreateCompanyUserActivityLogMutation>;
export type CreateCompanyUserActivityLogMutationResult = Apollo.MutationResult<CreateCompanyUserActivityLogMutation>;
export type CreateCompanyUserActivityLogMutationOptions = Apollo.BaseMutationOptions<CreateCompanyUserActivityLogMutation, CreateCompanyUserActivityLogMutationVariables>;
export const SwitchCompanyUserLanguageDocument = gql`
    mutation SwitchCompanyUserLanguage($language: String!) {
  switchCompanyUserLanguage(language: $language) {
    language
  }
}
    `;
export type SwitchCompanyUserLanguageMutationFn = Apollo.MutationFunction<SwitchCompanyUserLanguageMutation, SwitchCompanyUserLanguageMutationVariables>;

/**
 * __useSwitchCompanyUserLanguageMutation__
 *
 * To run a mutation, you first call `useSwitchCompanyUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchCompanyUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchCompanyUserLanguageMutation, { data, loading, error }] = useSwitchCompanyUserLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSwitchCompanyUserLanguageMutation(baseOptions?: Apollo.MutationHookOptions<SwitchCompanyUserLanguageMutation, SwitchCompanyUserLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchCompanyUserLanguageMutation, SwitchCompanyUserLanguageMutationVariables>(SwitchCompanyUserLanguageDocument, options);
      }
export type SwitchCompanyUserLanguageMutationHookResult = ReturnType<typeof useSwitchCompanyUserLanguageMutation>;
export type SwitchCompanyUserLanguageMutationResult = Apollo.MutationResult<SwitchCompanyUserLanguageMutation>;
export type SwitchCompanyUserLanguageMutationOptions = Apollo.BaseMutationOptions<SwitchCompanyUserLanguageMutation, SwitchCompanyUserLanguageMutationVariables>;
export const ToggleActiveDocument = gql`
    mutation ToggleActive($params: CompanyUserToggleActiveInput!) {
  toggleActive(params: $params) {
    ok
  }
}
    `;
export type ToggleActiveMutationFn = Apollo.MutationFunction<ToggleActiveMutation, ToggleActiveMutationVariables>;

/**
 * __useToggleActiveMutation__
 *
 * To run a mutation, you first call `useToggleActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveMutation, { data, loading, error }] = useToggleActiveMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useToggleActiveMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveMutation, ToggleActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveMutation, ToggleActiveMutationVariables>(ToggleActiveDocument, options);
      }
export type ToggleActiveMutationHookResult = ReturnType<typeof useToggleActiveMutation>;
export type ToggleActiveMutationResult = Apollo.MutationResult<ToggleActiveMutation>;
export type ToggleActiveMutationOptions = Apollo.BaseMutationOptions<ToggleActiveMutation, ToggleActiveMutationVariables>;
export const UpdateCompanyUserDocument = gql`
    mutation UpdateCompanyUser($id: ID!, $params: CreateOrUpdateCompanyUserInput!) {
  updateCompanyUser(id: $id, params: $params) {
    ok
  }
}
    `;
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>(UpdateCompanyUserDocument, options);
      }
export type UpdateCompanyUserMutationHookResult = ReturnType<typeof useUpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;
export const CreateCurrentCandidateInterestJobDocument = gql`
    mutation CreateCurrentCandidateInterestJob($jobId: Int!, $interestType: String!) {
  createCurrentCandidateInterestJob(
    params: {jobId: $jobId, interestType: $interestType}
  ) {
    candidateInterestJob {
      id
      interestType
      candidateId
      job {
        id
      }
    }
  }
}
    `;
export type CreateCurrentCandidateInterestJobMutationFn = Apollo.MutationFunction<CreateCurrentCandidateInterestJobMutation, CreateCurrentCandidateInterestJobMutationVariables>;

/**
 * __useCreateCurrentCandidateInterestJobMutation__
 *
 * To run a mutation, you first call `useCreateCurrentCandidateInterestJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentCandidateInterestJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentCandidateInterestJobMutation, { data, loading, error }] = useCreateCurrentCandidateInterestJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      interestType: // value for 'interestType'
 *   },
 * });
 */
export function useCreateCurrentCandidateInterestJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentCandidateInterestJobMutation, CreateCurrentCandidateInterestJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentCandidateInterestJobMutation, CreateCurrentCandidateInterestJobMutationVariables>(CreateCurrentCandidateInterestJobDocument, options);
      }
export type CreateCurrentCandidateInterestJobMutationHookResult = ReturnType<typeof useCreateCurrentCandidateInterestJobMutation>;
export type CreateCurrentCandidateInterestJobMutationResult = Apollo.MutationResult<CreateCurrentCandidateInterestJobMutation>;
export type CreateCurrentCandidateInterestJobMutationOptions = Apollo.BaseMutationOptions<CreateCurrentCandidateInterestJobMutation, CreateCurrentCandidateInterestJobMutationVariables>;
export const CheckCandidateIsRegisteredDocument = gql`
    query CheckCandidateIsRegistered($email: String!) {
  checkCandidateIsRegistered(candidateEmail: $email) {
    isRegistered
  }
}
    `;

/**
 * __useCheckCandidateIsRegisteredQuery__
 *
 * To run a query within a React component, call `useCheckCandidateIsRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCandidateIsRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCandidateIsRegisteredQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckCandidateIsRegisteredQuery(baseOptions: Apollo.QueryHookOptions<CheckCandidateIsRegisteredQuery, CheckCandidateIsRegisteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCandidateIsRegisteredQuery, CheckCandidateIsRegisteredQueryVariables>(CheckCandidateIsRegisteredDocument, options);
      }
export function useCheckCandidateIsRegisteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCandidateIsRegisteredQuery, CheckCandidateIsRegisteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCandidateIsRegisteredQuery, CheckCandidateIsRegisteredQueryVariables>(CheckCandidateIsRegisteredDocument, options);
        }
export type CheckCandidateIsRegisteredQueryHookResult = ReturnType<typeof useCheckCandidateIsRegisteredQuery>;
export type CheckCandidateIsRegisteredLazyQueryHookResult = ReturnType<typeof useCheckCandidateIsRegisteredLazyQuery>;
export type CheckCandidateIsRegisteredQueryResult = Apollo.QueryResult<CheckCandidateIsRegisteredQuery, CheckCandidateIsRegisteredQueryVariables>;
export const GetCandidateProfileDataDocument = gql`
    query GetCandidateProfileData {
  getCandidateProfileData {
    candidate {
      id
      image
      linkedInUrl
      searchStatus
      workRemote
      workedUsingOnlyEnglish
      isDashboardFirstAccess
      candidateCities {
        city {
          id
          name
        }
        order
      }
      cltSalary
      pjSalary
      usdSalary
      candidateFocus {
        order
        experienceLevel {
          value
        }
        focus {
          id
          description
          career {
            name
            id
            slug
            worksWithSkills
          }
        }
      }
      skills {
        id
        level {
          value
        }
        mainTech
        order
        technology {
          id
          name
        }
      }
      candidateLanguages {
        language {
          id
          name
        }
        assessmentLevel
        readingLevel {
          value
        }
        talkingLevel {
          value
        }
        writingLevel {
          value
        }
        updatedAt
      }
      noExperiences
      experiences {
        company
        currentJob
        description
        endDate
        id
        jobTitle
        startDate
        skills {
          id
          level {
            value
          }
          mainTech
          order
          technology {
            id
            name
          }
        }
      }
      educations {
        course
        educationStatus
        endYear
        id
        institution
        startYear
        title {
          value
        }
      }
      certifications {
        current
        endDate
        id
        institution
        startDate
        title
        url
      }
      projects {
        current
        description
        endDate
        id
        name
        startDate
        url
      }
      positionTitle
      summary
      candidateExcludedCompanies {
        id
        companyAlias {
          id
          name
        }
      }
      image
      name
      email
      phone
      city {
        id
        name
      }
      gender
      specialNecessity
      stackoverflowUrl
      githubUrl
      allowWhatsappNotification
      hiringOption
      available
      situation
      portfolioUrl
      portfolioExtraUrl
      videoAskCompleted
      eligibleToInternationalJobs
      techCareer
    }
  }
}
    `;

/**
 * __useGetCandidateProfileDataQuery__
 *
 * To run a query within a React component, call `useGetCandidateProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCandidateProfileDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidateProfileDataQuery, GetCandidateProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateProfileDataQuery, GetCandidateProfileDataQueryVariables>(GetCandidateProfileDataDocument, options);
      }
export function useGetCandidateProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateProfileDataQuery, GetCandidateProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateProfileDataQuery, GetCandidateProfileDataQueryVariables>(GetCandidateProfileDataDocument, options);
        }
export type GetCandidateProfileDataQueryHookResult = ReturnType<typeof useGetCandidateProfileDataQuery>;
export type GetCandidateProfileDataLazyQueryHookResult = ReturnType<typeof useGetCandidateProfileDataLazyQuery>;
export type GetCandidateProfileDataQueryResult = Apollo.QueryResult<GetCandidateProfileDataQuery, GetCandidateProfileDataQueryVariables>;
export const GetCurrentCandidateAvailableDocument = gql`
    query GetCurrentCandidateAvailable {
  getCurrentCandidate {
    id
    available
    searchStatus
  }
}
    `;

/**
 * __useGetCurrentCandidateAvailableQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateAvailableQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateAvailableQuery, GetCurrentCandidateAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateAvailableQuery, GetCurrentCandidateAvailableQueryVariables>(GetCurrentCandidateAvailableDocument, options);
      }
export function useGetCurrentCandidateAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateAvailableQuery, GetCurrentCandidateAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateAvailableQuery, GetCurrentCandidateAvailableQueryVariables>(GetCurrentCandidateAvailableDocument, options);
        }
export type GetCurrentCandidateAvailableQueryHookResult = ReturnType<typeof useGetCurrentCandidateAvailableQuery>;
export type GetCurrentCandidateAvailableLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateAvailableLazyQuery>;
export type GetCurrentCandidateAvailableQueryResult = Apollo.QueryResult<GetCurrentCandidateAvailableQuery, GetCurrentCandidateAvailableQueryVariables>;
export const GetCurrentCandidateBasicInfoDocument = gql`
    query GetCurrentCandidateBasicInfo {
  getCurrentCandidate {
    id
    name
    email
    phone
    hiringOption
    isDashboardFirstAccess
  }
}
    `;

/**
 * __useGetCurrentCandidateBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateBasicInfoQuery, GetCurrentCandidateBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateBasicInfoQuery, GetCurrentCandidateBasicInfoQueryVariables>(GetCurrentCandidateBasicInfoDocument, options);
      }
export function useGetCurrentCandidateBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateBasicInfoQuery, GetCurrentCandidateBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateBasicInfoQuery, GetCurrentCandidateBasicInfoQueryVariables>(GetCurrentCandidateBasicInfoDocument, options);
        }
export type GetCurrentCandidateBasicInfoQueryHookResult = ReturnType<typeof useGetCurrentCandidateBasicInfoQuery>;
export type GetCurrentCandidateBasicInfoLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateBasicInfoLazyQuery>;
export type GetCurrentCandidateBasicInfoQueryResult = Apollo.QueryResult<GetCurrentCandidateBasicInfoQuery, GetCurrentCandidateBasicInfoQueryVariables>;
export const GetCurrentCandidateHiringDocument = gql`
    query GetCurrentCandidateHiring {
  getCurrentCandidateHiring {
    id
    company {
      id
      name
    }
    candidateSalary
    job {
      id
      title
      isInternational
    }
    createdAt
  }
}
    `;

/**
 * __useGetCurrentCandidateHiringQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateHiringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateHiringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateHiringQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateHiringQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateHiringQuery, GetCurrentCandidateHiringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateHiringQuery, GetCurrentCandidateHiringQueryVariables>(GetCurrentCandidateHiringDocument, options);
      }
export function useGetCurrentCandidateHiringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateHiringQuery, GetCurrentCandidateHiringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateHiringQuery, GetCurrentCandidateHiringQueryVariables>(GetCurrentCandidateHiringDocument, options);
        }
export type GetCurrentCandidateHiringQueryHookResult = ReturnType<typeof useGetCurrentCandidateHiringQuery>;
export type GetCurrentCandidateHiringLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateHiringLazyQuery>;
export type GetCurrentCandidateHiringQueryResult = Apollo.QueryResult<GetCurrentCandidateHiringQuery, GetCurrentCandidateHiringQueryVariables>;
export const GetCurrentCandidateImageDocument = gql`
    query GetCurrentCandidateImage {
  getCurrentCandidate {
    id
    image
  }
}
    `;

/**
 * __useGetCurrentCandidateImageQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateImageQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateImageQuery, GetCurrentCandidateImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateImageQuery, GetCurrentCandidateImageQueryVariables>(GetCurrentCandidateImageDocument, options);
      }
export function useGetCurrentCandidateImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateImageQuery, GetCurrentCandidateImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateImageQuery, GetCurrentCandidateImageQueryVariables>(GetCurrentCandidateImageDocument, options);
        }
export type GetCurrentCandidateImageQueryHookResult = ReturnType<typeof useGetCurrentCandidateImageQuery>;
export type GetCurrentCandidateImageLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateImageLazyQuery>;
export type GetCurrentCandidateImageQueryResult = Apollo.QueryResult<GetCurrentCandidateImageQuery, GetCurrentCandidateImageQueryVariables>;
export const GetCurrentCandidateInterestJobsByJobIdsDocument = gql`
    query GetCurrentCandidateInterestJobsByJobIds($jobs: [Int!]!) {
  getCurrentCandidateInterestJobsByJobIds(jobIds: $jobs) {
    id
    job {
      id
    }
  }
}
    `;

/**
 * __useGetCurrentCandidateInterestJobsByJobIdsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateInterestJobsByJobIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateInterestJobsByJobIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateInterestJobsByJobIdsQuery({
 *   variables: {
 *      jobs: // value for 'jobs'
 *   },
 * });
 */
export function useGetCurrentCandidateInterestJobsByJobIdsQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentCandidateInterestJobsByJobIdsQuery, GetCurrentCandidateInterestJobsByJobIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateInterestJobsByJobIdsQuery, GetCurrentCandidateInterestJobsByJobIdsQueryVariables>(GetCurrentCandidateInterestJobsByJobIdsDocument, options);
      }
export function useGetCurrentCandidateInterestJobsByJobIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateInterestJobsByJobIdsQuery, GetCurrentCandidateInterestJobsByJobIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateInterestJobsByJobIdsQuery, GetCurrentCandidateInterestJobsByJobIdsQueryVariables>(GetCurrentCandidateInterestJobsByJobIdsDocument, options);
        }
export type GetCurrentCandidateInterestJobsByJobIdsQueryHookResult = ReturnType<typeof useGetCurrentCandidateInterestJobsByJobIdsQuery>;
export type GetCurrentCandidateInterestJobsByJobIdsLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateInterestJobsByJobIdsLazyQuery>;
export type GetCurrentCandidateInterestJobsByJobIdsQueryResult = Apollo.QueryResult<GetCurrentCandidateInterestJobsByJobIdsQuery, GetCurrentCandidateInterestJobsByJobIdsQueryVariables>;
export const GetCurrentCandidateMainInfoDocument = gql`
    query GetCurrentCandidateMainInfo {
  getCurrentCandidate {
    id
    pjSalary
    cltSalary
    usdSalary
    workRemote
    image
    hiringOption
    situation
    candidateCities {
      order
      city {
        id
        name
      }
    }
    skills {
      id
      mainTech
      technology {
        id
        name
      }
    }
    candidateLanguages {
      readingLevel {
        value
      }
      talkingLevel {
        value
      }
      writingLevel {
        value
      }
      language {
        id
        name
      }
    }
    candidateFocus {
      focus {
        career {
          name
          id
          slug
          worksWithSkills
        }
      }
    }
    videoAskCompleted
    eligibleToInternationalJobs
  }
}
    `;

/**
 * __useGetCurrentCandidateMainInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateMainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateMainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateMainInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateMainInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateMainInfoQuery, GetCurrentCandidateMainInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateMainInfoQuery, GetCurrentCandidateMainInfoQueryVariables>(GetCurrentCandidateMainInfoDocument, options);
      }
export function useGetCurrentCandidateMainInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateMainInfoQuery, GetCurrentCandidateMainInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateMainInfoQuery, GetCurrentCandidateMainInfoQueryVariables>(GetCurrentCandidateMainInfoDocument, options);
        }
export type GetCurrentCandidateMainInfoQueryHookResult = ReturnType<typeof useGetCurrentCandidateMainInfoQuery>;
export type GetCurrentCandidateMainInfoLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateMainInfoLazyQuery>;
export type GetCurrentCandidateMainInfoQueryResult = Apollo.QueryResult<GetCurrentCandidateMainInfoQuery, GetCurrentCandidateMainInfoQueryVariables>;
export const GetCurrentCandidateMd5EmailDocument = gql`
    query GetCurrentCandidateMd5Email {
  getCurrentCandidate {
    id
    md5Email
  }
}
    `;

/**
 * __useGetCurrentCandidateMd5EmailQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateMd5EmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateMd5EmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateMd5EmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateMd5EmailQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateMd5EmailQuery, GetCurrentCandidateMd5EmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateMd5EmailQuery, GetCurrentCandidateMd5EmailQueryVariables>(GetCurrentCandidateMd5EmailDocument, options);
      }
export function useGetCurrentCandidateMd5EmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateMd5EmailQuery, GetCurrentCandidateMd5EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateMd5EmailQuery, GetCurrentCandidateMd5EmailQueryVariables>(GetCurrentCandidateMd5EmailDocument, options);
        }
export type GetCurrentCandidateMd5EmailQueryHookResult = ReturnType<typeof useGetCurrentCandidateMd5EmailQuery>;
export type GetCurrentCandidateMd5EmailLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateMd5EmailLazyQuery>;
export type GetCurrentCandidateMd5EmailQueryResult = Apollo.QueryResult<GetCurrentCandidateMd5EmailQuery, GetCurrentCandidateMd5EmailQueryVariables>;
export const GetCurrentCandidatePendingBidsDocument = gql`
    query GetCurrentCandidatePendingBids {
  getCurrentCandidatePendingBids {
    id
    createdAt
    company {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentCandidatePendingBidsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidatePendingBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidatePendingBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidatePendingBidsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidatePendingBidsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidatePendingBidsQuery, GetCurrentCandidatePendingBidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidatePendingBidsQuery, GetCurrentCandidatePendingBidsQueryVariables>(GetCurrentCandidatePendingBidsDocument, options);
      }
export function useGetCurrentCandidatePendingBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidatePendingBidsQuery, GetCurrentCandidatePendingBidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidatePendingBidsQuery, GetCurrentCandidatePendingBidsQueryVariables>(GetCurrentCandidatePendingBidsDocument, options);
        }
export type GetCurrentCandidatePendingBidsQueryHookResult = ReturnType<typeof useGetCurrentCandidatePendingBidsQuery>;
export type GetCurrentCandidatePendingBidsLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidatePendingBidsLazyQuery>;
export type GetCurrentCandidatePendingBidsQueryResult = Apollo.QueryResult<GetCurrentCandidatePendingBidsQuery, GetCurrentCandidatePendingBidsQueryVariables>;
export const GetCurrentCandidatePendingInterviewsDocument = gql`
    query GetCurrentCandidatePendingInterviews {
  getCurrentCandidatePendingInterviews {
    id
    start
    end
    eventType
    company {
      id
      name
    }
    companyUser {
      id
      name
    }
    bid {
      id
    }
  }
}
    `;

/**
 * __useGetCurrentCandidatePendingInterviewsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidatePendingInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidatePendingInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidatePendingInterviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidatePendingInterviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidatePendingInterviewsQuery, GetCurrentCandidatePendingInterviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidatePendingInterviewsQuery, GetCurrentCandidatePendingInterviewsQueryVariables>(GetCurrentCandidatePendingInterviewsDocument, options);
      }
export function useGetCurrentCandidatePendingInterviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidatePendingInterviewsQuery, GetCurrentCandidatePendingInterviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidatePendingInterviewsQuery, GetCurrentCandidatePendingInterviewsQueryVariables>(GetCurrentCandidatePendingInterviewsDocument, options);
        }
export type GetCurrentCandidatePendingInterviewsQueryHookResult = ReturnType<typeof useGetCurrentCandidatePendingInterviewsQuery>;
export type GetCurrentCandidatePendingInterviewsLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidatePendingInterviewsLazyQuery>;
export type GetCurrentCandidatePendingInterviewsQueryResult = Apollo.QueryResult<GetCurrentCandidatePendingInterviewsQuery, GetCurrentCandidatePendingInterviewsQueryVariables>;
export const GetCurrentCandidateUnreadMessagesDocument = gql`
    query GetCurrentCandidateUnreadMessages {
  getCurrentCandidateUnreadMessages {
    id
    company {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentCandidateUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateUnreadMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateUnreadMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateUnreadMessagesQuery, GetCurrentCandidateUnreadMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateUnreadMessagesQuery, GetCurrentCandidateUnreadMessagesQueryVariables>(GetCurrentCandidateUnreadMessagesDocument, options);
      }
export function useGetCurrentCandidateUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateUnreadMessagesQuery, GetCurrentCandidateUnreadMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateUnreadMessagesQuery, GetCurrentCandidateUnreadMessagesQueryVariables>(GetCurrentCandidateUnreadMessagesDocument, options);
        }
export type GetCurrentCandidateUnreadMessagesQueryHookResult = ReturnType<typeof useGetCurrentCandidateUnreadMessagesQuery>;
export type GetCurrentCandidateUnreadMessagesLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateUnreadMessagesLazyQuery>;
export type GetCurrentCandidateUnreadMessagesQueryResult = Apollo.QueryResult<GetCurrentCandidateUnreadMessagesQuery, GetCurrentCandidateUnreadMessagesQueryVariables>;
export const GetCurrentCandidateWhatsAppNotificationStatusDocument = gql`
    query GetCurrentCandidateWhatsAppNotificationStatus {
  getCurrentCandidate {
    id
    allowWhatsappNotification
  }
}
    `;

/**
 * __useGetCurrentCandidateWhatsAppNotificationStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentCandidateWhatsAppNotificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCandidateWhatsAppNotificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCandidateWhatsAppNotificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCandidateWhatsAppNotificationStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCandidateWhatsAppNotificationStatusQuery, GetCurrentCandidateWhatsAppNotificationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCandidateWhatsAppNotificationStatusQuery, GetCurrentCandidateWhatsAppNotificationStatusQueryVariables>(GetCurrentCandidateWhatsAppNotificationStatusDocument, options);
      }
export function useGetCurrentCandidateWhatsAppNotificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCandidateWhatsAppNotificationStatusQuery, GetCurrentCandidateWhatsAppNotificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCandidateWhatsAppNotificationStatusQuery, GetCurrentCandidateWhatsAppNotificationStatusQueryVariables>(GetCurrentCandidateWhatsAppNotificationStatusDocument, options);
        }
export type GetCurrentCandidateWhatsAppNotificationStatusQueryHookResult = ReturnType<typeof useGetCurrentCandidateWhatsAppNotificationStatusQuery>;
export type GetCurrentCandidateWhatsAppNotificationStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentCandidateWhatsAppNotificationStatusLazyQuery>;
export type GetCurrentCandidateWhatsAppNotificationStatusQueryResult = Apollo.QueryResult<GetCurrentCandidateWhatsAppNotificationStatusQuery, GetCurrentCandidateWhatsAppNotificationStatusQueryVariables>;
export const GetFocusSuggestionsDocument = gql`
    query GetFocusSuggestions($careerSlug: String, $onlyActiveJobs: Boolean) {
  getFocusSuggestions(careerSlug: $careerSlug, onlyActiveJobs: $onlyActiveJobs) {
    focus {
      id
      description
      career {
        worksWithSkills
      }
    }
  }
}
    `;

/**
 * __useGetFocusSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetFocusSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFocusSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFocusSuggestionsQuery({
 *   variables: {
 *      careerSlug: // value for 'careerSlug'
 *      onlyActiveJobs: // value for 'onlyActiveJobs'
 *   },
 * });
 */
export function useGetFocusSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetFocusSuggestionsQuery, GetFocusSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFocusSuggestionsQuery, GetFocusSuggestionsQueryVariables>(GetFocusSuggestionsDocument, options);
      }
export function useGetFocusSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFocusSuggestionsQuery, GetFocusSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFocusSuggestionsQuery, GetFocusSuggestionsQueryVariables>(GetFocusSuggestionsDocument, options);
        }
export type GetFocusSuggestionsQueryHookResult = ReturnType<typeof useGetFocusSuggestionsQuery>;
export type GetFocusSuggestionsLazyQueryHookResult = ReturnType<typeof useGetFocusSuggestionsLazyQuery>;
export type GetFocusSuggestionsQueryResult = Apollo.QueryResult<GetFocusSuggestionsQuery, GetFocusSuggestionsQueryVariables>;
export const GetRegisterSuggestionsDocument = gql`
    query GetRegisterSuggestions($careerSlug: CareerSlugEnum) {
  getRegisterSuggestions(careerSlug: $careerSlug) {
    cities {
      name
      id
    }
    techs {
      name
      id
    }
  }
}
    `;

/**
 * __useGetRegisterSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetRegisterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisterSuggestionsQuery({
 *   variables: {
 *      careerSlug: // value for 'careerSlug'
 *   },
 * });
 */
export function useGetRegisterSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegisterSuggestionsQuery, GetRegisterSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisterSuggestionsQuery, GetRegisterSuggestionsQueryVariables>(GetRegisterSuggestionsDocument, options);
      }
export function useGetRegisterSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisterSuggestionsQuery, GetRegisterSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisterSuggestionsQuery, GetRegisterSuggestionsQueryVariables>(GetRegisterSuggestionsDocument, options);
        }
export type GetRegisterSuggestionsQueryHookResult = ReturnType<typeof useGetRegisterSuggestionsQuery>;
export type GetRegisterSuggestionsLazyQueryHookResult = ReturnType<typeof useGetRegisterSuggestionsLazyQuery>;
export type GetRegisterSuggestionsQueryResult = Apollo.QueryResult<GetRegisterSuggestionsQuery, GetRegisterSuggestionsQueryVariables>;
export const ImportLinkedinProfileDocument = gql`
    query ImportLinkedinProfile($linkedinProfileUrl: String!) {
  importLinkedinProfile(linkedinProfileUrl: $linkedinProfileUrl) {
    fullName
    occupation
    headline
    summary
    experiences {
      company
      title
      description
      location
      startsAt {
        month
        year
      }
      endsAt {
        month
        year
      }
    }
    education {
      startsAt {
        month
        year
      }
      endsAt {
        month
        year
      }
      fieldOfStudy
      degreeName
      school
      description
    }
    accomplishmentProjects {
      startsAt {
        month
        year
      }
      endsAt {
        month
        year
      }
      title
      description
      url
    }
    certifications {
      startsAt {
        month
        year
      }
      endsAt {
        month
        year
      }
      url
      name
      displaySource
      authority
    }
  }
}
    `;

/**
 * __useImportLinkedinProfileQuery__
 *
 * To run a query within a React component, call `useImportLinkedinProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportLinkedinProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportLinkedinProfileQuery({
 *   variables: {
 *      linkedinProfileUrl: // value for 'linkedinProfileUrl'
 *   },
 * });
 */
export function useImportLinkedinProfileQuery(baseOptions: Apollo.QueryHookOptions<ImportLinkedinProfileQuery, ImportLinkedinProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportLinkedinProfileQuery, ImportLinkedinProfileQueryVariables>(ImportLinkedinProfileDocument, options);
      }
export function useImportLinkedinProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportLinkedinProfileQuery, ImportLinkedinProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportLinkedinProfileQuery, ImportLinkedinProfileQueryVariables>(ImportLinkedinProfileDocument, options);
        }
export type ImportLinkedinProfileQueryHookResult = ReturnType<typeof useImportLinkedinProfileQuery>;
export type ImportLinkedinProfileLazyQueryHookResult = ReturnType<typeof useImportLinkedinProfileLazyQuery>;
export type ImportLinkedinProfileQueryResult = Apollo.QueryResult<ImportLinkedinProfileQuery, ImportLinkedinProfileQueryVariables>;
export const ListCareersDocument = gql`
    query ListCareers {
  listCareers {
    name
    id
    slug
    worksWithSkills
  }
}
    `;

/**
 * __useListCareersQuery__
 *
 * To run a query within a React component, call `useListCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCareersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCareersQuery(baseOptions?: Apollo.QueryHookOptions<ListCareersQuery, ListCareersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCareersQuery, ListCareersQueryVariables>(ListCareersDocument, options);
      }
export function useListCareersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCareersQuery, ListCareersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCareersQuery, ListCareersQueryVariables>(ListCareersDocument, options);
        }
export type ListCareersQueryHookResult = ReturnType<typeof useListCareersQuery>;
export type ListCareersLazyQueryHookResult = ReturnType<typeof useListCareersLazyQuery>;
export type ListCareersQueryResult = Apollo.QueryResult<ListCareersQuery, ListCareersQueryVariables>;
export const ShowCandidateProfileDocument = gql`
    query ShowCandidateProfile($candidateId: Int!) {
  showCandidateProfile(candidateId: $candidateId) {
    candidate {
      id
      name
      image
      positionTitle
      cltSalary
      pjSalary
      usdSalary
      lastApprovedTime
      approvedInternational
      searchStatus
      city {
        id
        name
      }
      summary
      summaryTranslation
      techEvaluations {
        evaluatedAt
        score
        skills
        kind
        pdfUrl
      }
      workRemote
      localWorkPreference
      specialNecessity
      humanizedSpecialNecessity
      workedUsingOnlyEnglish
      geekhunterEvaluation
      geekhunterEvaluationTranslation
      geekhunterEvaluatedAt
      searchPools
      stackoverflowUrl
      githubUrl
      linkedInUrl
      portfolioUrl
      portfolioExtraUrl
      available
      situation
      phone
      email
      allowWhatsappNotification
      hiringOption
      profileLanguage
      candidateLanguages {
        assessmentLevel
        id
        language {
          id
          name
        }
        talkingLevel {
          value
        }
        updatedAt
      }
      experiences {
        id
        company
        startDate
        endDate
        currentJob
        jobTitle
        durationInMonths
        description
        descriptionTranslation
        skills {
          technology {
            name
          }
        }
      }
      skills {
        id
        level {
          value
          text
        }
        mainTech
        technology {
          id
          name
        }
        evaluationCount
      }
      educations {
        id
        institution
        educationStatus
        startYear
        endYear
        durationInYears
        course
      }
      certifications {
        id
        institution
        startDate
        endDate
        url
        current
        title
      }
      projects {
        id
        name
        description
        url
        startDate
        endDate
        current
      }
      candidateFocus {
        id
        focus {
          id
          description
        }
        experienceLevel {
          value
          text
        }
        order
      }
      candidateCities {
        id
        order
        city {
          id
          name
        }
      }
    }
    bidsSent {
      job {
        title
        id
      }
      cltMinSalary
      cltMaxSalary
      pjMinSalary
      pjMaxSalary
      usdAnnualMinSalary
      usdAnnualMaxSalary
      status
      createdAt
      companyUser {
        name
      }
      id
      acceptedAt
      company {
        name
      }
    }
    notes {
      id
      note
      actorName
      excluded
    }
    isFavoriteCandidate
    isCandidateHidden
    hasMessage
    isBidden
    candidateInterestJobs {
      id
      candidateId
      status
      createdAt
      job {
        id
        title
      }
      interestType
    }
    activeStepCandidates {
      id
      step {
        title
      }
      bid {
        job {
          title
        }
      }
    }
    archivedStepCandidates {
      id
      step {
        title
      }
      bid {
        job {
          title
        }
      }
    }
    archivedStepCandidateFeedbacks {
      feedbackType {
        value
        label
      }
      archivedStepCandidate {
        stepCandidate {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useShowCandidateProfileQuery__
 *
 * To run a query within a React component, call `useShowCandidateProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowCandidateProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowCandidateProfileQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useShowCandidateProfileQuery(baseOptions: Apollo.QueryHookOptions<ShowCandidateProfileQuery, ShowCandidateProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowCandidateProfileQuery, ShowCandidateProfileQueryVariables>(ShowCandidateProfileDocument, options);
      }
export function useShowCandidateProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowCandidateProfileQuery, ShowCandidateProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowCandidateProfileQuery, ShowCandidateProfileQueryVariables>(ShowCandidateProfileDocument, options);
        }
export type ShowCandidateProfileQueryHookResult = ReturnType<typeof useShowCandidateProfileQuery>;
export type ShowCandidateProfileLazyQueryHookResult = ReturnType<typeof useShowCandidateProfileLazyQuery>;
export type ShowCandidateProfileQueryResult = Apollo.QueryResult<ShowCandidateProfileQuery, ShowCandidateProfileQueryVariables>;
export const CheckPasswordDocument = gql`
    query CheckPassword($password: String!) {
  checkPassword(password: $password)
}
    `;

/**
 * __useCheckPasswordQuery__
 *
 * To run a query within a React component, call `useCheckPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
      }
export function useCheckPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
        }
export type CheckPasswordQueryHookResult = ReturnType<typeof useCheckPasswordQuery>;
export type CheckPasswordLazyQueryHookResult = ReturnType<typeof useCheckPasswordLazyQuery>;
export type CheckPasswordQueryResult = Apollo.QueryResult<CheckPasswordQuery, CheckPasswordQueryVariables>;
export const CheckTwoFactorAuthenticationCodeDocument = gql`
    query CheckTwoFactorAuthenticationCode($code: String!) {
  checkTwoFactorAuthenticationCode(code: $code)
}
    `;

/**
 * __useCheckTwoFactorAuthenticationCodeQuery__
 *
 * To run a query within a React component, call `useCheckTwoFactorAuthenticationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTwoFactorAuthenticationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTwoFactorAuthenticationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckTwoFactorAuthenticationCodeQuery(baseOptions: Apollo.QueryHookOptions<CheckTwoFactorAuthenticationCodeQuery, CheckTwoFactorAuthenticationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTwoFactorAuthenticationCodeQuery, CheckTwoFactorAuthenticationCodeQueryVariables>(CheckTwoFactorAuthenticationCodeDocument, options);
      }
export function useCheckTwoFactorAuthenticationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTwoFactorAuthenticationCodeQuery, CheckTwoFactorAuthenticationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTwoFactorAuthenticationCodeQuery, CheckTwoFactorAuthenticationCodeQueryVariables>(CheckTwoFactorAuthenticationCodeDocument, options);
        }
export type CheckTwoFactorAuthenticationCodeQueryHookResult = ReturnType<typeof useCheckTwoFactorAuthenticationCodeQuery>;
export type CheckTwoFactorAuthenticationCodeLazyQueryHookResult = ReturnType<typeof useCheckTwoFactorAuthenticationCodeLazyQuery>;
export type CheckTwoFactorAuthenticationCodeQueryResult = Apollo.QueryResult<CheckTwoFactorAuthenticationCodeQuery, CheckTwoFactorAuthenticationCodeQueryVariables>;
export const GetAccountManagerInfoForHeaderDocument = gql`
    query GetAccountManagerInfoForHeader {
  getCurrentAccountManager {
    name
    email
    image
    phone
    scheduleUrl
  }
}
    `;

/**
 * __useGetAccountManagerInfoForHeaderQuery__
 *
 * To run a query within a React component, call `useGetAccountManagerInfoForHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountManagerInfoForHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountManagerInfoForHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountManagerInfoForHeaderQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountManagerInfoForHeaderQuery, GetAccountManagerInfoForHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountManagerInfoForHeaderQuery, GetAccountManagerInfoForHeaderQueryVariables>(GetAccountManagerInfoForHeaderDocument, options);
      }
export function useGetAccountManagerInfoForHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountManagerInfoForHeaderQuery, GetAccountManagerInfoForHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountManagerInfoForHeaderQuery, GetAccountManagerInfoForHeaderQueryVariables>(GetAccountManagerInfoForHeaderDocument, options);
        }
export type GetAccountManagerInfoForHeaderQueryHookResult = ReturnType<typeof useGetAccountManagerInfoForHeaderQuery>;
export type GetAccountManagerInfoForHeaderLazyQueryHookResult = ReturnType<typeof useGetAccountManagerInfoForHeaderLazyQuery>;
export type GetAccountManagerInfoForHeaderQueryResult = Apollo.QueryResult<GetAccountManagerInfoForHeaderQuery, GetAccountManagerInfoForHeaderQueryVariables>;
export const GetActiveFocusesDocument = gql`
    query GetActiveFocuses($careerSlugs: [CareerSlugEnum!], $availableForCandidateSearch: Boolean, $availableForCandidates: Boolean) {
  getActiveFocuses(
    careerSlugs: $careerSlugs
    availableForCandidateSearch: $availableForCandidateSearch
    availableForCandidates: $availableForCandidates
  ) {
    id
    description
    career {
      id
      name
      slug
      worksWithSkills
    }
  }
}
    `;

/**
 * __useGetActiveFocusesQuery__
 *
 * To run a query within a React component, call `useGetActiveFocusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFocusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFocusesQuery({
 *   variables: {
 *      careerSlugs: // value for 'careerSlugs'
 *      availableForCandidateSearch: // value for 'availableForCandidateSearch'
 *      availableForCandidates: // value for 'availableForCandidates'
 *   },
 * });
 */
export function useGetActiveFocusesQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveFocusesQuery, GetActiveFocusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveFocusesQuery, GetActiveFocusesQueryVariables>(GetActiveFocusesDocument, options);
      }
export function useGetActiveFocusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveFocusesQuery, GetActiveFocusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveFocusesQuery, GetActiveFocusesQueryVariables>(GetActiveFocusesDocument, options);
        }
export type GetActiveFocusesQueryHookResult = ReturnType<typeof useGetActiveFocusesQuery>;
export type GetActiveFocusesLazyQueryHookResult = ReturnType<typeof useGetActiveFocusesLazyQuery>;
export type GetActiveFocusesQueryResult = Apollo.QueryResult<GetActiveFocusesQuery, GetActiveFocusesQueryVariables>;
export const GetCandidateFilterOptionsByIdDocument = gql`
    query GetCandidateFilterOptionsById($cityIds: [ID!]!, $techIds: [ID!]!, $shouldGetCities: Boolean!, $shouldGetTechs: Boolean!) {
  getCityByIds(ids: $cityIds) @include(if: $shouldGetCities) {
    id
    name
  }
  getTechnologyByIds(ids: $techIds) @include(if: $shouldGetTechs) {
    id
    name
  }
}
    `;

/**
 * __useGetCandidateFilterOptionsByIdQuery__
 *
 * To run a query within a React component, call `useGetCandidateFilterOptionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateFilterOptionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateFilterOptionsByIdQuery({
 *   variables: {
 *      cityIds: // value for 'cityIds'
 *      techIds: // value for 'techIds'
 *      shouldGetCities: // value for 'shouldGetCities'
 *      shouldGetTechs: // value for 'shouldGetTechs'
 *   },
 * });
 */
export function useGetCandidateFilterOptionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCandidateFilterOptionsByIdQuery, GetCandidateFilterOptionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateFilterOptionsByIdQuery, GetCandidateFilterOptionsByIdQueryVariables>(GetCandidateFilterOptionsByIdDocument, options);
      }
export function useGetCandidateFilterOptionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateFilterOptionsByIdQuery, GetCandidateFilterOptionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateFilterOptionsByIdQuery, GetCandidateFilterOptionsByIdQueryVariables>(GetCandidateFilterOptionsByIdDocument, options);
        }
export type GetCandidateFilterOptionsByIdQueryHookResult = ReturnType<typeof useGetCandidateFilterOptionsByIdQuery>;
export type GetCandidateFilterOptionsByIdLazyQueryHookResult = ReturnType<typeof useGetCandidateFilterOptionsByIdLazyQuery>;
export type GetCandidateFilterOptionsByIdQueryResult = Apollo.QueryResult<GetCandidateFilterOptionsByIdQuery, GetCandidateFilterOptionsByIdQueryVariables>;
export const GetCandidateFilterSelectOptionsDocument = gql`
    query GetCandidateFilterSelectOptions($careerSlugs: [CareerSlugEnum!], $availableForCandidateSearch: Boolean) {
  getTechnologiesSuggestions {
    id
    name
  }
  getCitiesSuggestions {
    id
    name
  }
  getActiveFocuses(
    careerSlugs: $careerSlugs
    availableForCandidateSearch: $availableForCandidateSearch
  ) {
    id
    description
    career {
      id
      name
      slug
      worksWithSkills
    }
  }
}
    `;

/**
 * __useGetCandidateFilterSelectOptionsQuery__
 *
 * To run a query within a React component, call `useGetCandidateFilterSelectOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateFilterSelectOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateFilterSelectOptionsQuery({
 *   variables: {
 *      careerSlugs: // value for 'careerSlugs'
 *      availableForCandidateSearch: // value for 'availableForCandidateSearch'
 *   },
 * });
 */
export function useGetCandidateFilterSelectOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidateFilterSelectOptionsQuery, GetCandidateFilterSelectOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateFilterSelectOptionsQuery, GetCandidateFilterSelectOptionsQueryVariables>(GetCandidateFilterSelectOptionsDocument, options);
      }
export function useGetCandidateFilterSelectOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateFilterSelectOptionsQuery, GetCandidateFilterSelectOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateFilterSelectOptionsQuery, GetCandidateFilterSelectOptionsQueryVariables>(GetCandidateFilterSelectOptionsDocument, options);
        }
export type GetCandidateFilterSelectOptionsQueryHookResult = ReturnType<typeof useGetCandidateFilterSelectOptionsQuery>;
export type GetCandidateFilterSelectOptionsLazyQueryHookResult = ReturnType<typeof useGetCandidateFilterSelectOptionsLazyQuery>;
export type GetCandidateFilterSelectOptionsQueryResult = Apollo.QueryResult<GetCandidateFilterSelectOptionsQuery, GetCandidateFilterSelectOptionsQueryVariables>;
export const GetCitiesSuggestionsDocument = gql`
    query GetCitiesSuggestions {
  getCitiesSuggestions {
    id
    name
  }
}
    `;

/**
 * __useGetCitiesSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetCitiesSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCitiesSuggestionsQuery, GetCitiesSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesSuggestionsQuery, GetCitiesSuggestionsQueryVariables>(GetCitiesSuggestionsDocument, options);
      }
export function useGetCitiesSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesSuggestionsQuery, GetCitiesSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesSuggestionsQuery, GetCitiesSuggestionsQueryVariables>(GetCitiesSuggestionsDocument, options);
        }
export type GetCitiesSuggestionsQueryHookResult = ReturnType<typeof useGetCitiesSuggestionsQuery>;
export type GetCitiesSuggestionsLazyQueryHookResult = ReturnType<typeof useGetCitiesSuggestionsLazyQuery>;
export type GetCitiesSuggestionsQueryResult = Apollo.QueryResult<GetCitiesSuggestionsQuery, GetCitiesSuggestionsQueryVariables>;
export const GetCityByIdsDocument = gql`
    query GetCityByIds($ids: [ID!]!) {
  getCityByIds(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useGetCityByIdsQuery__
 *
 * To run a query within a React component, call `useGetCityByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCityByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetCityByIdsQuery, GetCityByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCityByIdsQuery, GetCityByIdsQueryVariables>(GetCityByIdsDocument, options);
      }
export function useGetCityByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityByIdsQuery, GetCityByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCityByIdsQuery, GetCityByIdsQueryVariables>(GetCityByIdsDocument, options);
        }
export type GetCityByIdsQueryHookResult = ReturnType<typeof useGetCityByIdsQuery>;
export type GetCityByIdsLazyQueryHookResult = ReturnType<typeof useGetCityByIdsLazyQuery>;
export type GetCityByIdsQueryResult = Apollo.QueryResult<GetCityByIdsQuery, GetCityByIdsQueryVariables>;
export const GetCompanyAccountInfoForHeaderDocument = gql`
    query GetCompanyAccountInfoForHeader {
  getCurrentCompanyUser {
    id
    name
    company {
      id
      name
      logo
      isInternational
      currentTier
      registrationFlow
    }
  }
  getCurrentPackageInfo {
    id
    startAt
    endAt
    hiringCount
    remainingPositions
    remainingDuration {
      diff
      diffType
    }
    packageSize
    packagePlan
  }
}
    `;

/**
 * __useGetCompanyAccountInfoForHeaderQuery__
 *
 * To run a query within a React component, call `useGetCompanyAccountInfoForHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAccountInfoForHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAccountInfoForHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyAccountInfoForHeaderQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyAccountInfoForHeaderQuery, GetCompanyAccountInfoForHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyAccountInfoForHeaderQuery, GetCompanyAccountInfoForHeaderQueryVariables>(GetCompanyAccountInfoForHeaderDocument, options);
      }
export function useGetCompanyAccountInfoForHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAccountInfoForHeaderQuery, GetCompanyAccountInfoForHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyAccountInfoForHeaderQuery, GetCompanyAccountInfoForHeaderQueryVariables>(GetCompanyAccountInfoForHeaderDocument, options);
        }
export type GetCompanyAccountInfoForHeaderQueryHookResult = ReturnType<typeof useGetCompanyAccountInfoForHeaderQuery>;
export type GetCompanyAccountInfoForHeaderLazyQueryHookResult = ReturnType<typeof useGetCompanyAccountInfoForHeaderLazyQuery>;
export type GetCompanyAccountInfoForHeaderQueryResult = Apollo.QueryResult<GetCompanyAccountInfoForHeaderQuery, GetCompanyAccountInfoForHeaderQueryVariables>;
export const GetCompanyUserScheduleInfoDocument = gql`
    query GetCompanyUserScheduleInfo {
  getCurrentCompanyUser {
    id
    email
    phoneNumber
    company {
      id
      teamMember {
        id
        scheduleUrl
      }
    }
  }
}
    `;

/**
 * __useGetCompanyUserScheduleInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserScheduleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserScheduleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserScheduleInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUserScheduleInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyUserScheduleInfoQuery, GetCompanyUserScheduleInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUserScheduleInfoQuery, GetCompanyUserScheduleInfoQueryVariables>(GetCompanyUserScheduleInfoDocument, options);
      }
export function useGetCompanyUserScheduleInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUserScheduleInfoQuery, GetCompanyUserScheduleInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUserScheduleInfoQuery, GetCompanyUserScheduleInfoQueryVariables>(GetCompanyUserScheduleInfoDocument, options);
        }
export type GetCompanyUserScheduleInfoQueryHookResult = ReturnType<typeof useGetCompanyUserScheduleInfoQuery>;
export type GetCompanyUserScheduleInfoLazyQueryHookResult = ReturnType<typeof useGetCompanyUserScheduleInfoLazyQuery>;
export type GetCompanyUserScheduleInfoQueryResult = Apollo.QueryResult<GetCompanyUserScheduleInfoQuery, GetCompanyUserScheduleInfoQueryVariables>;
export const GetCurrentActiveJobsSearchCandidatesDocument = gql`
    query getCurrentActiveJobsSearchCandidates {
  getCurrentActiveJobsSearchCandidates {
    id
    job {
      id
      slug
      title
      requiredEnglish
      remoteWork
      hybrid
      cityId
      cltMinSalary
      cltMaxSalary
      pjMinSalary
      pjMaxSalary
      usdAnnualSalaryMin
      usdAnnualSalaryMax
      focusId
      technologies {
        id
        name
      }
      lastBidMessage
    }
    maxSalary
    companyUserName
  }
}
    `;

/**
 * __useGetCurrentActiveJobsSearchCandidatesQuery__
 *
 * To run a query within a React component, call `useGetCurrentActiveJobsSearchCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentActiveJobsSearchCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentActiveJobsSearchCandidatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentActiveJobsSearchCandidatesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentActiveJobsSearchCandidatesQuery, GetCurrentActiveJobsSearchCandidatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentActiveJobsSearchCandidatesQuery, GetCurrentActiveJobsSearchCandidatesQueryVariables>(GetCurrentActiveJobsSearchCandidatesDocument, options);
      }
export function useGetCurrentActiveJobsSearchCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentActiveJobsSearchCandidatesQuery, GetCurrentActiveJobsSearchCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentActiveJobsSearchCandidatesQuery, GetCurrentActiveJobsSearchCandidatesQueryVariables>(GetCurrentActiveJobsSearchCandidatesDocument, options);
        }
export type GetCurrentActiveJobsSearchCandidatesQueryHookResult = ReturnType<typeof useGetCurrentActiveJobsSearchCandidatesQuery>;
export type GetCurrentActiveJobsSearchCandidatesLazyQueryHookResult = ReturnType<typeof useGetCurrentActiveJobsSearchCandidatesLazyQuery>;
export type GetCurrentActiveJobsSearchCandidatesQueryResult = Apollo.QueryResult<GetCurrentActiveJobsSearchCandidatesQuery, GetCurrentActiveJobsSearchCandidatesQueryVariables>;
export const GetCurrentCompanyUserDocument = gql`
    query GetCurrentCompanyUser {
  getCurrentCompanyUser {
    company {
      id
      cities {
        id
        name
        priority
        state {
          id
          name
          abbreviation
        }
      }
      companyPackages {
        id
        startAt
        endAt
        revenue
        status
        packagePlan {
          slug
        }
        automaticRenewal
      }
      name
      segmentation
      status
      demo
      isInternalCompany
      isInternational
      hasAccessToGupyIntegration
      hasActivePlan
      hasPausedPackage
      unrestrictedView
      pausedPackage {
        id
        pauseStartAt
        pauseEndAt
      }
      teamMember {
        email
      }
      currentTier
      features {
        showSalary
        showEnglishVetting
        showProfileTranslation
        showTechVetting
        searchPoolSelectedByIa
        searchPoolGeekhunterApproved
        showRecommendations
        standard3Jobs
        showWorkAvailability
        showSocialNetworks
        addNewUsers
        diversityFilter
        accessToAccountManager
        accessCompanyUserRoles
        accessToHub
        accessToAts
      }
      registrationFlow
      logo
    }
    confirmationSentAt
    confirmedAt
    createdAt
    currentSignInAt
    currentSignInIp
    email
    failedAttempts
    firstReferer
    id
    jobTitle
    lastSignInAt
    lastSignInIp
    lockedAt
    md5Email
    name
    phoneNumber
    receiveNotifications
    rememberCreatedAt
    role
    signInCount
    termsOfService
    unconfirmedEmail
    updatedAt
    utmCampaign
    utmMedium
    utmSource
    utmTerm
    otpRequiredForLogin
    twoFactorAuthenticationIsConfigured
    twoFactorAuthenticationQrCodeUri
    accessHub
  }
}
    `;

/**
 * __useGetCurrentCompanyUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>(GetCurrentCompanyUserDocument, options);
      }
export function useGetCurrentCompanyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>(GetCurrentCompanyUserDocument, options);
        }
export type GetCurrentCompanyUserQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserQuery>;
export type GetCurrentCompanyUserLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserLazyQuery>;
export type GetCurrentCompanyUserQueryResult = Apollo.QueryResult<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>;
export const GetCurrentCompanyUserBasicInfoDocument = gql`
    query GetCurrentCompanyUserBasicInfo {
  getCurrentCompanyUser {
    id
    email
    name
    phoneNumber
    company {
      id
      name
      isInternational
    }
  }
  getCurrentPackageInfo {
    id
    packagePlan
  }
}
    `;

/**
 * __useGetCurrentCompanyUserBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyUserBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyUserBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyUserBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyUserBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyUserBasicInfoQuery, GetCurrentCompanyUserBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCompanyUserBasicInfoQuery, GetCurrentCompanyUserBasicInfoQueryVariables>(GetCurrentCompanyUserBasicInfoDocument, options);
      }
export function useGetCurrentCompanyUserBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyUserBasicInfoQuery, GetCurrentCompanyUserBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCompanyUserBasicInfoQuery, GetCurrentCompanyUserBasicInfoQueryVariables>(GetCurrentCompanyUserBasicInfoDocument, options);
        }
export type GetCurrentCompanyUserBasicInfoQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserBasicInfoQuery>;
export type GetCurrentCompanyUserBasicInfoLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserBasicInfoLazyQuery>;
export type GetCurrentCompanyUserBasicInfoQueryResult = Apollo.QueryResult<GetCurrentCompanyUserBasicInfoQuery, GetCurrentCompanyUserBasicInfoQueryVariables>;
export const GetCurrentCompanyUserCompanyCitiesDocument = gql`
    query GetCurrentCompanyUserCompanyCities {
  getCurrentCompanyUser {
    id
    company {
      id
      cities {
        id
        name
        priority
        state {
          id
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyUserCompanyCitiesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyUserCompanyCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyUserCompanyCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyUserCompanyCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyUserCompanyCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyUserCompanyCitiesQuery, GetCurrentCompanyUserCompanyCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCompanyUserCompanyCitiesQuery, GetCurrentCompanyUserCompanyCitiesQueryVariables>(GetCurrentCompanyUserCompanyCitiesDocument, options);
      }
export function useGetCurrentCompanyUserCompanyCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyUserCompanyCitiesQuery, GetCurrentCompanyUserCompanyCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCompanyUserCompanyCitiesQuery, GetCurrentCompanyUserCompanyCitiesQueryVariables>(GetCurrentCompanyUserCompanyCitiesDocument, options);
        }
export type GetCurrentCompanyUserCompanyCitiesQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserCompanyCitiesQuery>;
export type GetCurrentCompanyUserCompanyCitiesLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserCompanyCitiesLazyQuery>;
export type GetCurrentCompanyUserCompanyCitiesQueryResult = Apollo.QueryResult<GetCurrentCompanyUserCompanyCitiesQuery, GetCurrentCompanyUserCompanyCitiesQueryVariables>;
export const GetCurrentCompanyUserIsConfirmedDocument = gql`
    query GetCurrentCompanyUserIsConfirmed {
  getCurrentCompanyUser {
    id
    confirmedAt
  }
}
    `;

/**
 * __useGetCurrentCompanyUserIsConfirmedQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyUserIsConfirmedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyUserIsConfirmedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyUserIsConfirmedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyUserIsConfirmedQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyUserIsConfirmedQuery, GetCurrentCompanyUserIsConfirmedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCompanyUserIsConfirmedQuery, GetCurrentCompanyUserIsConfirmedQueryVariables>(GetCurrentCompanyUserIsConfirmedDocument, options);
      }
export function useGetCurrentCompanyUserIsConfirmedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyUserIsConfirmedQuery, GetCurrentCompanyUserIsConfirmedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCompanyUserIsConfirmedQuery, GetCurrentCompanyUserIsConfirmedQueryVariables>(GetCurrentCompanyUserIsConfirmedDocument, options);
        }
export type GetCurrentCompanyUserIsConfirmedQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserIsConfirmedQuery>;
export type GetCurrentCompanyUserIsConfirmedLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserIsConfirmedLazyQuery>;
export type GetCurrentCompanyUserIsConfirmedQueryResult = Apollo.QueryResult<GetCurrentCompanyUserIsConfirmedQuery, GetCurrentCompanyUserIsConfirmedQueryVariables>;
export const GetGupyIntegrationDocument = gql`
    query GetGupyIntegration {
  getGupyIntegration {
    id
    domain
    token
    active
    hasAgreedToTerms
  }
}
    `;

/**
 * __useGetGupyIntegrationQuery__
 *
 * To run a query within a React component, call `useGetGupyIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGupyIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGupyIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGupyIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<GetGupyIntegrationQuery, GetGupyIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGupyIntegrationQuery, GetGupyIntegrationQueryVariables>(GetGupyIntegrationDocument, options);
      }
export function useGetGupyIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGupyIntegrationQuery, GetGupyIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGupyIntegrationQuery, GetGupyIntegrationQueryVariables>(GetGupyIntegrationDocument, options);
        }
export type GetGupyIntegrationQueryHookResult = ReturnType<typeof useGetGupyIntegrationQuery>;
export type GetGupyIntegrationLazyQueryHookResult = ReturnType<typeof useGetGupyIntegrationLazyQuery>;
export type GetGupyIntegrationQueryResult = Apollo.QueryResult<GetGupyIntegrationQuery, GetGupyIntegrationQueryVariables>;
export const GetTechnologiesSuggestionsDocument = gql`
    query GetTechnologiesSuggestions {
  getTechnologiesSuggestions {
    id
    name
  }
}
    `;

/**
 * __useGetTechnologiesSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetTechnologiesSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologiesSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologiesSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTechnologiesSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTechnologiesSuggestionsQuery, GetTechnologiesSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologiesSuggestionsQuery, GetTechnologiesSuggestionsQueryVariables>(GetTechnologiesSuggestionsDocument, options);
      }
export function useGetTechnologiesSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologiesSuggestionsQuery, GetTechnologiesSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologiesSuggestionsQuery, GetTechnologiesSuggestionsQueryVariables>(GetTechnologiesSuggestionsDocument, options);
        }
export type GetTechnologiesSuggestionsQueryHookResult = ReturnType<typeof useGetTechnologiesSuggestionsQuery>;
export type GetTechnologiesSuggestionsLazyQueryHookResult = ReturnType<typeof useGetTechnologiesSuggestionsLazyQuery>;
export type GetTechnologiesSuggestionsQueryResult = Apollo.QueryResult<GetTechnologiesSuggestionsQuery, GetTechnologiesSuggestionsQueryVariables>;
export const GetTechnologyByIdsDocument = gql`
    query GetTechnologyByIds($ids: [ID!]!) {
  getTechnologyByIds(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useGetTechnologyByIdsQuery__
 *
 * To run a query within a React component, call `useGetTechnologyByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologyByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetTechnologyByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetTechnologyByIdsQuery, GetTechnologyByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologyByIdsQuery, GetTechnologyByIdsQueryVariables>(GetTechnologyByIdsDocument, options);
      }
export function useGetTechnologyByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologyByIdsQuery, GetTechnologyByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologyByIdsQuery, GetTechnologyByIdsQueryVariables>(GetTechnologyByIdsDocument, options);
        }
export type GetTechnologyByIdsQueryHookResult = ReturnType<typeof useGetTechnologyByIdsQuery>;
export type GetTechnologyByIdsLazyQueryHookResult = ReturnType<typeof useGetTechnologyByIdsLazyQuery>;
export type GetTechnologyByIdsQueryResult = Apollo.QueryResult<GetTechnologyByIdsQuery, GetTechnologyByIdsQueryVariables>;
export const CountHubPositionsByCompanyDocument = gql`
    query CountHubPositionsByCompany($keyword: String) {
  countHubPositionsByCompany(keyword: $keyword)
}
    `;

/**
 * __useCountHubPositionsByCompanyQuery__
 *
 * To run a query within a React component, call `useCountHubPositionsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountHubPositionsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountHubPositionsByCompanyQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useCountHubPositionsByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<CountHubPositionsByCompanyQuery, CountHubPositionsByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountHubPositionsByCompanyQuery, CountHubPositionsByCompanyQueryVariables>(CountHubPositionsByCompanyDocument, options);
      }
export function useCountHubPositionsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountHubPositionsByCompanyQuery, CountHubPositionsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountHubPositionsByCompanyQuery, CountHubPositionsByCompanyQueryVariables>(CountHubPositionsByCompanyDocument, options);
        }
export type CountHubPositionsByCompanyQueryHookResult = ReturnType<typeof useCountHubPositionsByCompanyQuery>;
export type CountHubPositionsByCompanyLazyQueryHookResult = ReturnType<typeof useCountHubPositionsByCompanyLazyQuery>;
export type CountHubPositionsByCompanyQueryResult = Apollo.QueryResult<CountHubPositionsByCompanyQuery, CountHubPositionsByCompanyQueryVariables>;
export const GetHubPositionsByCompanyDocument = gql`
    query GetHubPositionsByCompany($page: Int, $perPage: Int, $keyword: String) {
  getHubPositionsByCompany(page: $page, perPage: $perPage, keyword: $keyword) {
    id
    name
    post
    seniority
    level
    salary
    internalRole
    origin
    focus {
      id
      description
    }
    jobSalaries {
      minSalary
      maxSalary
    }
    hubSalaryStatistic {
      median
      mean
      min
      max
      numberOfSamples
      basis
    }
  }
}
    `;

/**
 * __useGetHubPositionsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetHubPositionsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubPositionsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubPositionsByCompanyQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetHubPositionsByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetHubPositionsByCompanyQuery, GetHubPositionsByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHubPositionsByCompanyQuery, GetHubPositionsByCompanyQueryVariables>(GetHubPositionsByCompanyDocument, options);
      }
export function useGetHubPositionsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHubPositionsByCompanyQuery, GetHubPositionsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHubPositionsByCompanyQuery, GetHubPositionsByCompanyQueryVariables>(GetHubPositionsByCompanyDocument, options);
        }
export type GetHubPositionsByCompanyQueryHookResult = ReturnType<typeof useGetHubPositionsByCompanyQuery>;
export type GetHubPositionsByCompanyLazyQueryHookResult = ReturnType<typeof useGetHubPositionsByCompanyLazyQuery>;
export type GetHubPositionsByCompanyQueryResult = Apollo.QueryResult<GetHubPositionsByCompanyQuery, GetHubPositionsByCompanyQueryVariables>;
export const ListIndustriesDocument = gql`
    query ListIndustries {
  industries {
    id
    name
    active
  }
}
    `;

/**
 * __useListIndustriesQuery__
 *
 * To run a query within a React component, call `useListIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<ListIndustriesQuery, ListIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIndustriesQuery, ListIndustriesQueryVariables>(ListIndustriesDocument, options);
      }
export function useListIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIndustriesQuery, ListIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIndustriesQuery, ListIndustriesQueryVariables>(ListIndustriesDocument, options);
        }
export type ListIndustriesQueryHookResult = ReturnType<typeof useListIndustriesQuery>;
export type ListIndustriesLazyQueryHookResult = ReturnType<typeof useListIndustriesLazyQuery>;
export type ListIndustriesQueryResult = Apollo.QueryResult<ListIndustriesQuery, ListIndustriesQueryVariables>;
export const ListNumberOfDevelopersOptionsDocument = gql`
    query ListNumberOfDevelopersOptions {
  numberOfProgrammersOptions {
    key
    value
  }
}
    `;

/**
 * __useListNumberOfDevelopersOptionsQuery__
 *
 * To run a query within a React component, call `useListNumberOfDevelopersOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNumberOfDevelopersOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNumberOfDevelopersOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNumberOfDevelopersOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ListNumberOfDevelopersOptionsQuery, ListNumberOfDevelopersOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListNumberOfDevelopersOptionsQuery, ListNumberOfDevelopersOptionsQueryVariables>(ListNumberOfDevelopersOptionsDocument, options);
      }
export function useListNumberOfDevelopersOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListNumberOfDevelopersOptionsQuery, ListNumberOfDevelopersOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListNumberOfDevelopersOptionsQuery, ListNumberOfDevelopersOptionsQueryVariables>(ListNumberOfDevelopersOptionsDocument, options);
        }
export type ListNumberOfDevelopersOptionsQueryHookResult = ReturnType<typeof useListNumberOfDevelopersOptionsQuery>;
export type ListNumberOfDevelopersOptionsLazyQueryHookResult = ReturnType<typeof useListNumberOfDevelopersOptionsLazyQuery>;
export type ListNumberOfDevelopersOptionsQueryResult = Apollo.QueryResult<ListNumberOfDevelopersOptionsQuery, ListNumberOfDevelopersOptionsQueryVariables>;
export const MetabaseEmbedDashboardUrlDocument = gql`
    query MetabaseEmbedDashboardUrl($dashboard: Int!, $role: String!) {
  metabaseEmbedDashboardUrl(dashboard: $dashboard, role: $role)
}
    `;

/**
 * __useMetabaseEmbedDashboardUrlQuery__
 *
 * To run a query within a React component, call `useMetabaseEmbedDashboardUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetabaseEmbedDashboardUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetabaseEmbedDashboardUrlQuery({
 *   variables: {
 *      dashboard: // value for 'dashboard'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useMetabaseEmbedDashboardUrlQuery(baseOptions: Apollo.QueryHookOptions<MetabaseEmbedDashboardUrlQuery, MetabaseEmbedDashboardUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetabaseEmbedDashboardUrlQuery, MetabaseEmbedDashboardUrlQueryVariables>(MetabaseEmbedDashboardUrlDocument, options);
      }
export function useMetabaseEmbedDashboardUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetabaseEmbedDashboardUrlQuery, MetabaseEmbedDashboardUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetabaseEmbedDashboardUrlQuery, MetabaseEmbedDashboardUrlQueryVariables>(MetabaseEmbedDashboardUrlDocument, options);
        }
export type MetabaseEmbedDashboardUrlQueryHookResult = ReturnType<typeof useMetabaseEmbedDashboardUrlQuery>;
export type MetabaseEmbedDashboardUrlLazyQueryHookResult = ReturnType<typeof useMetabaseEmbedDashboardUrlLazyQuery>;
export type MetabaseEmbedDashboardUrlQueryResult = Apollo.QueryResult<MetabaseEmbedDashboardUrlQuery, MetabaseEmbedDashboardUrlQueryVariables>;
export const SearchCandidatesDocument = gql`
    query SearchCandidates($filters: SearchCandidateFilter!, $searchId: ID, $onlyTotals: Boolean) {
  searchCandidates(
    filters: $filters
    companyCandidateSearchId: $searchId
    onlyTotals: $onlyTotals
  ) {
    candidates {
      candidate {
        id
        name
        image
        positionTitle
        cltSalary
        pjSalary
        usdSalary
        lastApprovedTime
        approvedInternational
        city {
          id
          name
        }
        summary
        workRemote
        localWorkPreference
        specialNecessity
        humanizedSpecialNecessity
        experiences {
          id
          company
          startDate
          endDate
          currentJob
        }
        skills {
          id
          level {
            value
            text
          }
          mainTech
          technology {
            id
            name
          }
        }
        educations {
          id
          institution
          educationStatus
          endYear
        }
        candidateFocus {
          id
          focus {
            id
            description
          }
          experienceLevel {
            value
            text
          }
          order
        }
        candidateCities {
          id
          order
          city {
            id
            name
          }
        }
        candidateLanguages {
          language {
            id
            name
          }
          assessmentLevel
          readingLevel {
            value
          }
          talkingLevel {
            value
          }
          writingLevel {
            value
          }
          updatedAt
        }
        searchPools
      }
      lastBidSent {
        id
        status
        createdAt
        companyUser {
          id
          name
        }
      }
      isFavorite
      lastTimeViewed
      notes {
        note
        createdAt
        actor {
          ... on CompanyUser {
            name
          }
        }
      }
    }
    totalFilteredCount
    isDefaultSearch
    companyCandidateSearchId
  }
}
    `;

/**
 * __useSearchCandidatesQuery__
 *
 * To run a query within a React component, call `useSearchCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCandidatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      searchId: // value for 'searchId'
 *      onlyTotals: // value for 'onlyTotals'
 *   },
 * });
 */
export function useSearchCandidatesQuery(baseOptions: Apollo.QueryHookOptions<SearchCandidatesQuery, SearchCandidatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCandidatesQuery, SearchCandidatesQueryVariables>(SearchCandidatesDocument, options);
      }
export function useSearchCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCandidatesQuery, SearchCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCandidatesQuery, SearchCandidatesQueryVariables>(SearchCandidatesDocument, options);
        }
export type SearchCandidatesQueryHookResult = ReturnType<typeof useSearchCandidatesQuery>;
export type SearchCandidatesLazyQueryHookResult = ReturnType<typeof useSearchCandidatesLazyQuery>;
export type SearchCandidatesQueryResult = Apollo.QueryResult<SearchCandidatesQuery, SearchCandidatesQueryVariables>;
export const SearchCandidatesTotalsDocument = gql`
    query SearchCandidatesTotals($filters: SearchCandidateFilter!, $searchId: ID, $onlyTotals: Boolean) {
  searchCandidates(
    filters: $filters
    companyCandidateSearchId: $searchId
    onlyTotals: $onlyTotals
  ) {
    totalFilteredCount
  }
}
    `;

/**
 * __useSearchCandidatesTotalsQuery__
 *
 * To run a query within a React component, call `useSearchCandidatesTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCandidatesTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCandidatesTotalsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      searchId: // value for 'searchId'
 *      onlyTotals: // value for 'onlyTotals'
 *   },
 * });
 */
export function useSearchCandidatesTotalsQuery(baseOptions: Apollo.QueryHookOptions<SearchCandidatesTotalsQuery, SearchCandidatesTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCandidatesTotalsQuery, SearchCandidatesTotalsQueryVariables>(SearchCandidatesTotalsDocument, options);
      }
export function useSearchCandidatesTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCandidatesTotalsQuery, SearchCandidatesTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCandidatesTotalsQuery, SearchCandidatesTotalsQueryVariables>(SearchCandidatesTotalsDocument, options);
        }
export type SearchCandidatesTotalsQueryHookResult = ReturnType<typeof useSearchCandidatesTotalsQuery>;
export type SearchCandidatesTotalsLazyQueryHookResult = ReturnType<typeof useSearchCandidatesTotalsLazyQuery>;
export type SearchCandidatesTotalsQueryResult = Apollo.QueryResult<SearchCandidatesTotalsQuery, SearchCandidatesTotalsQueryVariables>;
export const SearchLanguagesDocument = gql`
    query searchLanguages($text: String!) {
  searchLanguages(text: $text) {
    id
    name
  }
}
    `;

/**
 * __useSearchLanguagesQuery__
 *
 * To run a query within a React component, call `useSearchLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLanguagesQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchLanguagesQuery(baseOptions: Apollo.QueryHookOptions<SearchLanguagesQuery, SearchLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLanguagesQuery, SearchLanguagesQueryVariables>(SearchLanguagesDocument, options);
      }
export function useSearchLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLanguagesQuery, SearchLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLanguagesQuery, SearchLanguagesQueryVariables>(SearchLanguagesDocument, options);
        }
export type SearchLanguagesQueryHookResult = ReturnType<typeof useSearchLanguagesQuery>;
export type SearchLanguagesLazyQueryHookResult = ReturnType<typeof useSearchLanguagesLazyQuery>;
export type SearchLanguagesQueryResult = Apollo.QueryResult<SearchLanguagesQuery, SearchLanguagesQueryVariables>;
export const ValidateCompanyRegistrationDocument = gql`
    query ValidateCompanyRegistration($companyDomain: String!, $companyName: String!) {
  validateCompanyRegistration(
    companyDomain: $companyDomain
    companyName: $companyName
  ) {
    ok
  }
}
    `;

/**
 * __useValidateCompanyRegistrationQuery__
 *
 * To run a query within a React component, call `useValidateCompanyRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCompanyRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCompanyRegistrationQuery({
 *   variables: {
 *      companyDomain: // value for 'companyDomain'
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useValidateCompanyRegistrationQuery(baseOptions: Apollo.QueryHookOptions<ValidateCompanyRegistrationQuery, ValidateCompanyRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateCompanyRegistrationQuery, ValidateCompanyRegistrationQueryVariables>(ValidateCompanyRegistrationDocument, options);
      }
export function useValidateCompanyRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCompanyRegistrationQuery, ValidateCompanyRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateCompanyRegistrationQuery, ValidateCompanyRegistrationQueryVariables>(ValidateCompanyRegistrationDocument, options);
        }
export type ValidateCompanyRegistrationQueryHookResult = ReturnType<typeof useValidateCompanyRegistrationQuery>;
export type ValidateCompanyRegistrationLazyQueryHookResult = ReturnType<typeof useValidateCompanyRegistrationLazyQuery>;
export type ValidateCompanyRegistrationQueryResult = Apollo.QueryResult<ValidateCompanyRegistrationQuery, ValidateCompanyRegistrationQueryVariables>;
export const FindActiveJobsByCompanyUserWhoIsResponsibleDocument = gql`
    query FindActiveJobsByCompanyUserWhoIsResponsible($id: ID!) {
  findActiveJobsByCompanyUserWhoIsResponsible(id: $id) {
    id
  }
}
    `;

/**
 * __useFindActiveJobsByCompanyUserWhoIsResponsibleQuery__
 *
 * To run a query within a React component, call `useFindActiveJobsByCompanyUserWhoIsResponsibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindActiveJobsByCompanyUserWhoIsResponsibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindActiveJobsByCompanyUserWhoIsResponsibleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindActiveJobsByCompanyUserWhoIsResponsibleQuery(baseOptions: Apollo.QueryHookOptions<FindActiveJobsByCompanyUserWhoIsResponsibleQuery, FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindActiveJobsByCompanyUserWhoIsResponsibleQuery, FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables>(FindActiveJobsByCompanyUserWhoIsResponsibleDocument, options);
      }
export function useFindActiveJobsByCompanyUserWhoIsResponsibleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindActiveJobsByCompanyUserWhoIsResponsibleQuery, FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindActiveJobsByCompanyUserWhoIsResponsibleQuery, FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables>(FindActiveJobsByCompanyUserWhoIsResponsibleDocument, options);
        }
export type FindActiveJobsByCompanyUserWhoIsResponsibleQueryHookResult = ReturnType<typeof useFindActiveJobsByCompanyUserWhoIsResponsibleQuery>;
export type FindActiveJobsByCompanyUserWhoIsResponsibleLazyQueryHookResult = ReturnType<typeof useFindActiveJobsByCompanyUserWhoIsResponsibleLazyQuery>;
export type FindActiveJobsByCompanyUserWhoIsResponsibleQueryResult = Apollo.QueryResult<FindActiveJobsByCompanyUserWhoIsResponsibleQuery, FindActiveJobsByCompanyUserWhoIsResponsibleQueryVariables>;
export const FindPendingBidsByCompanyUserWhoIsResponsibleDocument = gql`
    query FindPendingBidsByCompanyUserWhoIsResponsible($id: ID!) {
  findPendingBidsByCompanyUserWhoIsResponsible(id: $id) {
    id
  }
}
    `;

/**
 * __useFindPendingBidsByCompanyUserWhoIsResponsibleQuery__
 *
 * To run a query within a React component, call `useFindPendingBidsByCompanyUserWhoIsResponsibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPendingBidsByCompanyUserWhoIsResponsibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPendingBidsByCompanyUserWhoIsResponsibleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPendingBidsByCompanyUserWhoIsResponsibleQuery(baseOptions: Apollo.QueryHookOptions<FindPendingBidsByCompanyUserWhoIsResponsibleQuery, FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPendingBidsByCompanyUserWhoIsResponsibleQuery, FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables>(FindPendingBidsByCompanyUserWhoIsResponsibleDocument, options);
      }
export function useFindPendingBidsByCompanyUserWhoIsResponsibleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPendingBidsByCompanyUserWhoIsResponsibleQuery, FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPendingBidsByCompanyUserWhoIsResponsibleQuery, FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables>(FindPendingBidsByCompanyUserWhoIsResponsibleDocument, options);
        }
export type FindPendingBidsByCompanyUserWhoIsResponsibleQueryHookResult = ReturnType<typeof useFindPendingBidsByCompanyUserWhoIsResponsibleQuery>;
export type FindPendingBidsByCompanyUserWhoIsResponsibleLazyQueryHookResult = ReturnType<typeof useFindPendingBidsByCompanyUserWhoIsResponsibleLazyQuery>;
export type FindPendingBidsByCompanyUserWhoIsResponsibleQueryResult = Apollo.QueryResult<FindPendingBidsByCompanyUserWhoIsResponsibleQuery, FindPendingBidsByCompanyUserWhoIsResponsibleQueryVariables>;
export const FindScheduledInterviewsByCompanyUserWhoIsResponsibleDocument = gql`
    query FindScheduledInterviewsByCompanyUserWhoIsResponsible($id: ID!) {
  findScheduledInterviewsByCompanyUserWhoIsResponsible(id: $id) {
    id
  }
}
    `;

/**
 * __useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery__
 *
 * To run a query within a React component, call `useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery(baseOptions: Apollo.QueryHookOptions<FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery, FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery, FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables>(FindScheduledInterviewsByCompanyUserWhoIsResponsibleDocument, options);
      }
export function useFindScheduledInterviewsByCompanyUserWhoIsResponsibleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery, FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery, FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables>(FindScheduledInterviewsByCompanyUserWhoIsResponsibleDocument, options);
        }
export type FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryHookResult = ReturnType<typeof useFindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery>;
export type FindScheduledInterviewsByCompanyUserWhoIsResponsibleLazyQueryHookResult = ReturnType<typeof useFindScheduledInterviewsByCompanyUserWhoIsResponsibleLazyQuery>;
export type FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryResult = Apollo.QueryResult<FindScheduledInterviewsByCompanyUserWhoIsResponsibleQuery, FindScheduledInterviewsByCompanyUserWhoIsResponsibleQueryVariables>;
export const GetAllCompanyUsersFromCurrentCompanyDocument = gql`
    query GetAllCompanyUsersFromCurrentCompany {
  getAllCompanyUsersFromCurrentCompany {
    id
    name
    role
    email
    jobTitle
    receiveNotifications
    active
  }
}
    `;

/**
 * __useGetAllCompanyUsersFromCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyUsersFromCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyUsersFromCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyUsersFromCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompanyUsersFromCurrentCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCompanyUsersFromCurrentCompanyQuery, GetAllCompanyUsersFromCurrentCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCompanyUsersFromCurrentCompanyQuery, GetAllCompanyUsersFromCurrentCompanyQueryVariables>(GetAllCompanyUsersFromCurrentCompanyDocument, options);
      }
export function useGetAllCompanyUsersFromCurrentCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompanyUsersFromCurrentCompanyQuery, GetAllCompanyUsersFromCurrentCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCompanyUsersFromCurrentCompanyQuery, GetAllCompanyUsersFromCurrentCompanyQueryVariables>(GetAllCompanyUsersFromCurrentCompanyDocument, options);
        }
export type GetAllCompanyUsersFromCurrentCompanyQueryHookResult = ReturnType<typeof useGetAllCompanyUsersFromCurrentCompanyQuery>;
export type GetAllCompanyUsersFromCurrentCompanyLazyQueryHookResult = ReturnType<typeof useGetAllCompanyUsersFromCurrentCompanyLazyQuery>;
export type GetAllCompanyUsersFromCurrentCompanyQueryResult = Apollo.QueryResult<GetAllCompanyUsersFromCurrentCompanyQuery, GetAllCompanyUsersFromCurrentCompanyQueryVariables>;
export const GetCompanyUserByIdDocument = gql`
    query GetCompanyUserById($id: ID!) {
  getCompanyUserById(id: $id) {
    id
    name
    role
    email
    jobTitle
    receiveNotifications
    phoneNumber
    active
    accessHub
  }
}
    `;

/**
 * __useGetCompanyUserByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyUserByIdQuery, GetCompanyUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUserByIdQuery, GetCompanyUserByIdQueryVariables>(GetCompanyUserByIdDocument, options);
      }
export function useGetCompanyUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUserByIdQuery, GetCompanyUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUserByIdQuery, GetCompanyUserByIdQueryVariables>(GetCompanyUserByIdDocument, options);
        }
export type GetCompanyUserByIdQueryHookResult = ReturnType<typeof useGetCompanyUserByIdQuery>;
export type GetCompanyUserByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyUserByIdLazyQuery>;
export type GetCompanyUserByIdQueryResult = Apollo.QueryResult<GetCompanyUserByIdQuery, GetCompanyUserByIdQueryVariables>;
export const GetFeatureFlagDocument = gql`
    query GetFeatureFlag($params: GetFeatureFlag!) {
  getFeatureFlag(params: $params)
}
    `;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagQueryHookResult = ReturnType<typeof useGetFeatureFlagQuery>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>;
export const FindActiveAndApprovedJobsDocument = gql`
    query findActiveAndApprovedJobs {
  findActiveAndApprovedJobs {
    id
    city {
      id
      name
    }
    cltMaxSalary
    cltMinSalary
    createdAt
    maxSalary
    pjMaxSalary
    pjMinSalary
    remoteWork
    slug
    technologies {
      id
      name
      urlPath
    }
    title
    focusId
    experienceLevel
    createdAt
    requirements
  }
}
    `;

/**
 * __useFindActiveAndApprovedJobsQuery__
 *
 * To run a query within a React component, call `useFindActiveAndApprovedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindActiveAndApprovedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindActiveAndApprovedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindActiveAndApprovedJobsQuery(baseOptions?: Apollo.QueryHookOptions<FindActiveAndApprovedJobsQuery, FindActiveAndApprovedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindActiveAndApprovedJobsQuery, FindActiveAndApprovedJobsQueryVariables>(FindActiveAndApprovedJobsDocument, options);
      }
export function useFindActiveAndApprovedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindActiveAndApprovedJobsQuery, FindActiveAndApprovedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindActiveAndApprovedJobsQuery, FindActiveAndApprovedJobsQueryVariables>(FindActiveAndApprovedJobsDocument, options);
        }
export type FindActiveAndApprovedJobsQueryHookResult = ReturnType<typeof useFindActiveAndApprovedJobsQuery>;
export type FindActiveAndApprovedJobsLazyQueryHookResult = ReturnType<typeof useFindActiveAndApprovedJobsLazyQuery>;
export type FindActiveAndApprovedJobsQueryResult = Apollo.QueryResult<FindActiveAndApprovedJobsQuery, FindActiveAndApprovedJobsQueryVariables>;
export const FindFirstJobBySlugDocument = gql`
    query findFirstJobBySlug($slug: String!) {
  findFirstJobBySlug(slug: $slug) {
    id
    title
    experienceLevel
    active
    adminApproval
    cltMaxSalary
    cltMinSalary
    createdAt
    maxSalary
    pjMaxSalary
    pjMinSalary
    usdAnnualSalaryMin
    usdAnnualSalaryMax
    remoteWork
    hybrid
    slug
    activities
    requirements
    englishLevel
    description
    focusId
    hideSalary
    hideCompany
    city {
      id
      name
      state {
        abbreviation
      }
    }
    jobBenefits {
      description
      benefit {
        name
        key
      }
    }
    jobTechnologies {
      id
      required
      technology {
        id
        name
        urlPath
      }
    }
    company {
      summary
      industry {
        name
      }
      name
    }
    isInternational
    jobType
    techCareer
    careerExperienceLevel
    focusExperienceLevel
    focus {
      description
      career {
        name
        slug
      }
    }
    publishedScreening {
      id
      questions {
        id
        answerType
        name
        mandatory
        minAnswerOptions
      }
    }
  }
}
    `;

/**
 * __useFindFirstJobBySlugQuery__
 *
 * To run a query within a React component, call `useFindFirstJobBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstJobBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstJobBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFindFirstJobBySlugQuery(baseOptions: Apollo.QueryHookOptions<FindFirstJobBySlugQuery, FindFirstJobBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstJobBySlugQuery, FindFirstJobBySlugQueryVariables>(FindFirstJobBySlugDocument, options);
      }
export function useFindFirstJobBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstJobBySlugQuery, FindFirstJobBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstJobBySlugQuery, FindFirstJobBySlugQueryVariables>(FindFirstJobBySlugDocument, options);
        }
export type FindFirstJobBySlugQueryHookResult = ReturnType<typeof useFindFirstJobBySlugQuery>;
export type FindFirstJobBySlugLazyQueryHookResult = ReturnType<typeof useFindFirstJobBySlugLazyQuery>;
export type FindFirstJobBySlugQueryResult = Apollo.QueryResult<FindFirstJobBySlugQuery, FindFirstJobBySlugQueryVariables>;
export const FindLatestApprovedJobsDocument = gql`
    query findLatestApprovedJobs($quantity: Int) {
  findLatestApprovedJobs(quantity: $quantity) {
    id
    city {
      id
      name
    }
    cltMaxSalary
    cltMinSalary
    createdAt
    maxSalary
    pjMaxSalary
    pjMinSalary
    usdAnnualSalaryMin
    usdAnnualSalaryMax
    remoteWork
    hybrid
    slug
    hideSalary
    technologies {
      id
      name
      urlPath
    }
    title
    isInternational
    jobType
    company {
      slug
    }
  }
}
    `;

/**
 * __useFindLatestApprovedJobsQuery__
 *
 * To run a query within a React component, call `useFindLatestApprovedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLatestApprovedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLatestApprovedJobsQuery({
 *   variables: {
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useFindLatestApprovedJobsQuery(baseOptions?: Apollo.QueryHookOptions<FindLatestApprovedJobsQuery, FindLatestApprovedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindLatestApprovedJobsQuery, FindLatestApprovedJobsQueryVariables>(FindLatestApprovedJobsDocument, options);
      }
export function useFindLatestApprovedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindLatestApprovedJobsQuery, FindLatestApprovedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindLatestApprovedJobsQuery, FindLatestApprovedJobsQueryVariables>(FindLatestApprovedJobsDocument, options);
        }
export type FindLatestApprovedJobsQueryHookResult = ReturnType<typeof useFindLatestApprovedJobsQuery>;
export type FindLatestApprovedJobsLazyQueryHookResult = ReturnType<typeof useFindLatestApprovedJobsLazyQuery>;
export type FindLatestApprovedJobsQueryResult = Apollo.QueryResult<FindLatestApprovedJobsQuery, FindLatestApprovedJobsQueryVariables>;
export const FindMaxMinJobsSalaryDocument = gql`
    query findMaxMinJobsSalary {
  findMaxMinJobsSalary {
    minSalary
    maxSalary
  }
}
    `;

/**
 * __useFindMaxMinJobsSalaryQuery__
 *
 * To run a query within a React component, call `useFindMaxMinJobsSalaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMaxMinJobsSalaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMaxMinJobsSalaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMaxMinJobsSalaryQuery(baseOptions?: Apollo.QueryHookOptions<FindMaxMinJobsSalaryQuery, FindMaxMinJobsSalaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMaxMinJobsSalaryQuery, FindMaxMinJobsSalaryQueryVariables>(FindMaxMinJobsSalaryDocument, options);
      }
export function useFindMaxMinJobsSalaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMaxMinJobsSalaryQuery, FindMaxMinJobsSalaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMaxMinJobsSalaryQuery, FindMaxMinJobsSalaryQueryVariables>(FindMaxMinJobsSalaryDocument, options);
        }
export type FindMaxMinJobsSalaryQueryHookResult = ReturnType<typeof useFindMaxMinJobsSalaryQuery>;
export type FindMaxMinJobsSalaryLazyQueryHookResult = ReturnType<typeof useFindMaxMinJobsSalaryLazyQuery>;
export type FindMaxMinJobsSalaryQueryResult = Apollo.QueryResult<FindMaxMinJobsSalaryQuery, FindMaxMinJobsSalaryQueryVariables>;
export const FindShowcaseJobsDocument = gql`
    query findShowcaseJobs($showcaseParams: SearchJobFilter!) {
  findShowcaseJobs(showcaseParams: $showcaseParams) {
    total
    data {
      id
      city {
        id
        name
      }
      cltMaxSalary
      cltMinSalary
      createdAt
      maxSalary
      pjMaxSalary
      pjMinSalary
      usdAnnualSalaryMin
      usdAnnualSalaryMax
      remoteWork
      slug
      hideSalary
      technologies {
        id
        name
        urlPath
      }
      title
      focusId
      focus {
        id
        description
        career {
          id
          name
        }
      }
      experienceLevel
      requirements
      description
      isInternational
      hybrid
      techCareer
      careerExperienceLevel
      focusExperienceLevel
      jobType
      company {
        slug
      }
    }
  }
}
    `;

/**
 * __useFindShowcaseJobsQuery__
 *
 * To run a query within a React component, call `useFindShowcaseJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShowcaseJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShowcaseJobsQuery({
 *   variables: {
 *      showcaseParams: // value for 'showcaseParams'
 *   },
 * });
 */
export function useFindShowcaseJobsQuery(baseOptions: Apollo.QueryHookOptions<FindShowcaseJobsQuery, FindShowcaseJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindShowcaseJobsQuery, FindShowcaseJobsQueryVariables>(FindShowcaseJobsDocument, options);
      }
export function useFindShowcaseJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindShowcaseJobsQuery, FindShowcaseJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindShowcaseJobsQuery, FindShowcaseJobsQueryVariables>(FindShowcaseJobsDocument, options);
        }
export type FindShowcaseJobsQueryHookResult = ReturnType<typeof useFindShowcaseJobsQuery>;
export type FindShowcaseJobsLazyQueryHookResult = ReturnType<typeof useFindShowcaseJobsLazyQuery>;
export type FindShowcaseJobsQueryResult = Apollo.QueryResult<FindShowcaseJobsQuery, FindShowcaseJobsQueryVariables>;
export const FindSimilarJobsDocument = gql`
    query findSimilarJobs($jobId: Int!, $quantity: Int) {
  findSimilarJobs(jobId: $jobId, quantity: $quantity) {
    id
    city {
      id
      name
    }
    cltMaxSalary
    cltMinSalary
    createdAt
    maxSalary
    pjMaxSalary
    pjMinSalary
    usdAnnualSalaryMin
    usdAnnualSalaryMax
    remoteWork
    hybrid
    slug
    hideSalary
    technologies {
      id
      name
      urlPath
    }
    title
    isInternational
    jobType
    company {
      slug
    }
  }
}
    `;

/**
 * __useFindSimilarJobsQuery__
 *
 * To run a query within a React component, call `useFindSimilarJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimilarJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimilarJobsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useFindSimilarJobsQuery(baseOptions: Apollo.QueryHookOptions<FindSimilarJobsQuery, FindSimilarJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSimilarJobsQuery, FindSimilarJobsQueryVariables>(FindSimilarJobsDocument, options);
      }
export function useFindSimilarJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSimilarJobsQuery, FindSimilarJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSimilarJobsQuery, FindSimilarJobsQueryVariables>(FindSimilarJobsDocument, options);
        }
export type FindSimilarJobsQueryHookResult = ReturnType<typeof useFindSimilarJobsQuery>;
export type FindSimilarJobsLazyQueryHookResult = ReturnType<typeof useFindSimilarJobsLazyQuery>;
export type FindSimilarJobsQueryResult = Apollo.QueryResult<FindSimilarJobsQuery, FindSimilarJobsQueryVariables>;
export const GetScreeningByJobIdDocument = gql`
    query getScreeningByJobId($jobId: Int!, $publishedOnly: Boolean) {
  getScreeningByJobId(jobId: $jobId, publishedOnly: $publishedOnly) {
    id
    questions {
      id
      answerType
      name
      mandatory
      minAnswerOptions
    }
  }
}
    `;

/**
 * __useGetScreeningByJobIdQuery__
 *
 * To run a query within a React component, call `useGetScreeningByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreeningByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreeningByJobIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      publishedOnly: // value for 'publishedOnly'
 *   },
 * });
 */
export function useGetScreeningByJobIdQuery(baseOptions: Apollo.QueryHookOptions<GetScreeningByJobIdQuery, GetScreeningByJobIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScreeningByJobIdQuery, GetScreeningByJobIdQueryVariables>(GetScreeningByJobIdDocument, options);
      }
export function useGetScreeningByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScreeningByJobIdQuery, GetScreeningByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScreeningByJobIdQuery, GetScreeningByJobIdQueryVariables>(GetScreeningByJobIdDocument, options);
        }
export type GetScreeningByJobIdQueryHookResult = ReturnType<typeof useGetScreeningByJobIdQuery>;
export type GetScreeningByJobIdLazyQueryHookResult = ReturnType<typeof useGetScreeningByJobIdLazyQuery>;
export type GetScreeningByJobIdQueryResult = Apollo.QueryResult<GetScreeningByJobIdQuery, GetScreeningByJobIdQueryVariables>;
export const ListCountriesAndCitiesDocument = gql`
    query ListCountriesAndCities($textSearch: String!, $limit: Int) {
  listCountries {
    id
    name
  }
  searchCities(text: $textSearch, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __useListCountriesAndCitiesQuery__
 *
 * To run a query within a React component, call `useListCountriesAndCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCountriesAndCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCountriesAndCitiesQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListCountriesAndCitiesQuery(baseOptions: Apollo.QueryHookOptions<ListCountriesAndCitiesQuery, ListCountriesAndCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCountriesAndCitiesQuery, ListCountriesAndCitiesQueryVariables>(ListCountriesAndCitiesDocument, options);
      }
export function useListCountriesAndCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCountriesAndCitiesQuery, ListCountriesAndCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCountriesAndCitiesQuery, ListCountriesAndCitiesQueryVariables>(ListCountriesAndCitiesDocument, options);
        }
export type ListCountriesAndCitiesQueryHookResult = ReturnType<typeof useListCountriesAndCitiesQuery>;
export type ListCountriesAndCitiesLazyQueryHookResult = ReturnType<typeof useListCountriesAndCitiesLazyQuery>;
export type ListCountriesAndCitiesQueryResult = Apollo.QueryResult<ListCountriesAndCitiesQuery, ListCountriesAndCitiesQueryVariables>;
export const ListSubscriptionPlansDocument = gql`
    query ListSubscriptionPlans {
  listSubscriptionPlans {
    id
    name
    price
    period
    slug
  }
}
    `;

/**
 * __useListSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useListSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSubscriptionPlansQuery(baseOptions?: Apollo.QueryHookOptions<ListSubscriptionPlansQuery, ListSubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSubscriptionPlansQuery, ListSubscriptionPlansQueryVariables>(ListSubscriptionPlansDocument, options);
      }
export function useListSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSubscriptionPlansQuery, ListSubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSubscriptionPlansQuery, ListSubscriptionPlansQueryVariables>(ListSubscriptionPlansDocument, options);
        }
export type ListSubscriptionPlansQueryHookResult = ReturnType<typeof useListSubscriptionPlansQuery>;
export type ListSubscriptionPlansLazyQueryHookResult = ReturnType<typeof useListSubscriptionPlansLazyQuery>;
export type ListSubscriptionPlansQueryResult = Apollo.QueryResult<ListSubscriptionPlansQuery, ListSubscriptionPlansQueryVariables>;
export const SearchCitiesDocument = gql`
    query SearchCities($textSearch: String!) {
  searchCities(text: $textSearch) {
    id
    name
  }
}
    `;

/**
 * __useSearchCitiesQuery__
 *
 * To run a query within a React component, call `useSearchCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCitiesQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useSearchCitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
      }
export function useSearchCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
        }
export type SearchCitiesQueryHookResult = ReturnType<typeof useSearchCitiesQuery>;
export type SearchCitiesLazyQueryHookResult = ReturnType<typeof useSearchCitiesLazyQuery>;
export type SearchCitiesQueryResult = Apollo.QueryResult<SearchCitiesQuery, SearchCitiesQueryVariables>;
export const SearchCompanyAliasDocument = gql`
    query SearchCompanyAlias($textSearch: String!) {
  searchCompanyAlias(text: $textSearch) {
    id
    name
    baseCompanyId
  }
}
    `;

/**
 * __useSearchCompanyAliasQuery__
 *
 * To run a query within a React component, call `useSearchCompanyAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyAliasQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useSearchCompanyAliasQuery(baseOptions: Apollo.QueryHookOptions<SearchCompanyAliasQuery, SearchCompanyAliasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCompanyAliasQuery, SearchCompanyAliasQueryVariables>(SearchCompanyAliasDocument, options);
      }
export function useSearchCompanyAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCompanyAliasQuery, SearchCompanyAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCompanyAliasQuery, SearchCompanyAliasQueryVariables>(SearchCompanyAliasDocument, options);
        }
export type SearchCompanyAliasQueryHookResult = ReturnType<typeof useSearchCompanyAliasQuery>;
export type SearchCompanyAliasLazyQueryHookResult = ReturnType<typeof useSearchCompanyAliasLazyQuery>;
export type SearchCompanyAliasQueryResult = Apollo.QueryResult<SearchCompanyAliasQuery, SearchCompanyAliasQueryVariables>;
export const SearchTechsDocument = gql`
    query SearchTechs($textSearch: String!, $subTypes: [TechnologySubtypeEnum!]) {
  searchTechs(text: $textSearch, subtypes: $subTypes) {
    id
    name
  }
}
    `;

/**
 * __useSearchTechsQuery__
 *
 * To run a query within a React component, call `useSearchTechsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTechsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTechsQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *      subTypes: // value for 'subTypes'
 *   },
 * });
 */
export function useSearchTechsQuery(baseOptions: Apollo.QueryHookOptions<SearchTechsQuery, SearchTechsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTechsQuery, SearchTechsQueryVariables>(SearchTechsDocument, options);
      }
export function useSearchTechsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTechsQuery, SearchTechsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTechsQuery, SearchTechsQueryVariables>(SearchTechsDocument, options);
        }
export type SearchTechsQueryHookResult = ReturnType<typeof useSearchTechsQuery>;
export type SearchTechsLazyQueryHookResult = ReturnType<typeof useSearchTechsLazyQuery>;
export type SearchTechsQueryResult = Apollo.QueryResult<SearchTechsQuery, SearchTechsQueryVariables>;